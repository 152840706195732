import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { LoginResponse, UserSessionInformationResult } from '../../login/Models/LoginResponse.model';
import { LoginService } from '../../services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDealershipRequest, ChangeDealershipResponse, LeftMenus } from '../models/navbar.model';
import { FormArray, FormGroup } from '@angular/forms';
import { UserIdService } from '../../services/userIdService/user-id.service';
import { LogoutService } from '../../services/logout.service';
import { environment } from '../../../environments/environment';
import { AnnouncementService } from '../../announcements/services/announcement.service';
import { Location } from '@angular/common';
import { EmployeeDetailsAdminService } from '../../employee-information/services/employee-details-admin.service';
import { EmployeeInformationForAdmin } from '../../employee-information/models/employee-information-for-admin.model';
import { TranslateService } from '@ngx-translate/core';
import * as constants from '../../../Constants/countries.constants';
import { ChangeDealershipService } from '../services/navbar.service';
import { ErrorService } from '../../services/error-service/error.service';
import { CommunicationService } from '../../services/communication.service';
import { CommonService } from '../../services/common.service';
import { SESSION_STORAGE_CONSTANTS } from 'Constants/StarsLearning.constants';
import { PATH_CONSTANTS } from 'Constants/StarsLearning.constants';

@Component({
	selector: 'app-nav-bar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavBarComponent implements OnInit {
	logoutOpen = false;
	loginResponse: LoginResponse;
	userSessionInformation: UserSessionInformationResult;
	mySideNavOpen: boolean;
	activeMenu: string;
	leftMenus: LeftMenus;
	starLearning: FormGroup;
	isUserProfile = false;
	starsBaseURL: string;
	showAdminNavbar = true;
	adminDropdownActive = false;
	employeeInfoForAdmin: EmployeeInformationForAdmin;

	changeDealershipResponse: ChangeDealershipResponse;
	errorOccured = false;
	errorMessages = '';

	// for User Type
	isFleetAdmin: boolean;
	isDealerAdmin: boolean;
	isAdminView: boolean;
	isCorporateAdminView: boolean;

	isINTDLRSUPPORT: boolean;
	isINTINSTRUCTOR: boolean;
	isINTCURRCOORD: boolean;
	isINTCTRYADMIN: boolean;
	isINTBUSADMIN: boolean;

	// for management reports
	dealerCode: string;
	selectedDealerShipCode: string;
	selectedSubDealerShipCode: string;
	selectedDealerCodeAndSubDealerShipCode: string;
	prevSelectedDealerShipCode: string;
	subDealerCode: string;
	countryCode: string;
	managementReportsURL: string;

	constants = constants;
	pathConstants = PATH_CONSTANTS;

	userName = '';

	@ViewChild('closeModal') private closeModal: ElementRef;
	@ViewChild('adminDropDown') private adminDropdown: ElementRef;
	navBarMenuActive = false;
	// for Corporate Nav bar
	currentRoute = '';
	mySideNavSubMenuOpen: boolean;

	securityProfile = [];
	isCareerEntryVisible: boolean;
	displayCertAlertMenu: boolean;

	isCollapsed = true;

	corporateCBUCode: string;

	INTDLRSUPPORTSubMenu = [
		{ menuName: this.translate.instant('Administration.CertificationList'), path: '', isDisabled: true },
		{ menuName: this.translate.instant('Tiles.reset_password'), path: '/corporateAdmin/reset-password', isDisabled: false },
		{ menuName: this.translate.instant('Administration.TransferTerminateDealerList'), path: '/corporateAdmin/transfer-terminate-dealer', isDisabled: false }
	];

	INTINSTRUCTORSubMenu = [
		{ menuName: this.translate.instant('Administration.CertificationList'), path: '', isDisabled: true },
		{ menuName: this.translate.instant('Administration.HotelList'), path: '/corporateAdmin/hotel', isDisabled: false },
		{ menuName: this.translate.instant('Administration.InstructorList'), path: '/corporateAdmin/instructor-list', isDisabled: false },
		{ menuName: this.translate.instant('Administration.LocationList'), path: PATH_CONSTANTS.LOCATION_LIST, isDisabled: false },
		{ menuName: this.translate.instant('Administration.NonScheduledTrainingResults'), path: '/corporateAdmin/' + PATH_CONSTANTS.NON_SCHEDULED_TRAINING_RESULTS, isDisabled: false },
		{ menuName: this.translate.instant('Tiles.reset_password'), path: '/corporateAdmin/reset-password', isDisabled: false },
		{ menuName: this.translate.instant('Administration.ScheduleAndClassMaintenance'), path: '', isDisabled: true },
		{ menuName: this.translate.instant('Administration.SupplierList'), path: '', isDisabled: true },
		{ menuName: this.translate.instant('Administration.TransferTerminateDealerList'), path: '/corporateAdmin/transfer-terminate-dealer', isDisabled: false }
	];

	INTCURRCOORDSubMenu = [
		{ menuName: this.translate.instant('Administration.CertificationList'), path: '', isDisabled: true },
		{ menuName: this.translate.instant('Administration.HotelList'), path: '/corporateAdmin/hotel', isDisabled: false },
		{ menuName: this.translate.instant('Administration.InstructorList'), path: '/corporateAdmin/instructor-list', isDisabled: false },
		{ menuName: this.translate.instant('Administration.JobRoleCountryList'), path: '/corporateAdmin/job-role-list-by-country', isDisabled: false },
		{ menuName: this.translate.instant('Administration.LocationList'), path: PATH_CONSTANTS.LOCATION_LIST, isDisabled: false },
		{ menuName: this.translate.instant('Administration.NonScheduledTrainingResults'), path: '/corporateAdmin/' + PATH_CONSTANTS.NON_SCHEDULED_TRAINING_RESULTS, isDisabled: false },
		{ menuName: this.translate.instant('Tiles.reset_password'), path: '/corporateAdmin/reset-password', isDisabled: false },
		{ menuName: this.translate.instant('Administration.ScheduleAndClassMaintenance'), path: '', isDisabled: true },
		{ menuName: this.translate.instant('Administration.SupplierList'), path: '', isDisabled: true },
		{ menuName: this.translate.instant('Administration.TransferTerminateDealerList'), path: '/corporateAdmin/transfer-terminate-dealer', isDisabled: false }
	];

	INTCTRYADMINSubMenu = [
		{ menuName: this.translate.instant('Administration.CertificationList'), path: '', isDisabled: true },
		{ menuName: this.translate.instant('Administration.EMSRegion'), path: '/corporateAdmin/emsiso', isDisabled: false },
		{ menuName: this.translate.instant('Administration.HotelList'), path: '/corporateAdmin/hotel', isDisabled: false },
		{ menuName: this.translate.instant('Administration.InstructorList'), path: '/corporateAdmin/instructor-list', isDisabled: false },
		{ menuName: this.translate.instant('Administration.JobRoleCountryList'), path: '/corporateAdmin/job-role-list-by-country', isDisabled: false },
		{ menuName: this.translate.instant('Administration.LocationList'), path: PATH_CONSTANTS.LOCATION_LIST, isDisabled: false },
		{ menuName: this.translate.instant('Administration.NonScheduledTrainingResults'), path: '/corporateAdmin/' + PATH_CONSTANTS.NON_SCHEDULED_TRAINING_RESULTS, isDisabled: false },
		{ menuName: this.translate.instant('Tiles.reset_password'), path: '/corporateAdmin/reset-password', isDisabled: false },
		{ menuName: this.translate.instant('Administration.ScheduleAndClassMaintenance'), path: '', isDisabled: true },
		{ menuName: this.translate.instant('Administration.SupplierList'), path: '', isDisabled: true },
		{ menuName: this.translate.instant('Administration.TransferTerminateDealerList'), path: '/corporateAdmin/transfer-terminate-dealer', isDisabled: false }
	];

	INTBUSADMINSubMenu = [
		{ menuName: this.translate.instant('Administration.CertificationList'), path: '', isDisabled: true },
		{ menuName: this.translate.instant('Administration.EMSRegion'), path: '/corporateAdmin/emsiso', isDisabled: false },
		{ menuName: this.translate.instant('Administration.HotelList'), path: '/corporateAdmin/hotel', isDisabled: false },
		{ menuName: this.translate.instant('Administration.InstructorList'), path: '/corporateAdmin/instructor-list', isDisabled: false },
		{ menuName: this.translate.instant('Administration.JobRoleMasterList'), path: '/corporateAdmin/job-role-master-list', isDisabled: false },
		{ menuName: this.translate.instant('Administration.JobRoleCountryList'), path: '/corporateAdmin/job-role-list-by-country', isDisabled: false },
		{ menuName: this.translate.instant('Administration.LocationList'), path: PATH_CONSTANTS.LOCATION_LIST, isDisabled: false },
		{ menuName: this.translate.instant('Administration.NonScheduledTrainingResults'), path: '/corporateAdmin/' + PATH_CONSTANTS.NON_SCHEDULED_TRAINING_RESULTS, isDisabled: false },
		{ menuName: this.translate.instant('Administration.ProgramList'), path: PATH_CONSTANTS.PROGRAM_LIST, isDisabled: false },
		{ menuName: this.translate.instant('Tiles.reset_password'), path: '/corporateAdmin/reset-password', isDisabled: false },
		{ menuName: this.translate.instant('Administration.RightTobeForgotten'), path: PATH_CONSTANTS.RIGHT_TO_BE_FORGOTTEN_LIST, isDisabled: false },
		{ menuName: this.translate.instant('Administration.ScheduleAndClassMaintenance'), path: '', isDisabled: true },
		{ menuName: this.translate.instant('Administration.SponsorList'), path: PATH_CONSTANTS.SPONSOR_LIST, isDisabled: false },
		{ menuName: this.translate.instant('Administration.SupplierList'), path: '', isDisabled: true },
		{ menuName: this.translate.instant('Administration.TransferTerminateDealerList'), path: '/corporateAdmin/transfer-terminate-dealer', isDisabled: false }
	];

	constructor(
		private changeDealershipService: ChangeDealershipService,
		private communicationService: CommunicationService,
		public loginService: LoginService,
		public router: Router,
		public userIdService: UserIdService,
		public logoutService: LogoutService,
		public announcementService: AnnouncementService,
		public location: Location,
		public route: ActivatedRoute,
		private errorService: ErrorService,
		public employeeDetailsAdminService: EmployeeDetailsAdminService,
		public translate: TranslateService,
		public commonService: CommonService) {
		this.leftMenus = new LeftMenus();
	}

	ngOnInit() {
		document.documentElement.scrollTop = 0;
		window.scrollTo(0, 0);
		this.employeeInfoForAdmin = this.employeeDetailsAdminService.displayEmployeeDetailsForAdmin(this.router.url);
		this.starsBaseURL = environment.starsBaseURL;
		this.loginResponse = this.loginService.getLoginResponse();
		this.starLearning = this.loginService.getStartsLearningForm();
		this.userSessionInformation = this.loginResponse.result.userSessionInformation;
		this.securityProfile = this.loginResponse.result.userSessionInformation.securityProfile;
		this.displayCertAlertMenu = constants.displayCertAlerts.includes(this.loginResponse.result.country);
		this.updateCBUCode();

		this.userName = this.buildName;
		this.decideUserType();
		this.prepareAdministrationSubMenu();
		this.prepareCareerEntryMenu();

		if (this.loginService.isAdmin && this.loginService.isChangeDealerShip && this.isAdminView) {
			this.getDealerships();
		}

		// below code is to support language translation
		sessionStorage.setItem('selectedLanguage', this.loginResponse.result.userSessionInformation.userDefaultLanguageCode.toLowerCase());
		this.translate.setDefaultLang('en');
		this.translate.use(sessionStorage.getItem('selectedLanguage'));

		if ((this.constants.acceptDeclarationCountries.includes(this.loginResponse.result.country) || this.loginResponse.result.userSessionInformation.cegeneral) && this.loginResponse.result.acceptFlag === 'N') {
			this.router.navigate(['/declaration-of-approval'], { skipLocationChange: false });
			return;
		}

		if (this.constants.acceptPrivacyCountries.includes(this.loginResponse.result.country) && this.loginResponse.result.acceptFlag === 'N') {
			this.router.navigate(['/privacy-statement'], { skipLocationChange: false });
			return;
		}

		//Redirect until password is not temp
		if (!this.loginResponse.result.quarterlyEmailVerificationFlag && this.loginResponse.result.resetFlag === 'Y') {
			this.router.navigate(['/change-temp-password'], { skipLocationChange: false });
		}

		// when user lands on the dashboard and user tries to refresh the page, we are ensuring that the user is Authenticated
		if (sessionStorage.getItem('userContext') || localStorage.getItem('userContext')) {
			this.userIdService.setUserAuthenticated(true);
		}
		this.starLearning.valueChanges.subscribe((value) => {
			this.loginService.saveContext();
		});
	}

	prepareAdministrationSubMenu() {
		if (this.isINTDLRSUPPORT) {
			this.modifyINTDLRSUPPORTSubMenu();
		}

		if (this.isINTINSTRUCTOR) {
			this.modifyINTINSTRUCTORSubMenu();
		}

		if (this.isINTCURRCOORD) {
			this.modifyINTCURRCOORDSubMenu();
		}

		if (this.isINTCTRYADMIN) {
			this.modifyINTCTRYADMINSubMenu();
		}

		if (this.isINTBUSADMIN) {
			this.modifyINTBUSADMINSubMenu();
		}
	}

	prepareCareerEntryMenu() {
		if (this.userSessionInformation.spCEAdmin || this.userSessionInformation.spCEINSAdmin || this.userSessionInformation.spCEFACTAdmin
			|| this.userSessionInformation.spCEINSView || this.userSessionInformation.spCESCHAdmin) {
			this.isCareerEntryVisible = true;
		}
	}

	private modifyINTDLRSUPPORTSubMenu() {
		const menu = this.INTDLRSUPPORTSubMenu;
		if (this.displayCertAlertMenu) {
			menu.splice(1, 0, this.certAlertDepartmentSubscriptions());
			menu.splice(2, 0, this.certAlertDealerSubscriptions());
		}
		if (this.securityProfile.includes('SPEMLTXT')) {
			menu.splice(3, 0, this.createTargetedCommunicationsOption());
		}
		if (this.securityProfile.includes('SPIDMERGE')) {
			menu.splice(5, 0, this.starsIDMergeListOption());
		}
		this.INTDLRSUPPORTSubMenu = menu;
	}

	private modifyINTINSTRUCTORSubMenu() {
		const menu = this.INTINSTRUCTORSubMenu;
		if (this.displayCertAlertMenu) {
			menu.splice(1, 0, this.certAlertDepartmentSubscriptions());
			menu.splice(2, 0, this.certAlertDealerSubscriptions());
		}
		if (this.securityProfile.includes('SPEMLTXT')) {
			menu.splice(3, 0, this.createTargetedCommunicationsOption());
		}
		if (this.securityProfile.includes('SPIDMERGE')) {
			menu.splice(10, 0, this.starsIDMergeListOption());
		}
		this.INTINSTRUCTORSubMenu = menu;
	}

	private modifyINTCURRCOORDSubMenu() {
		const menu = this.INTCURRCOORDSubMenu;
		if (this.displayCertAlertMenu) {
			menu.splice(1, 0, this.certAlertDepartmentSubscriptions());
			menu.splice(2, 0, this.certAlertDealerSubscriptions());
		}
		if (this.securityProfile.includes('SPEMLTXT')) {
			menu.splice(3, 0, this.createTargetedCommunicationsOption());
		}
		if (this.securityProfile.includes('SPIDMERGE')) {
			menu.splice(11, 0, this.starsIDMergeListOption());
		}
		this.INTCURRCOORDSubMenu = menu;
	}

	private modifyINTCTRYADMINSubMenu() {
		const menu = this.INTCTRYADMINSubMenu;
		if (this.displayCertAlertMenu) {
			menu.splice(1, 0, this.certAlertDepartmentSubscriptions());
			menu.splice(2, 0, this.certAlertDealerSubscriptions());
		}
		if (this.securityProfile.includes('SPEMLTXT')) {
			menu.splice(3, 0, this.createTargetedCommunicationsOption());
		}
		if (this.securityProfile.includes('SPIDMERGE')) {
			menu.splice(12, 0, this.starsIDMergeListOption());
		}
		this.INTCTRYADMINSubMenu = menu;
	}

	private modifyINTBUSADMINSubMenu() {
		const menu = this.INTBUSADMINSubMenu;
		if (this.displayCertAlertMenu) {
			menu.splice(1, 0, this.certAlertDepartmentSubscriptions());
			menu.splice(2, 0, this.certAlertDealerSubscriptions());
		}
		if (this.securityProfile.includes('SPEMLTXT')) {
			menu.splice(3, 0, this.createTargetedCommunicationsOption());
		}
		if (this.securityProfile.includes('SPIDMERGE')) {
			menu.splice(16, 0, this.starsIDMergeListOption());
		}
		this.INTBUSADMINSubMenu = menu;
	}

	starsIDMergeListOption() {
		return {
			menuName: this.translate.instant('Administration.StarsIdMergeList'),
			path: '',
			isDisabled: true
		};
	}

	createTargetedCommunicationsOption() {
		return {
			menuName: this.translate.instant('Administration.CreateTargetedCommunications'),
			path: '',
			isDisabled: true
		};
	}

	certAlertDealerSubscriptions() {
		return {
			menuName: this.translate.instant('Administration.CertAlertDealerSubscriptions'),
			path: '/corporateAdmin/cert-alert-subscriptions/dealer-subscriptions',
			isDisabled: false
		};
	}
	certAlertDepartmentSubscriptions() {
		return {
			menuName: this.translate.instant('Administration.CertAlertSubscriptions'),
			path: '/corporateAdmin/cert-alert-subscriptions',
			isDisabled: false
		};
	}

	/* Open the sidenav */
	openSideNav(event: any) {
		this.mySideNavOpen = event;
	}

	/* Close/hide the sidenav */
	closeSideNav() {
		this.mySideNavOpen = false;
		this.mySideNavSubMenuOpen = false;
		this.currentRoute = '';
	}

	// method for learner view nav bar highlight
	toggleMenu(menu: string) {
		this.mySideNavOpen = false;
		this.resetToNullAndClearSessionStorage();

		this.activeMenu = menu;
		if (menu === this.leftMenus.Dashboard) {
			this.router.navigate(['dashboard']);
		} else if (menu === this.leftMenus.ToDo) {
			this.router.navigate(['to-do']);
		} else if (menu === this.leftMenus.CertSummary) {
			this.cleanRoutings();
			this.router.navigate(['certifications']);
		} else if (menu === this.leftMenus.EnrollmentActivity) {
			this.router.navigate(['enrollment-activity']);
		} else if (menu === this.leftMenus.TrainingHistory) {
			this.router.navigate(['training-history']);
		} else if (menu === this.leftMenus.CourseAndTaskCatalog) {
			this.router.navigate(['course-catalog']);
		} else if (menu === this.leftMenus.ClassroomSchedule) {
			this.router.navigate(['class-schedule']);
		} else if (menu === this.leftMenus.UserSupportInformation) {
			this.router.navigate(['user-support-information']);
		} else if (menu === this.leftMenus.eLearning) {
			this.router.navigate(['eLearning-activity']);
		} else {
			this.router.navigate(['dashboard']);
		}
	}

	// method for Admin view nav bar highlight
	onClickSideNavItem(e: Event, path: string): void {
		this.mySideNavOpen = false;
		this.mySideNavSubMenuOpen = false;
		this.resetToNullAndClearSessionStorage();
		this.cleanRoutings();
		e.preventDefault();
		e.stopPropagation();

		if (!this.router.url.includes('/manageroles') && path === '') {
			e.preventDefault();
			e.stopPropagation();
		}
		if (!this.router.url.includes('/manageroles') && path !== '') {
			this.currentRoute = '';
			this.router.navigate([path], { relativeTo: this.route });
		}
	}

	onClickCorporateNavItem(e: Event, label: string, path?: string): void {
		e.preventDefault();
		e.stopPropagation();
		this.currentRoute = label;
		this.mySideNavSubMenuOpen = true;
		this.resetToNullAndClearSessionStorage();
		if (path) {
			this.mySideNavOpen = false;
			this.router.navigate([path]);
		}
	}

	translateText(key: string) {
		return this.loginService.translateTheText(key);
	}

	// method to reset the previous values
	resetToNullAndClearSessionStorage() {
		this.loginService.getBaseComponent().patchValue(null);
		this.loginService.setPageComponent([]);
		this.loginService.setCertPlannerHistory([]);
		this.starLearning.get('starsHistory').get('selectedBubble').patchValue(null);
		this.starLearning.get('starsHistory').get('selectedPage').patchValue(null);
		this.starLearning.get('starsHistory').get('showDiscontinued').patchValue(null);
		// on toggle of main menu clearing the context of ClassroomSchedule to load the whole page
		sessionStorage.removeItem('languageList');
		sessionStorage.removeItem('regionList');
		sessionStorage.removeItem('countryList');
		sessionStorage.removeItem('classroomSelectedProps');
		sessionStorage.removeItem('classScheduleDetailsContext');
		sessionStorage.removeItem('searchData');
		// on toggle of main menu clearing the context of Employee List
		sessionStorage.removeItem('employeeListContext');
		sessionStorage.removeItem('employeeListSelectedProps');
		this.loginService.starLearning.get('adminEmployeeInfo').get('empStarsID').patchValue('');
		this.loginService.starLearning.get('adminEmployeeInfo').get('empName').patchValue('');
		this.loginService.starLearning.get('adminEmployeeInfo').get('empEmail').patchValue('');
		this.loginService.starLearning.get('adminEmployeeInfo').get('empCountry').patchValue('');
		this.loginService.starLearning.get('adminEmployeeInfo').get('isSecDealership').patchValue('');
		// this.starLearning.get('classDetails').get('courseCode').patchValue(null);
		// this.starLearning.get('classDetails').get('sessionNo').patchValue(null);
		sessionStorage.removeItem('courseCatalogContext');
		sessionStorage.removeItem('shopTechCompetencyProps');
		sessionStorage.removeItem('manageRoleContext');
		//for jobrole
		sessionStorage.removeItem('FilterSet');
		//for fleet
		sessionStorage.removeItem('FleetFilterSet');

		//Remove location properties
		this.loginService.starLearning.get('corporateRequiredInfo').get('locCode').patchValue(null);
		this.loginService.starLearning.get('corporateRequiredInfo').get('locCountry').patchValue('');
		this.loginService.starLearning.get('corporateRequiredInfo').get('locSpJointVen').patchValue('');
		//Remove eLearning
		this.loginService.starLearning.get('eLearningInfo').get('employee').patchValue(null);
		this.loginService.starLearning.get('eLearningInfo').get('courseCode').patchValue(null);
		this.loginService.starLearning.get('eLearningInfo').get('courseName').patchValue(null);
		//Remove previous copr info when navigated
		this.commonService.saveSessionContext(SESSION_STORAGE_CONSTANTS.DEALER_SEARCH_RESPONSE, null);
		//Remove training history
		this.loginService.starLearning.get('trainingHistory').get('employee').patchValue(null);
		this.loginService.starLearning.get('trainingHistory').get('courseCode').patchValue(null);
		this.loginService.starLearning.get('trainingHistory').get('courseName').patchValue(null);
		//Remove associated waitlist
		this.loginService.starLearning.get('associatedWaitlist').get('employee').patchValue(null);
		this.loginService.starLearning.get('associatedWaitlist').get('courseCode').patchValue(null);
		this.loginService.starLearning.get('associatedWaitlist').get('courseName').patchValue(null);
		//this.loginService.starLearning.get('fleetList').get('fleetCode').patchValue(null);
		// for removing dealer list info
		sessionStorage.removeItem('dealerSearchFilterSet');
		sessionStorage.removeItem('dealerDetailMode');
		sessionStorage.removeItem('dealerDetailsRecord');
		//Remove CBU related items
		sessionStorage.removeItem('CbuFilterSet');
		sessionStorage.removeItem('CbuEmployeeListFilterSet');
		sessionStorage.removeItem(SESSION_STORAGE_CONSTANTS.CBU_DETAILS_RECORD);
		sessionStorage.removeItem(SESSION_STORAGE_CONSTANTS.CBU_DETAILS_RESPONSE);
		//Remove NonScheduled Training Records
		sessionStorage.removeItem(SESSION_STORAGE_CONSTANTS.NONSCHEDULE_TRAINING_SELECTED_COURSE_RESPONSE);
		sessionStorage.removeItem(SESSION_STORAGE_CONSTANTS.NONSCHEDULE_TRAINING_SELECTED_COURSE_DATE);

		sessionStorage.removeItem('selectedTaskGroupToSend');
		sessionStorage.removeItem('selectedTaskGroupToState');
		sessionStorage.removeItem('employeeProfileInfoFromCorporate');
	}

	adminDropdownChange(section: string) {
		// save the selectiopn of section to the sessionStorage
		this.commonService.saveSessionContext('selectedAdminView', section);
		this.isAdminView = this.router.url.includes('admin') && this.commonService.getSessionData('selectedAdminView') !== 'dashboard';
		if (section !== 'dashboard') {
			// below line is to reset the selectedBubble when the admin shifts directly from Learner to Admin view
			// where the Admin will land on 'Total' bubble section on click of certifications
			this.resetToNullAndClearSessionStorage();
			// below line is to reset the announcements flags and the announcements button on the header
			this.announcementService.setDisplayAnnouncements({ displaySingleAnnouncement: false, displayAllAnnouncements: false });
			this.router.navigate(['/admin/employee-list']);
		} else {
			// below lines are to reset the Employee info and Admin Context, when the admin shifts directly from Admin to Learner view
			this.resetToNullAndClearSessionStorage();
			this.router.navigate(['dashboard']);
		}
	}

	fadeClick($event) {
		if ($event.target.classList.value.includes('change-dealership-popup modal fade show')) {
			this.closeChangeDealerShip();
		}
	}

	@HostListener('document:click', ['$event'])
	clickout(event: any): void {
		if (this.adminDropdown) {
			this.navBarMenuActive = !this.adminDropdown.nativeElement.classList.value.includes('collapsed');
			if (this.navBarMenuActive) {
				this.navBarMenuActive = this.commonService.closePopup(
					this.navBarMenuActive,
					'viewCard',
					event);
				if (!this.navBarMenuActive) {
					this.adminDropdown.nativeElement.click();
				}
			}
		}
	}

	closeChangeDealerShip() {
		if (this.prevSelectedDealerShipCode) {
			this.selectedDealerShipCode = this.prevSelectedDealerShipCode;
		} else {
			this.selectedDealerShipCode = this.loginService.loginResponseObject.result.userSessionInformation.dealerCode;
			this.selectedSubDealerShipCode = this.loginService.loginResponseObject.result.userSessionInformation.dealerSubCode;
		}
	}

	acceptDealershipChange() {
		this.loginResponse = this.loginService.getLoginResponse();
		this.loginResponse.result.userSessionInformation.dealerCode = this.selectedDealerShipCode;
		this.loginResponse.result.userSessionInformation.dealerSubCode = this.selectedSubDealerShipCode;
		this.loginResponse.result.userSessionInformation.dealerName = this.changeDealershipResponse.result.changeDealershipList.find(x => x.dealerCode === this.selectedDealerShipCode && x.dealerSubCode === this.selectedSubDealerShipCode).dealerName;
		this.dealerCode = this.selectedDealerShipCode;
		this.subDealerCode = this.selectedSubDealerShipCode;
		this.loginService.updateLoginResponse(this.loginResponse);
		this.closeModal.nativeElement.click();
		this.loginService.starLearning.get('adminEmployeeInfo').get('empStarsID').patchValue('');
		this.loginService.starLearning.get('adminEmployeeInfo').get('empName').patchValue('');
		if (this.router.url.endsWith('/admin/employee-list')) {
			this.communicationService.updateEmployeeList.next(true);
		} else {
			this.router.navigate(['/admin/employee-list']);
		}
	}

	get IsPrimaryDealerShip() {
		if (this.changeDealershipResponse) {
			return this.changeDealershipResponse.result.changeDealershipList.filter(x => x.dealerCode
				=== this.loginService.loginResponseObject.result.userSessionInformation.dealerCode && x.dealerSubCode === this.loginService.loginResponseObject.result.userSessionInformation.dealerSubCode && x.homeDealerFlag).length > 0;
		}
		return true;
	}

	getDealerships() {
		const changeDealershipRequest = new ChangeDealershipRequest();
		changeDealershipRequest.starsID = this.loginService.loginResponseObject.result.starsID;
		changeDealershipRequest.loginUserLanguageCode = this.loginService.loginResponseObject.result.userSessionInformation.userDefaultLanguageCode;
		this.changeDealershipService.getDealerships(changeDealershipRequest, 'dealerCode', 'asc').subscribe((changeDealershipResponse: ChangeDealershipResponse) => {
			this.changeDealershipResponse = changeDealershipResponse;
		},
			error => {
				this.errorOccured = true;
				this.errorMessages = this.errorService.displayErrorMessages(error);
			});
	}

	corporateAdminDropdownChange(section: string) {
		// save the selection of section to the sessionStorage
		this.commonService.saveSessionContext('selectedCorpAdminView', section);
		this.isCorporateAdminView = ((this.router.url.includes('corporateAdmin') || this.router.url.includes('corp-admin-emp-profile'))
			&& this.commonService.getSessionData('selectedCorpAdminView') !== 'to-do');
		if (section !== 'to-do') {
			// below line is to reset the selectedBubble when the admin shifts directly from Learner to Admin view
			// where the Admin will land on 'Total' bubble section on click of certifications
			this.resetToNullAndClearSessionStorage();
			// below line is to reset the announcements flags and the announcements button on the header
			this.announcementService.setDisplayAnnouncements({ displaySingleAnnouncement: false, displayAllAnnouncements: false });
			this.router.navigate(['/corporateAdmin/home']);
		} else {
			// below lines are to reset the Employee info and Admin Context, when the admin shifts directly from Admin to Learner view
			this.resetToNullAndClearSessionStorage();
			this.router.navigate(['to-do']);
		}
	}

	cleanRoutings() {
		const formArray = this.loginService.getPageComponent().value;
		while (formArray.length) {
			formArray.pop();
		}
		this.loginService.setPageComponent(formArray);
		const formArrayCourse = (this.starLearning.get('certPlanner').get('certPlannerHistory') as FormArray);
		while (formArrayCourse.value.length) {
			(this.starLearning.get('certPlanner').get('certPlannerHistory') as FormArray).value.pop();
		}
		this.loginService.saveContext();
	}

	// below code change is to know whether user is Dealer Admin or Fleet Admin
	decideUserType() {
		this.dealerCode = this.userSessionInformation.dealerCode;
		this.selectedDealerShipCode = this.userSessionInformation.dealerCode;
		this.selectedSubDealerShipCode = this.userSessionInformation.dealerSubCode;
		this.selectedDealerCodeAndSubDealerShipCode = this.userSessionInformation.dealerCode + this.userSessionInformation.dealerSubCode;
		this.subDealerCode = this.userSessionInformation.dealerSubCode;
		this.countryCode = this.userSessionInformation.countryCode;
		// below code change is to know whether user is Fleet Admin or not
		this.isFleetAdmin = this.userSessionInformation.highestSecurityProfileCode === 'FLTMANAGER';
		// below code change is to know whether user is Dealer Admin or not
		this.isDealerAdmin = this.userSessionInformation.highestSecurityProfileCode === 'DLRMANAGER';
		this.isCorporateAdminView = ((this.router.url.includes('corporateAdmin') || this.router.url.includes('corp-admin-emp-profile'))
			&& this.commonService.getSessionData('selectedCorpAdminView') !== 'to-do');
		this.isAdminView = this.router.url.includes('admin') && this.commonService.getSessionData('selectedAdminView') !== 'dashboard';
		this.isINTDLRSUPPORT = this.userSessionInformation.highestSecurityProfileCode === 'INTDLRSUPPORT';
		this.isINTINSTRUCTOR = this.userSessionInformation.highestSecurityProfileCode === 'INTINSTRUCTOR';
		this.isINTCURRCOORD = this.userSessionInformation.highestSecurityProfileCode === 'INTCURRCOORD';
		this.isINTCTRYADMIN = this.userSessionInformation.highestSecurityProfileCode === 'INTCTRYADMIN';
		this.isINTBUSADMIN = this.userSessionInformation.highestSecurityProfileCode === 'INTBUSADMIN';
	}

	openManagementReports(event: Event): void {
		event.preventDefault();
		event.stopPropagation();
		if (this.isDealerAdmin) {
			this.managementReportsURL = `${environment.managementReportsDealerURL}&dealercode=${this.dealerCode}&subcode=${this.subDealerCode}&country=${this.countryCode}`;
		} else if (this.isFleetAdmin) {
			this.managementReportsURL = `${environment.managementReportsFleetURL}&org=${this.dealerCode}&country=${this.countryCode}`;
		}
		window.open(this.managementReportsURL);
	}

	get buildName() {
		const lastName = this.loginResponse.result.userSessionInformation.empLastName;
		const firstName = this.loginResponse.result.userSessionInformation.empFirstName;
		const middleName = this.loginResponse.result.userSessionInformation.empMiddleName;

		let userName = lastName + (firstName ? ', ' + firstName : '');
		userName = userName + (firstName && middleName ? ' ' + middleName : (!firstName && middleName ? ', ' + middleName : ''));

		return userName;
	}

	clickedRadio(dealerShip) {
		this.selectedDealerShipCode = dealerShip.dealerCode;
		this.selectedSubDealerShipCode = dealerShip.dealerSubCode;
		this.selectedDealerCodeAndSubDealerShipCode = dealerShip.dealerCode + dealerShip.dealerSubCode;
	}

	gotoCBUDetails() {
		let aCbu = {
			"countryCode": this.loginResponse.result.country,
			"cbuCode": this.userSessionInformation.dealerCode || this.corporateCBUCode,
			"jointVentureCode": this.loginResponse.result.userProfileDetailsResponse.userDetailsBO.jointVentureCode
		};
		this.commonService.saveSessionContext(SESSION_STORAGE_CONSTANTS.CBU_DETAILS_RECORD, aCbu);
		this.router.navigate(['/corporateAdmin' + '/' + this.pathConstants.CBU_SEARCH + '/' + this.pathConstants.CBU_DETAILS], { relativeTo: this.route });
	}

	updateCBUCode() {
		this.commonService.getCBUCodeData().subscribe(cbuCode => {
			this.corporateCBUCode = cbuCode;
			this.loginResponse = this.loginService.getLoginResponse();
			this.userSessionInformation = this.loginResponse.result.userSessionInformation;
		});
	}
}
