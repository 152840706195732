<div class="modal-body">
	<div class="modal-header">
		<button type="button" class="close" (click)="modalService.hide(1)">
			<span aria-hidden="true">
				<img src="../../assets/images/closed.png" alt="">
			</span>
		</button>
	</div>
	<p>
		<img src="assets/images/danger.png" alt="">
		Your session is going to expire in 15 minutes and you will be logged out. Please save your work.
	</p>
</div>
