import { Injectable } from '@angular/core';
import { UserModel } from '../../login/Models/UserModel.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiEndPoints } from '../../../Constants/apiendpoints.constants';
import { CertificateRequest, CertificateResponse } from '../models/certificates.model';
import { CertHistoryRequest, CertHistoryResponse } from '../../cert-history/models/cert-history.model';
import { CertificateDescriptionRequest, CertificateDescriptionResponse } from '../models/certificate-description.model';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../services/login.service';
import { CertificateStatusListRequest, CertificateStatusListResponse, UpdateCertificationStatusRequest, UpdateCertificationStatusResponse } from '../models/certificate-update.model';

@Injectable()
export class CertificateService {
	userInfo: Array<UserModel> = [];
	appliedFilter = {
		showDiscontinued: false,
		employee: ''
	};

	constructor(private http: HttpClient,
				public translate: TranslateService,
				public loginService: LoginService) {
	}

	getCertificates(certificateRequest: CertificateRequest) {
		return this.http.post<CertificateResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getCertificates}`
			, JSON.stringify(certificateRequest));
	}

	getCertificateDescription(certificateDescriptionRequest: CertificateDescriptionRequest) {
		return this.http.post<CertificateDescriptionResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getCertificateDescription}`
			, JSON.stringify(certificateDescriptionRequest));
	}

	getCertificateHistory(certHistoryRequest: CertHistoryRequest) {
		return this.http.post<CertHistoryResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getCertificateHistory}`
			, JSON.stringify(certHistoryRequest));
	}

	getIndividualCertificatesCount(tempCertificateList, section) {
		if (tempCertificateList === undefined) {
			return 0;
		}
		if (section.toLowerCase() === this.loginService.translateTheText('Status.Total')) {
			if (this.appliedFilter.showDiscontinued) {
				return tempCertificateList.length;
			} else {
				return tempCertificateList.filter((x) =>
					x.certStatus.trim().toLowerCase() !== this.loginService.translateTheText('Status.Discontinued')).length;
			}
		}
		return tempCertificateList.filter((x) => x.certStatus.trim().toLowerCase() === section.toLowerCase()).length;
	}

	getCertificateStatusList(certificateStatusListRequest: CertificateStatusListRequest) {
		return this.http.post<CertificateStatusListResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getUpdateCertificationStatusList}`
			, JSON.stringify(certificateStatusListRequest));
	}

	updateCertificateStatus(updateCertificationStatusRequest: UpdateCertificationStatusRequest) {
		return this.http.post<UpdateCertificationStatusResponse>(`${environment.ApiBaseURL}${ApiEndPoints.updateCertificationStatus}`
			, JSON.stringify(updateCertificationStatusRequest));
	}
}
