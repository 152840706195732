import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiEndPoints } from '../../../Constants/apiendpoints.constants';
import { LaunchCourseRequest, LaunchCourseResponse, LaunchLiveWebinarCourseRequest, LaunchLiveWebinarCourseResponse, LaunchWebinarCoursesRequest, LaunchWebinarCoursesResponse } from './launch-actions.model';

@Injectable()
export class ActionService {

	constructor(private http: HttpClient) { }

	// API for all other Launch Actions
	getLaunchCourse(launchCourseRequest: LaunchCourseRequest) {
		return this.http.post<LaunchCourseResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getLaunchCourse}`
			, JSON.stringify(launchCourseRequest));
	}

	// API for Live Webinar Action
	getLaunchLiveWebinar(launchLiveWebinarCourseRequest: LaunchLiveWebinarCourseRequest) {
		return this.http.post<LaunchLiveWebinarCourseResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getLaunchWebinar}`
			, JSON.stringify(launchLiveWebinarCourseRequest));
	}

	// API for OnDemand and Replay Actions
	getLaunchOnDemandReplayWebinarCourses(launchWebinarCoursesRequest: LaunchWebinarCoursesRequest) {
		return this.http.post<LaunchWebinarCoursesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getLaunchWebinarCourses}`
			, JSON.stringify(launchWebinarCoursesRequest));
	}
}
