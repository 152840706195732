import {Component, OnInit, HostListener, Input} from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup} from '@angular/forms';
import {LogoutService} from '../services/logout.service';
import {DeclarationRequest} from '../login/Models/UserModel.model';
import {LoginService} from '../services/login.service';
import {LoginResponse} from '../login/Models/LoginResponse.model';
import {ErrorMessagesModel} from '../start-error/models/error-messages.model';
import {ErrorService} from '../services/error-service/error.service';
import * as constants from '../../Constants/countries.constants';

@Component({
	selector: 'app-privacy-statement',
	templateUrl: './privacy-statement.component.html',
	styleUrls: ['./privacy-statement.component.scss']
})
export class PrivacyStatementComponent implements OnInit {
	@Input() starLearning: FormGroup;
	showBackToTop = false;

	loginResponse: LoginResponse;
	starsID: string;
	shouldDisplayAccept = false;
	constants = constants;

	// variables for Error Messages
	errorMessages: ErrorMessagesModel;
	serverErrorOccurred: boolean;

	constructor(
		public loginService: LoginService,
		private router: Router,
		public logoutService: LogoutService,
		public errorService: ErrorService) { }

	ngOnInit() {
		this.loginResponse = this.loginService.getLoginResponse();

		if (this.loginResponse) {
			if (this.loginResponse.result.acceptFlag === 'N' && !this.constants.europeGroup2.includes(this.loginResponse.result.userSessionInformation.countryCode)) {
				this.starsID = this.loginResponse.result.userSessionInformation.starsID;
				this.shouldDisplayAccept = true;
			}
		}
		document.documentElement.scrollTop = 0;
		window.scrollTo(0, 0);
	}

	@HostListener('window:scroll', ['$event'])
	onScroll(event): void {
		this.showBackToTop = document.documentElement.scrollTop > 100;
	}

	backToLogin(event: Event): any {
		event.preventDefault();
		event.stopPropagation();
		// as sessionStorage or localStorage has userContext
		// i.e., if user is not Authenticated, we will be routing them to the login page
		// if user is Authenticated we will route them to dashboard route
		if (!sessionStorage.getItem('userContext') || !localStorage.getItem('userContext')) {
			this.router.navigate(['/login']);
		} else if (this.constants.europeGroup2.includes(this.loginResponse.result.userSessionInformation.countryCode)) {
			this.router.navigate(['/dashboard']);
		} else {
			this.logoutService.logoutAndGoLogin();
		}
	}

	acceptDeclaration() {
		const declarationRequest = new DeclarationRequest();
		declarationRequest.starsID = this.starsID;
		declarationRequest.userDefaultLanguage = this.loginResponse.result.userSessionInformation.userDefaultLanguageCode;
		this.loginService.acceptDeclarationOfConsent(declarationRequest)
			.subscribe((declarationResponse: LoginResponse) => {
				this.loginResponse.result.acceptFlag = 'Y';

				this.loginService.updateLoginResponse(this.loginResponse);
				const route = this.loginService.isAdmin ? '/admin/employee-list' : '/dashboard';
				this.router.navigate([route], { skipLocationChange: false });
			},
			(error) => {
				this.serverErrorOccurred = true;
				// below line is to populate the error messages and show the title of the page
				this.errorMessages = this.errorService.displayErrorMessages(error);
			});

	}

	backToTop() {
		document.documentElement.scrollTop = 0;
	}
}
