import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from './paginator/paginator.component';
import { TranslateModule } from '@ngx-translate/core';
import { MomentDatePipe } from 'app/pipes/moment-date.pipe';
import { DropdownComponent } from './dropdown/dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateRangePipe } from 'app/pipes/moment-daterange.pipe';
import { BackButtonComponent } from 'app/back-button/back-button.component';
import { PrintIconComponent } from 'app/print-icon/print-icon.component';
import { StartErrorComponent } from 'app/start-error/start-error.component';
import { PhoneNumberPipe } from 'app/pipes/phone-number.pipe';
import { ErrorService } from 'app/services/error-service/error.service';
import { EnrollmentActivityComponent } from 'app/enrollment-activity/components/enrollment-container/enrollment-activity.component';
import { EnrollmentCardComponent } from 'app/enrollment-activity/components/enrollment-card/enrollment-card.component';
import { EnrollmentActivityService } from 'app/enrollment-activity/services/enrollment-activity.service';
import { CourseDetailsService } from 'app/course-details/services/course-details.service';
import { ClassDetailsService } from 'app/class-details/services/classDetails.service';
import { TrainingLocationDetailsService } from 'app/training-location-details/services/training-location-details.service';
import { LoadCourseUrlService } from 'app/services/loadCourseUrlService/load-course-url.service';
import { LaunchCourseCallService } from 'app/services/launchCourseCallService/launch-course-call.service';
import { CertificatePlannerService } from 'app/certification-planner/services/certificatePlanner.service';
import { ToDoComponent } from 'app/to-do/components/to-do-container/to-do.component';
import { ToDoUptodateComponent } from 'app/to-do/components/to-do-uptodate/to-do-uptodate.component';
import { ToDoService } from 'app/to-do/services/todo.service';
import { ToDoCardComponent } from 'app/to-do/components/to-do-card/to-do-card.component';
import { EmployeeInfoForAdminComponent } from 'app/employee-information/components/employee-info-for-admin/employee-info-for-admin.component';
import { EmployeeDetailsAdminService } from 'app/employee-information/services/employee-details-admin.service';
import { ClassroomScheduleService } from 'app/classroom-schedule/services/classroom-schedule.service';
import { CertificateService } from 'app/certificates/services/certificate.service';
import { ActionService } from 'app/services/actionService/action.service';
import { AnnouncementsComponent } from 'app/announcements/components/announcements-container/announcements.component';
import { AnnouncementCardComponent } from 'app/announcements/components/announcement-card/announcement-card.component';
import { SafeHtmlPipe } from 'app/pipes/safe-html.pipe';
import { MomentTimePipe } from 'app/pipes/moment-time.pipe';
import { SurveyComponent } from 'app/survey/survey.component';
import { MissingPrerequisiteComponent } from 'app/missing-prerequisite/missing-prerequisite.component';
import { JobHistoryComponent } from 'app/job-history/components/job-history.component';
import { QuarterlyEmailComponent } from 'app/dealer-admin/employee-list/components/quarterly-email/quarterly-email.component';
import { QuarterlyEmailService } from 'app/dealer-admin/employee-list/services/quarterly-email.service';
import { WordCountComponent } from './word-count/word-count.component';
import { StarsTooltipComponent } from './stars-tooltip/stars-tooltip.component';
import { ExcelExportService } from 'app/dealer-admin/employee-list/services/excel-export-service';
import { FormsErrorComponent } from './forms-error/forms-error.component';
import { TagsComponent } from './tags/tags.component';
import { CorporateCommonInformationComponent } from 'app/corporate-admin/corporate-common-information/components/corporate-common-information.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { DetailsModalComponent } from './details-modal/details-modal.component';
import { CancelEnrollmentComponent } from 'app/dealer-admin/cancel-enrollment/cancel-enrollment.component';
import { CorpAdminEmployeeProfileModule } from 'app/corp-admin-user-profile/corp-admin-user-profile.module';
import { CorpAdminViewInstructorProfileComponent } from 'app/corp-admin-user-profile/components/view-instructor-profile/view-instructor-profile.component';
import { CorpAdminEmployeeProfileEditComponent } from 'app/corp-admin-user-profile/components/employee-profile-edit/employee-profile-edit.component';
import { CorpAdminViewUserInfoComponent } from 'app/corp-admin-user-profile/components/view-user-info/view-employee-info.component';
import { CorpAdminViewJobInfoComponent } from 'app/corp-admin-user-profile/components/view-job-info/view-job-info.component';
import { CorpAdminEditJobInfoComponent } from 'app/corp-admin-user-profile/components/edit-job-info/edit-job-info.component';
import { EditInstructorProfileComponent } from 'app/corp-admin-user-profile/components/edit-instructor-profile/edit-instructor-profile.component';
import { EditSecurityProfileComponent } from 'app/corp-admin-user-profile/components/edit-security-profile/edit-security-profile.component';
import { CorpAdminViewSecurityProfileComponent } from 'app/corp-admin-user-profile/components/view-security-profile/view-security-profile.component';
import { CorpAdminEmployeeProfileViewComponent } from 'app/corp-admin-user-profile/components/employee-profile-view/employee-profile-view.component';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from './spinner/spinner.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SelectTrainingLocationModalComponent } from './select-training-location-modal/components/select-training-location-modal.component';
import { ResetPasswordComponent } from 'app/dealer-admin/employee-list/components/reset-password/reset-password.component';
import { HotelDetailComponent } from 'app/corporate-admin/hotel/components/hotel-detail/hotel-detail.component';
import { UpdateHotelComponent } from 'app/corporate-admin/hotel/components/update-hotel/update-hotel.component';
import { DeleteHotelComponent } from 'app/corporate-admin/hotel/components/delete-hotel/delete-hotel.component';
import { LaunchLiveWebinarCourseService } from 'app/services/launchLiveWebinarCourseService/launch-live-webinar-course.service';
import { LaunchWebinarCoursesCallService } from 'app/services/launchWebinarCoursesCallService/launchWebinarCoursesCall.service';
import { WebinarScheduleService } from 'app/webinar-schedule/services/webinarSchedule.service';
import { WebinarScheduleComponent } from 'app/webinar-schedule/components/webinar-schedule.component';
import { CreateCourseService } from 'app/corporate-admin/course/services/create-course.service';
import { CorpCourseDetailsService } from 'app/corporate-admin/corp-course-details/services/corp-course-details.service';
import { CreateCourseTwoComponent } from 'app/corporate-admin/course/components/create-course_two/create-course-two.component';
import { MantainEquivalenciesComponent } from 'app/corporate-admin/course/components/equivalencies/mantain-equivalencies.component';
import { MaintainUrlAddComponent } from 'app/corporate-admin/course/components/maintain-url/maintain-url-add/maintain-url-add.component';
import { MaintainUrlUpdateComponent } from 'app/corporate-admin/course/components/maintain-url/maintain-url-update/maintain-url-update.component';
import { CourseListComponent } from 'app/corporate-admin/course/components/course-list/course-list.component';
import { PrerequisitesComponent } from 'app/corporate-admin/course/components/prerequisites/prerequisites.component';
import { MaintainRolesAddComponent } from 'app/corporate-admin/course/components/maintain-roles/maintain-roles-add/maintain-roles-add.component';
import { TrainingLocationService } from 'app/corporate-admin/location/services/training-location.service';
@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule
	],
	declarations: [
		PaginatorComponent,
		MomentDatePipe,
		DropdownComponent,
		MomentDateRangePipe,
		BackButtonComponent,
		PrintIconComponent,
		StartErrorComponent,
		PhoneNumberPipe,
		EnrollmentActivityComponent,
		EnrollmentCardComponent,
		ToDoComponent,
		ToDoUptodateComponent,
		ToDoCardComponent,
		EmployeeInfoForAdminComponent,
		AnnouncementsComponent,
		AnnouncementCardComponent,
		SafeHtmlPipe,
		WebinarScheduleComponent,
		MomentTimePipe,
		SurveyComponent,
		MissingPrerequisiteComponent,
		JobHistoryComponent,
		QuarterlyEmailComponent,
		WordCountComponent,
		StarsTooltipComponent,
		FormsErrorComponent,
		TagsComponent,
		CorporateCommonInformationComponent,
		DeleteModalComponent,
		DetailsModalComponent,
		CancelEnrollmentComponent,
		CorpAdminEmployeeProfileEditComponent,
		CorpAdminViewUserInfoComponent,
		CorpAdminViewJobInfoComponent,
		CorpAdminEditJobInfoComponent,
		CorpAdminViewInstructorProfileComponent,
		CorpAdminEmployeeProfileViewComponent,
		CorpAdminViewInstructorProfileComponent,
		CorpAdminViewSecurityProfileComponent,
		EditSecurityProfileComponent,
		EditInstructorProfileComponent,
		SpinnerComponent,
		CheckboxComponent,
		SelectTrainingLocationModalComponent,
		ResetPasswordComponent,
		HotelDetailComponent,
		UpdateHotelComponent,
		DeleteHotelComponent,
		CreateCourseTwoComponent,
		MantainEquivalenciesComponent,
		MaintainUrlAddComponent,
		MaintainUrlUpdateComponent,
		MaintainRolesAddComponent,
		CourseListComponent,
		PrerequisitesComponent,
	],
	exports: [
		PaginatorComponent,
		DropdownComponent,
		MomentDatePipe,
		MomentDateRangePipe,
		PrintIconComponent,
		BackButtonComponent,
		StartErrorComponent,
		PhoneNumberPipe,
		EnrollmentActivityComponent,
		EnrollmentCardComponent,
		ToDoComponent,
		ToDoUptodateComponent,
		ToDoCardComponent,
		EmployeeInfoForAdminComponent,
		AnnouncementsComponent,
		AnnouncementCardComponent,
		SafeHtmlPipe,
		MomentTimePipe,
		SurveyComponent,
		MissingPrerequisiteComponent,
		JobHistoryComponent,
		QuarterlyEmailComponent,
		WordCountComponent,
		StarsTooltipComponent,
		FormsErrorComponent,
		TagsComponent,
		CorporateCommonInformationComponent,
		DeleteModalComponent,
		DetailsModalComponent,
		CancelEnrollmentComponent,
		CorpAdminEmployeeProfileEditComponent,
		CorpAdminViewUserInfoComponent,
		CorpAdminViewJobInfoComponent,
		CorpAdminEditJobInfoComponent,
		CorpAdminViewInstructorProfileComponent,
		CorpAdminEmployeeProfileViewComponent,
		CorpAdminViewInstructorProfileComponent,
		CorpAdminViewSecurityProfileComponent,
		EditSecurityProfileComponent,
		EditInstructorProfileComponent,
		SpinnerComponent,
		CheckboxComponent,
		SelectTrainingLocationModalComponent,
		ResetPasswordComponent,
		HotelDetailComponent,
		UpdateHotelComponent,
		DeleteHotelComponent,
		CreateCourseTwoComponent,
		MantainEquivalenciesComponent,
		MaintainUrlAddComponent,
		MaintainUrlUpdateComponent,
		MaintainRolesAddComponent,
		CourseListComponent,
		PrerequisitesComponent,
	],
	providers: [
		ErrorService,
		CertificateService,
		CertificatePlannerService,
		EnrollmentActivityService,
		CourseDetailsService,
		ClassDetailsService,
		TrainingLocationDetailsService,
		LoadCourseUrlService,
		LaunchCourseCallService,
		LaunchLiveWebinarCourseService,
		LaunchWebinarCoursesCallService,
		ToDoService,
		EmployeeDetailsAdminService,
		ClassroomScheduleService,
		ActionService,
		WebinarScheduleService,
		QuarterlyEmailService,
		ExcelExportService,
		TrainingLocationService,
		CreateCourseService,
		CorpCourseDetailsService,
	]
})
export class SharedModule {
}
