import {Component, OnInit} from '@angular/core';
import {AnnouncementResponseResult} from '../../models/announcements.model';
import {TranslateService} from '@ngx-translate/core';
import {AnnouncementService} from '../../services/announcement.service';

@Component({
	selector: 'app-announcements',
	templateUrl: './announcements.component.html',
	styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit {
	announcements: AnnouncementResponseResult[];

	// this flag is used for opening and closing of all announcements
	openAllAnnouncements: boolean;
	// this flag is used for opening the respective announcement and also to navigate back to all Announcements
	respectiveAnnouncement: boolean;
	// below variable is used to populate the respective announcement info
	clickedAnnouncement: AnnouncementResponseResult;
	// below variable is used for manipulating the result object to populate announcement info
	announcementBodyContent: string[];

	constructor(public translate: TranslateService,
				public announcementService: AnnouncementService) {}

	ngOnInit() {
		window.scrollTo(0, 0);
		this.announcementService.getAnnouncementsData().subscribe(data => {
			this.announcements = data;
		});
		this.announcementService.getSelectedAnnouncement().subscribe( selectedAnnouncement => {
			this.openRespectiveAnnouncement(selectedAnnouncement);
		});
		this.announcementService.getDisplayAnnouncements().subscribe((res) => {
			this.openAllAnnouncements = res.displayAllAnnouncements;
			this.respectiveAnnouncement = res.displaySingleAnnouncement;
		});
	}

	// method is used to close announcements window completely
	closeAnnouncementsWindow() {
		this.announcementService.setDisplayAnnouncements({displaySingleAnnouncement: false, displayAllAnnouncements: false});
	}

	// method is used to go back to all announcements from an announcement
	goToAllAnnouncements() {
		window.scrollTo(0, 0);
		this.announcementService.setDisplayAnnouncements({displaySingleAnnouncement: false, displayAllAnnouncements: true});
	}

	// method is used to open respective announcement from the announcements window
	openRespectiveAnnouncement(announcement: any) {
		if (announcement) {
			window.scrollTo(0, 0);
			this.announcementService.setDisplayAnnouncements({displaySingleAnnouncement: true, displayAllAnnouncements: false});
			this.clickedAnnouncement = announcement;
			this.announcementBodyContent = this.clickedAnnouncement.body.replace(`/\r\n\<ul>\<li>\<a>/`, `~`).split(`~`);
		}
	}
}
