<div _ngcontent-c21="" class="custom-select-outer" [ngStyle]="{'width' : width}" [ngClass]="{'disabled' : disabled, 'custom-select-outer-waitlist': isSubmitWaitlist, 'custom-select-outer-rm-0': removeRightMargin, 'customer-support': isCustomerSupport }" id="paginationDiv"
	 ng-reflect-klass="custom-select-outer" ng-reflect-ng-class="[object Object]">

	<div class="custom-select" (click)="dropdownActive = !dropdownActive" [id]="id">
		<div class="select-selected" [ngClass]="{'select-arrow-active' : dropdownActive, 'select-selected-waitlist': isSubmitWaitlist, 'invalid-selection': invalidSelection }">
			<ng-container *ngIf="selectedItem && selectedItem[displayField] != ''">
				{{selectedItem[displayField]}}
			</ng-container>
			<ng-container *ngIf="(selectedItem !== undefined || (selectedItem && selectedItem[displayField] == '')) && !required">
				&nbsp;
			</ng-container>
			<ng-container *ngIf="selectedItem === undefined && !required">
				&nbsp;
			</ng-container>
		</div>
		<div #listOptionsOpen class="select-items" *ngIf="dropdownActive">
			<div *ngIf="!required" (click)="dropDownSelected(undefined)">&nbsp;</div>
			<div [ngClass]="{'same-as-selected' : selectedItem && option[valueField] === selectedItem[valueField]}"
				 *ngFor="let option of options; let i = index;" (click)="dropDownSelected(option)">
				{{ option[displayField] }}
			</div>
		</div>
	</div>
</div>
