import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CommunicationService {

	updateEmployeeList: Subject<any> = new Subject<any>();

}
