import { Component, OnInit } from '@angular/core';
import {environment} from '../../environments/environment';

@Component({
	template: `<h3>Redirecting to login server</h3>`
})
export class ADFSLoginComponent implements OnInit {

	endPoint = `${environment.adfsBaseURL}`
		+ '?client_id=' + `${environment.clientId}`
		+ '&resource=' + `${environment.resource}`
		+ '&response_type=token'
		+ '&redirect_uri=' + encodeURIComponent(window.location.origin + '/');

	ngOnInit() {
		window.location.replace(this.endPoint);
	}
}
