import {Injectable} from '@angular/core';

@Injectable()
export class TimeDateService {
	displayTimeDate: any;

	constructor() {}

	callForDateTime() {
		this.displayTimeDate = this.checkEST();
		return this.displayTimeDate;
	}

	checkEST(): any {
		const now = new Date();

		// Check for Daylight Savings Time
		const jan = new Date(now.getFullYear(), 0, 1);
		const jul = new Date(now.getFullYear(), 6, 1);
		const stdTimezoneOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
		const isDaylightSavingsTime = (now.getTimezoneOffset() < stdTimezoneOffset);

		// Convert local time to UTC
		const nowUTCHour = now.getUTCFullYear();
		const nowUTCMonth = now.getUTCMonth();
		const nowUTCDate = now.getUTCDate();
		const nowUTCHours = now.getUTCHours();
		const nowUTCMins = now.getUTCMinutes();
		const nowUTCSecs = now.getUTCSeconds();
		const nowUTCMs = now.getUTCMilliseconds();
		const utc_timestamp = Date.UTC(nowUTCHour, nowUTCMonth, nowUTCDate, nowUTCHours, nowUTCMins, nowUTCSecs, nowUTCMs);

		// Determine  Eastern Time from UTC
		const today = new Date(utc_timestamp);
		const localOffset = today.getTimezoneOffset() * 60000;
		const hoursBehindEST = (isDaylightSavingsTime) ? -4 : -5;
		const todayUTC = today.getTime() + localOffset;
		const estOffset = 3600000 * hoursBehindEST;
		return new Date(todayUTC + estOffset);
	}

}
