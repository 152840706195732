import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LoginService } from '../login.service';
import { CommonService } from '../common.service';
import { TranslateService } from '@ngx-translate/core';
import { PageHistoryService } from '../pageHistoryService/page-history.service';
import { PopupBlockerService } from '../popupBlockerService/popup-blocker.service';
import { ErrorService } from '../error-service/error.service';
import { ActionService } from '../actionService/action.service';
import { LaunchLiveWebinarCourseRequest, LaunchLiveWebinarCourseResponse } from '../actionService/launch-actions.model';
import { LoadCourseUrlService } from '../loadCourseUrlService/load-course-url.service';

@Injectable()
export class LaunchLiveWebinarCourseService {
    serverErrorOccurred = false;
    loginResponse = this.loginService.getLoginResponse();

    constructor(public loginService: LoginService,
        public commonService: CommonService,
        public translate: TranslateService,
        public pageHistoryService: PageHistoryService,
        public popupBlockerService: PopupBlockerService,
        public errorService: ErrorService,
        public actionService: ActionService,
        public loadCourseUrlService: LoadCourseUrlService) { }

    // method to Launch the Live Webinar Course
    launchLiveWebinar(webinarCourseCode: string, launchWebinarValue: boolean, pageValue: any, currentUrl?: any) {
        const launchLiveWebinarCourseRequest: LaunchLiveWebinarCourseRequest = new LaunchLiveWebinarCourseRequest();
        launchLiveWebinarCourseRequest.starsID = this.loginResponse.result.userSessionInformation.starsID;
        launchLiveWebinarCourseRequest.country = this.loginResponse.result.userSessionInformation.countryCode;
        if (!environment.launchWebinarAdobeServerCode) {
            launchLiveWebinarCourseRequest.adobeServerCode = 'NP';
        } else {
            launchLiveWebinarCourseRequest.adobeServerCode = 'USA';
        }
        launchLiveWebinarCourseRequest.launchWebinar = launchWebinarValue;
        launchLiveWebinarCourseRequest.courseCode = webinarCourseCode;
        launchLiveWebinarCourseRequest.lang = this.loginResponse.result.userSessionInformation.userDefaultLanguageCode;

        this.actionService.getLaunchLiveWebinar(launchLiveWebinarCourseRequest)
            .subscribe((launchLiveWebinarCourseResponse: LaunchLiveWebinarCourseResponse) => {
                this.loadCourseUrlService.loadWebinarCourseUrl(launchLiveWebinarCourseResponse);
                this.serverErrorOccurred = false;
            },
                error => {
                    // console.log(error);
                    this.serverErrorOccurred = true;
                    this.errorService.handleLaunchCourseServiceError(error, pageValue, currentUrl);
                });
    }
}