import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TrainingLocationDetailsRequest, TrainingLocationDetailsResponse} from '../models/training-location-details.model';
import {environment} from '../../../environments/environment';
import {ApiEndPoints} from '../../../Constants/apiendpoints.constants';
import {LoginService} from '../../services/login.service';
import {PageHistoryService} from '../../services/pageHistoryService/page-history.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HotelDetailsRequest, HotelDetailsResponse} from '../models/hotel-details.model';

@Injectable()
export class TrainingLocationDetailsService {

	constructor(private http: HttpClient,
				public loginService: LoginService,
				public pageHistoryService: PageHistoryService,
				public router: Router,
				public route: ActivatedRoute) {}

	getTrainingLocationDetails(trainingLocationDetailsRequest: TrainingLocationDetailsRequest) {
		return this.http.post<TrainingLocationDetailsResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getTrainingLocationDetails}`
			, JSON.stringify(trainingLocationDetailsRequest));
	}

	getHotelDetails(hotelDetailsRequest: HotelDetailsRequest) {
		return this.http.post<HotelDetailsResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getHotelDetails}`,
			JSON.stringify(hotelDetailsRequest));
	}

	loadTrainingLocationDetails(course: any, route: any) {
		this.loginService.starLearning.get('trainingLocationDetails').get('courseCode').patchValue(course.courseCode);
		this.loginService.starLearning.get('trainingLocationDetails').get('courseTitle').patchValue(course.courseTitle);
		this.loginService.starLearning.get('trainingLocationDetails').get('locationCode').patchValue(course.locCode || course.trainingLocCode || course.locationCode);
		this.loginService.starLearning.get('trainingLocationDetails').get('country').patchValue(course.locCountry || course.locationCountryCode);
		this.pageHistoryService.pushPageValueIntoPageHistory(this.router.url);
		this.router.navigate(['training-location'], {relativeTo: route});

	}
}
