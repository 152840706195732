<div class="pre-reques-model" id="missing-prerequisites">
	<div class="modal-header">
		<h5 class="modal-title">
			{{'Launch_Action_Language_Dropdown.choose_language' | translate}}
		</h5>
	</div>

	<div class="modal-body course-language-option">
		<div class="course-language-option">
			{{'Launch_Action_Language_Dropdown.msg' | translate }}
		</div>
		<span style="color:red">*</span>{{'TableHeaders.language' | translate }}
		<div style="width: 400px">
			<app-dropdown [options]="languageOptions" [required]="true  " [displayField]="displayField" [valueField]="valueField" [(ngModel)]="selectedLanguage">
			</app-dropdown>
		</div>
	</div>
	<div style="display: flex;flex: 1;justify-content: center;padding-top: 15px;">
		<a href="javascript:void(0)" class="btn" (click)="closeModal()" data-bs-dismiss="modal">
			{{'Buttons.cancel' | translate}}
		</a>
		<a href="javascript:void(0)" class="normal-btn" (click)="continueWithOptionalLanguage()" data-bs-dismiss="modal">
			{{'Buttons.continue' | translate}}
		</a>
	</div>
</div>