import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {
	UserSupportInformationRequest,
	UserSupportInformationResponse
} from '../../user-support-information/models/user-support-information.model';
import {AnnouncementResponseResult} from '../models/announcements.model';
import {ErrorMessagesModel} from '../../start-error/models/error-messages.model';
import {AnnouncementsAndUserSupportInformationService} from '../../user-support-information/services/announcements-and-user-support-information.service';
import {ErrorService} from '../../services/error-service/error.service';

export interface ToggleAnnouncement {
	displaySingleAnnouncement?: boolean;
	displayAllAnnouncements?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class AnnouncementService {
	private announcementsData$: BehaviorSubject<any> = new BehaviorSubject([]);
	private displayAnnouncements$: Subject<ToggleAnnouncement> = new Subject<ToggleAnnouncement>();
	private selectedAnnouncement$: BehaviorSubject<any> = new BehaviorSubject(undefined);

	// for Announcements
	userSupportInformationResponse: UserSupportInformationResponse;
	announcements: AnnouncementResponseResult[] = [];

	// variables for Error Messages
	errorMessages: ErrorMessagesModel;
	serverErrorOccurred: boolean;

	constructor(public announcementsAndUserSupportInformationService: AnnouncementsAndUserSupportInformationService,
				public errorService: ErrorService,
				public injector: Injector) {}

	/** sets the announcementsData value
	 *
	 * @param announcementsData
	 */
	setAnnouncementsData(announcementsData: any) {
		this.announcementsData$.next(announcementsData);
	}
	/**
	 *
	 * @returns the announcementsData as per user action
	 */
	getAnnouncementsData(): Observable<any> {
		return this.announcementsData$.asObservable();
	}

	/** sets the displayAnnouncements data of ToggleAnnouncement interface
	 *
	 * @param displayAnnouncements
	 */
	setDisplayAnnouncements(data: ToggleAnnouncement) {
		this.displayAnnouncements$.next(data);
	}
	/**
	 *
	 * @returns the displayAnnouncements as per user action
	 */
	getDisplayAnnouncements() {
		return this.displayAnnouncements$.asObservable();
	}

	/** sets the selectedAnnouncement value
	 *
	 * @param selectedAnnouncement
	 */
	setSelectedAnnouncement(selectedAnnouncement: any) {
		this.selectedAnnouncement$.next(selectedAnnouncement);
	}

	/**
	 *
	 * @returns the selectedAnnouncement as per user action
	 */
	getSelectedAnnouncement(): Observable<any> {
		return this.selectedAnnouncement$.asObservable();
	}

	loadAnnouncements(loginResponse: any) {
		// preparing the request object for Announcements
		if (loginResponse.result) {
			const userSupportInformationRequest = new UserSupportInformationRequest();
			userSupportInformationRequest.certCode = '';
			userSupportInformationRequest.country = loginResponse.result.userSessionInformation.countryCode;
			userSupportInformationRequest.departmentCode = loginResponse.result.userSessionInformation.departmentCode;
			userSupportInformationRequest.jointVentureCode = loginResponse.result.userSessionInformation.jointVentureCode;
			userSupportInformationRequest.lastUpdateTime = '';
			userSupportInformationRequest.starsID = loginResponse.result.userSessionInformation.starsID;
			userSupportInformationRequest.userDefaultLanguage = loginResponse.result.userSessionInformation.userDefaultLanguageCode;

			// utilizing the announcementsAndUserSupportInformationService and calling the getAnnouncementsAndUserSupportInformation API get response
			this.announcementsAndUserSupportInformationService.getAnnouncementsAndUserSupportInformation(userSupportInformationRequest)
				.subscribe((userSupportInformationResponse: UserSupportInformationResponse) => {
					this.userSupportInformationResponse = userSupportInformationResponse;
					// populating the list of items for Announcements
					this.filteringAnnouncements(this.userSupportInformationResponse);
				}, (error) => {
					// console.log(error);
					this.serverErrorOccurred = true;
					// below line is to populate the error messages and show the title of the page
					this.errorMessages = this.errorService.displayErrorMessages(error);
				});
		}
	}

	filteringAnnouncements(userSupportInformationResponse: UserSupportInformationResponse) {
		const list = userSupportInformationResponse.result;
		for (const item of list) {
			if (item.messageType === 'A') {
				this.announcements.push(item);
			}
		}
		this.setAnnouncementsData(this.announcements);
	}
}

