import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QuarterlyEmailRequest } from '../models/quarterly-email.model';
import {LoginResponse} from '../../../login/Models/LoginResponse.model';
import {environment} from '../../../../environments/environment';
import {ApiEndPoints} from '../../../../Constants/apiendpoints.constants';

@Injectable()
export class QuarterlyEmailService {

	constructor(private http: HttpClient) {	}

	updateQuarterlyEmail(quarterlyEmailRequest: QuarterlyEmailRequest) {
		return this.http.post<LoginResponse>(`${environment.ApiBaseURL}${ApiEndPoints.updateQuarterlyEmail}`
			, JSON.stringify(quarterlyEmailRequest));
	}
}
