import {Injectable} from '@angular/core';


@Injectable()
export class PopupBlockerService {

	constructor() {}

	checkPopUpBlocker(launchCourseURL: any) {
		const popUp = window.open(`${launchCourseURL}`, 'launch');
		if (popUp == null || typeof(popUp) === 'undefined') {
			alert('A pop-up blocker may be preventing the application from opening the page. Please go to your browser settings to turn off your pop-up blocker and try again.');
		} else {
			window.open(launchCourseURL, 'launch');
		}
	}

}
