import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoginUserDashboard implements CanActivate {
	constructor(private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		// route the user to dashboard url once the user is logged in and access any end point of the application
		// if not authenticated user will be routed to login page
		if ((localStorage.getItem('userContext'))
			|| (localStorage.getItem('userContext')
				&& localStorage.getItem('redirectURL').includes('access_token'))
		) {
			this.router.navigate(['/dashboard']);
			return false;
		}
		return true;
	}
}
