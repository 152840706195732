import {Injectable} from '@angular/core';
import {LoginService} from '../login.service';
import {CommonService} from '../common.service';
import {TranslateService} from '@ngx-translate/core';
import {PageHistoryService} from '../pageHistoryService/page-history.service';
import {PopupBlockerService} from '../popupBlockerService/popup-blocker.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorService} from '../error-service/error.service';
import {LaunchCourseRequest, LaunchCourseResponse} from '../actionService/launch-actions.model';
import {ActionService} from '../actionService/action.service';
import {LoadCourseUrlService} from '../loadCourseUrlService/load-course-url.service';
import { Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {LaunchCourseMissingPrereqComponent} from '../../launch-course-missing-prereq/launch-course-missing-prereq.component';
import {CourseLanguageOptionsComponent} from '../../course-language-options/course-language-options.component';

@Injectable()
export class LaunchCourseCallService {
	launchCourseResponse: LaunchCourseResponse;
	serverErrorOccurred = false;
	loginResponse = this.loginService.getLoginResponse();
	errorOccurred$ = new Subject<any>();
	public modalRef: BsModalRef;

	constructor(public loginService: LoginService,
				public commonService: CommonService,
				public translate: TranslateService,
				public pageHistoryService: PageHistoryService,
				public popupBlockerService: PopupBlockerService,
				public router: Router,
				public route: ActivatedRoute,
				public errorService: ErrorService,
				public actionService: ActionService,
				public loadCourseUrlService: LoadCourseUrlService,
				public modalService: BsModalService) {}

	// method to decide whether to Launch the Course or Informal Learning Course
	launchCourseCall(course?: any, urlType?: string, route?: any, currentUrl?: any, modal?: any, showErrorInPopup?: boolean, optionalLanguage?: string) {
		this.loginResponse = this.loginService.getLoginResponse();
		const launchCourseRequest: LaunchCourseRequest = new LaunchCourseRequest();
		launchCourseRequest.courseCode = course.courseCode || course.taskCode;
		launchCourseRequest.countryCode = this.loginResponse.result.userSessionInformation.countryCode;
		launchCourseRequest.deliveryMethod = course.deliveryMethodCode;
		launchCourseRequest.urlType = urlType;
		launchCourseRequest.starsID = this.loginResponse.result.starsID;
		launchCourseRequest.userDefaultLanguage = this.loginResponse.result.userDefaultLanguage;

		this.actionService.getLaunchCourse(launchCourseRequest).subscribe((launchCourseResponse: LaunchCourseResponse) => {
				this.serverErrorOccurred = false;
			if(launchCourseResponse.result.courseURLs.filter(x => x.courseLang === this.loginResponse.result.userDefaultLanguage).length === 0){
					this.openCourseLanguageOptions(launchCourseResponse.result.courseURLs, {launchCourseResponse: launchCourseResponse} );
				}
				else{
					if (course.deliveryMethodCode === 'INFLRN') {
						this.loadCourseUrlService.loadLaunchInformalLearningCourse(launchCourseResponse);
					} else {
						this.loadCourseUrlService.loadLaunchCourse(launchCourseResponse, optionalLanguage);
					}
				}
			},
			error => {
				//To handle launchCourseMissingPreRequisites
				if (error.error.message === 'ValidationFailureMessage') {
					this.openMissingPreReq(error.error.result.missingPrereqResponse.mapCourseList, error.error.errorMsgDesc);
				} else {
					// console.log(error);
					if (!showErrorInPopup) {
						this.serverErrorOccurred = true;
						this.errorService.handleLaunchCourseServiceError(error, route, currentUrl);
						//Class Schedule - Materials error page
						if (modal) {
							modal.nativeElement.click();
						}
					} else {
						this.errorOccurred$.next(error);
					}
				}
				//End
			});

	}

	openCourseLanguageOptions(languageOptions, courseCallParams){
		const initialState = {
			languageOptions: languageOptions,
			launchCourseParameters: courseCallParams,
			title: '',
			closeBtnName: ''
		};
		this.modalRef = this.modalService.show(CourseLanguageOptionsComponent, {
			initialState, class: 'modal-enforce-prereq'
		});
		this.modalRef.content.emitData = new Subject();
		this.modalRef.content.emitData.subscribe(() => {
			this.modalRef.hide();
		});

		this.modalRef.content.emitCourseData = new Subject();
		this.modalRef.content.emitCourseData.subscribe((courseData) => {
			this.modalRef.hide();
			if (courseData.launchCourseResponse.deliveryMethodCode === 'INFLRN') {
				this.loadCourseUrlService.loadLaunchInformalLearningCourse(courseData.launchCourseResponse);
			} else {
				this.loadCourseUrlService.loadLaunchCourse(courseData.launchCourseResponse, courseData.optionalLanguage);
			}
		})
	}

	openMissingPreReq(missinPreReqData, errorMessage) {
		const initialState = {
			missingPreReqData: missinPreReqData,
			title: 'Missing Prerequisites',
			closeBtnName: 'Close',
			errorMessage: errorMessage
		};
		this.modalRef = this.modalService.show(LaunchCourseMissingPrereqComponent, {
			initialState, class: 'modal-enforce-prereq'
		});
		this.modalRef.content.emitData = new Subject();
		this.modalRef.content.emitData.subscribe(() => {
			this.modalRef.hide();
		});
	}
}

