import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ToDoRequest, ToDoResponse} from '../models/to-do.model';
import {environment} from '../../../environments/environment';
import {ApiEndPoints} from '../../../Constants/apiendpoints.constants';

@Injectable()
export class ToDoService {
	constructor(private http: HttpClient) {

	}
	getToDoList(toDoRequest: ToDoRequest) {
		return this.http.post<ToDoResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getToDoList}`
			, JSON.stringify(toDoRequest));
	}
}
