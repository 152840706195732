import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ApiEndPoints} from '../../../../Constants/apiendpoints.constants';
import { AssociatedWLRequest, AvailableLangsForCountriesRequest, AvailableLangsForCountriesResponse, CourseEquivalenciesRequest, CourseEquivalenciesResponse, CourseExistingPrerequisitesRequest, CoursePrerequisitesRequest, CoursePrerequisitesResponse, CourseRolesRequest, CourseRolesResponse, CreateCourseRequest, CreateCourseResponse, MaintainUrlRequest, MaintainUrlResponse, ValidateCourseCodeRequest, ValidateCourseCodeResponse } from '../models/corp-course.model';

@Injectable()
export class CreateCourseService {
	constructor(
		private http: HttpClient) {
	}

	validateCourseCode(validateCourseCodeRequest: ValidateCourseCodeRequest) {
		return this.http.post<ValidateCourseCodeResponse>(`${environment.ApiBaseURL}${ApiEndPoints.validateCourseCode}`
			, JSON.stringify(validateCourseCodeRequest));
	}

	getAvailableLangsForCountries(availableLangsForCountriesRequest: AvailableLangsForCountriesRequest) {
		return this.http.post<AvailableLangsForCountriesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getAvailableLangsForCountries}`
			, JSON.stringify(availableLangsForCountriesRequest));
	}

	createCoursePageOne(createCourseRequest: CreateCourseRequest) {
		return this.http.post<CreateCourseResponse>(`${environment.ApiBaseURL}${ApiEndPoints.createCourse}`
			, JSON.stringify(createCourseRequest));
	}

	getExistingEquivCourses(equivalencieRequest: CourseEquivalenciesRequest) {
		return this.http.post<CourseEquivalenciesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getExistingEquivCourses}`
			, JSON.stringify(equivalencieRequest));
	}

	getAvailableEquivCourses(equivalencieRequest: CourseEquivalenciesRequest) {
		return this.http.post<CourseEquivalenciesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getAvailableEquivCourses}`
			, JSON.stringify(equivalencieRequest));
	}

	addEquivalencyCourses(equivalencieRequest: CourseEquivalenciesRequest) {
		return this.http.post<CourseEquivalenciesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.addEquivalencyCourses}`
			, JSON.stringify(equivalencieRequest));
	}

	removeEquivCourses(equivalencieRequest: CourseEquivalenciesRequest) {
		return this.http.post<CourseEquivalenciesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.removeEquivCourses}`
			, JSON.stringify(equivalencieRequest));
	}

	getExistingPrerequisites(prerequisitesRequest: CourseExistingPrerequisitesRequest) {
		return this.http.post<CoursePrerequisitesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getExistingPrerequisites}`
			, JSON.stringify(prerequisitesRequest));
	}

	addPrerequisites(coursePrerequisitesRequest: CoursePrerequisitesRequest) {
		return this.http.post<CoursePrerequisitesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.addPrerequisites}`
			, JSON.stringify(coursePrerequisitesRequest));
	}

	getAvailablePrerequisites(coursePrerequisitesRequest: CoursePrerequisitesRequest) {
		return this.http.post<CoursePrerequisitesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getAvailablePrerequisites}`
			, JSON.stringify(coursePrerequisitesRequest));
	}

	removePrerequisites(coursePrerequisitesRequest: CoursePrerequisitesRequest) {
		return this.http.post<CoursePrerequisitesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.removePrerequisites}`
			, JSON.stringify(coursePrerequisitesRequest));
	}

	associateWLCourse(associatedWLRequest: AssociatedWLRequest) {
		return this.http.post<ValidateCourseCodeResponse>(`${environment.ApiBaseURL}${ApiEndPoints.associateWLCourse}`
			, JSON.stringify(associatedWLRequest));
	}

	geturlCountries(urlCountriesRequest: MaintainUrlRequest) {
		return this.http.post<MaintainUrlResponse>(`${environment.ApiBaseURL}${ApiEndPoints.urlCountries}`
			, JSON.stringify(urlCountriesRequest));
	}

	validateURLType(mantainUrlRequest: MaintainUrlRequest) {
		return this.http.post<MaintainUrlResponse>(`${environment.ApiBaseURL}${ApiEndPoints.validateURLType}`
			, JSON.stringify(mantainUrlRequest));
	}

	saveURL(mantainUrlRequest: MaintainUrlRequest) {
		return this.http.post<MaintainUrlResponse>(`${environment.ApiBaseURL}${ApiEndPoints.saveURL}`
			, JSON.stringify(mantainUrlRequest));
	}

	courseURLs(mantainUrlRequest: MaintainUrlRequest) {
		return this.http.post<MaintainUrlResponse>(`${environment.ApiBaseURL}${ApiEndPoints.courseURLs}`
			, JSON.stringify(mantainUrlRequest));
	}

	deleteURL(mantainUrlRequest: MaintainUrlRequest) {
		return this.http.post<MaintainUrlResponse>(`${environment.ApiBaseURL}${ApiEndPoints.deleteURL}`
			, JSON.stringify(mantainUrlRequest));
	}

	urlDetails(mantainUrlRequest: MaintainUrlRequest) {
		return this.http.post<MaintainUrlResponse>(`${environment.ApiBaseURL}${ApiEndPoints.urlDetails}`
			, JSON.stringify(mantainUrlRequest));
	}

	updateURL(mantainUrlRequest: MaintainUrlRequest) {
		return this.http.post<MaintainUrlResponse>(`${environment.ApiBaseURL}${ApiEndPoints.updateURL}`
			, JSON.stringify(mantainUrlRequest));
	}

	updateCoursePageOne(createCourseRequest: CreateCourseRequest) {
		return this.http.post<CreateCourseResponse>(`${environment.ApiBaseURL}${ApiEndPoints.updateCourse}`
			, JSON.stringify(createCourseRequest));
	}

	getDepartmentRolesForCourse(courseRolesRequest: CourseRolesRequest) {
		return this.http.post<CourseRolesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getDepartmentRolesForCourse}`
			, JSON.stringify(courseRolesRequest));
	}

	getcourseToRoleData(courseRolesRequest: AssociatedWLRequest) {
		return this.http.post<CourseRolesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.courseToRoleData}`
			, JSON.stringify(courseRolesRequest));
	}

	addCourseRole(courseRolesRequest: CourseRolesRequest) {
		return this.http.post<CourseRolesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.addCourseRole}`
			, JSON.stringify(courseRolesRequest));
	}

	updateCourseRole(courseRolesRequest: CourseRolesRequest) {
		return this.http.post<CourseRolesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.updateCourseRole}`
			, JSON.stringify(courseRolesRequest));
	}

	deleteCourseRole(courseRolesRequest: CourseRolesRequest) {
		return this.http.post<CourseRolesResponse>(`${environment.ApiBaseURL}${ApiEndPoints.deleteCourseRole}`
			, JSON.stringify(courseRolesRequest));
	}
}
