import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SESSION_STORAGE_CONSTANTS } from 'Constants/StarsLearning.constants';
import { LoginResponse } from '../login/Models/LoginResponse.model';
import { CorporateInfoForAdmin } from '../corporate-admin/corporate-common-information/models/common-info.model';
import { PageHistoryService } from 'app/services/pageHistoryService/page-history.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CommonService {
	contextData: any;
	corporateInfoForAdmin: CorporateInfoForAdmin;
	backPageUrl: string;
	private cbuCodeData$: BehaviorSubject<any> = new BehaviorSubject([]);

	constructor(private sanitizer: DomSanitizer) { }

	corporateAdminSecurityProfiles: Array<string> = [
		'INTDLRSUPPORT',
		'INTINSTRUCTOR',
		'INTCURRCOORD',
		'INTCTRYADMIN',
		'INTBUSADMIN'
	];

	corporateAllSecurityProfiles: Array<string> = [
		'INTGENERAL',
		'INTDLRSUPPORT',
		'INTINSTRUCTOR',
		'INTCURRCOORD',
		'INTCTRYADMIN',
		'INTBUSADMIN'
	];

	trim(param: string) {
		return param ? param.trim() : param;
	}

	static trimNewLines(param: string) {
		return param ? param.replace(/\n|\r/g, "") : param;
	}

	getDuration(hours: string) {
		if (!hours) {
			return '';
		}
		const duration = hours.split(':');
		let durationToDisplay = '';
		durationToDisplay = Number(duration[0]) > 0 ? `${durationToDisplay}${Number(duration[0])}h` : durationToDisplay;
		durationToDisplay = Number(duration[1]) > 0 ? `${durationToDisplay} ${Number(duration[1])}m` : durationToDisplay;
		return durationToDisplay === '' ? '---' : durationToDisplay;
	}

	//for closing the modal in Classroom Schedule, handles duplicate courses and
	//works for both Desktop and Mobile modals
	closePopup(active, id, event) {
		if (active) {
			const element = document.getElementById(id);
			if (element && !element.contains(event.target)) {
				if (typeof active === 'string') {
					active = '';
				} else {
					active = false;
				}
			}
		}
		return active;
	}

	closePopupWithClassname(active, className, event) {
		if (active) {
			const element = document.getElementsByClassName(className)[0];
			if (element && !element.contains(event.target)) {
				if (typeof active === 'string') {
					active = '';
				} else {
					active = false;
				}
			}
		}
		return active;
	}

	// method used to get Session Data on passing the key and return contextData or null
	getSessionData(key: string) {
		if (sessionStorage.getItem(key)) {
			this.contextData = JSON.parse(sessionStorage.getItem(key));
			return this.contextData;
		} else {
			return null;
		}
	}

	// method used to save the contextData into key
	saveSessionContext(key: string, contextData: any) {
		if (contextData) {
			sessionStorage.setItem(key, JSON.stringify(contextData));
		} else {
			sessionStorage.setItem(key, contextData);
		}
	}

	SanitizeInputText(strValue: string): string {
		return this.sanitizer.sanitize(SecurityContext.HTML, strValue);
	}

	displayCorporateInfoForAdmin(loginResponse: LoginResponse) {
		this.corporateInfoForAdmin = new CorporateInfoForAdmin();
		this.corporateInfoForAdmin.dealerSearchResponse = this.getSessionData(SESSION_STORAGE_CONSTANTS.DEALER_SEARCH_RESPONSE);
		this.corporateInfoForAdmin.fleetSearchResponse = this.getSessionData(SESSION_STORAGE_CONSTANTS.FLEET_SEARCH_RESPONSE);
		this.corporateInfoForAdmin.corporateUser = this.corporateAdminSecurityProfiles.includes(loginResponse?.result?.userSessionInformation?.highestSecurityProfileCode);
		this.corporateInfoForAdmin.corporateAllUser = this.corporateAllSecurityProfiles.includes(loginResponse?.result?.userSessionInformation?.highestSecurityProfileCode);
		this.corporateInfoForAdmin.canUpdateDeferralDates = loginResponse?.result?.userSessionInformation?.securityProfile?.includes('SPEDITDEF');
		return this.corporateInfoForAdmin;
	}

	isSpJointVen(loginResponse: LoginResponse) {
		return loginResponse?.result?.userSessionInformation?.securityProfile?.includes('SPJOINTVEN');
	}

	navigateToChangePassword(pageHistoryService: PageHistoryService, commonService: CommonService, router: Router) {
		pageHistoryService.pushPageValueIntoPageHistory(router.url);
		if ((router.url.includes('corporateAdmin') || router.url.includes('corp-admin-emp-profile'))
			&& commonService.getSessionData('selectedCorpAdminView') !== 'to-do') {
			router.navigate(['/corp-admin-emp-profile/change-password-admin']);
		} else {
			router.navigate(['/viewuserprofile/change-password']);
		}
	}

	setCBUCode(userProfileResponse: any) {
		this.setCBUCodeData(userProfileResponse?.result?.cbuCode);
	}

	/** sets the cbuCodeData value
	 *
	 * @param cbuCodeData
	 */
	setCBUCodeData(cbuCodeData: any) {
		this.cbuCodeData$.next(cbuCodeData);
	}
	/**
	 *
	 * @returns the cbuCodeData as per user action
	 */
	getCBUCodeData(): Observable<any> {
		return this.cbuCodeData$.asObservable();
	}
}
