export class EmployeeInformationForAdmin {
	lastName: string;
	firstName: string;
	middleName: string;
	starsId: string;
	designation: string;
	fullName: string;
	isAdminPage: boolean;
	email: string;
	country: string;
	userDefaultLanguage: string;
	isSecDealership: boolean;
	empDealerCode: string;
	empDealerFleetIndicator: string;
	empJointVentureCode: string;
}
