import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiEndPoints } from '../../../Constants/apiendpoints.constants';
import {LoginService} from '../../services/login.service';
import {PageHistoryService} from '../../services/pageHistoryService/page-history.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
	ClassDetailsRequest,
	ClassDetailsResponse, EquivalencieRequest, EquivalencieResponse,
	TrainingLocationRequest,
	TrainingLocationResponse
} from '../models/class-details.model';

@Injectable()
export class ClassDetailsService {
	constructor(private http: HttpClient,
				public loginService: LoginService,
				public pageHistoryService: PageHistoryService,
				public router: Router,
				public route: ActivatedRoute) {}

	getClassDetails(classDetailsRequest: ClassDetailsRequest) {
		return this.http.post<ClassDetailsResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getClassDetails}`
			, JSON.stringify(classDetailsRequest));
	}

	getEquivalencies(equivalencieRequest: EquivalencieRequest) {
		return this.http.post<EquivalencieResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getEquivalenceDetails}`
			, JSON.stringify(equivalencieRequest));
	}

	getTrainingLocation(trainingLocationRequest: TrainingLocationRequest) {
		return this.http.post<TrainingLocationResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getTrainingLocationDetails}`
			, JSON.stringify(trainingLocationRequest));
	}

	loadClassDetails(course: any, route: any) {
		this.loginService.starLearning.get('classDetails').get('courseCode').patchValue(course.courseCode || course.taskCode);
		this.loginService.starLearning.get('classDetails').get('sessionNo').patchValue(course.sessionNo || course.sessionNum || course.sessionNumber);
		this.loginService.starLearning.get('classDetails').get('title').patchValue(course.courseTitle);
		this.loginService.starLearning.get('classDetails').get('deliveryMethod').patchValue(course.deliveryMethodCode);
		this.pageHistoryService.pushPageValueIntoPageHistory(this.router.url);
		this.router.navigate(['class-details'], {relativeTo: route});
	}

}
