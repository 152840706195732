export const ApiVersion = {
	currentVersion: 'api/v1/'
};
export const ApiEndPoints = {
	login: `${ApiVersion.currentVersion}login`,
	acceptDeclarationOfConsent: `${ApiVersion.currentVersion}login/acceptDeclaration`,
	getCertificates: `${ApiVersion.currentVersion}certificationSummary`,
	getCertificatePlanner: `${ApiVersion.currentVersion}certPlanner`,
	getLaunchCourse: `${ApiVersion.currentVersion}launchCourse`,
	getCertificateDescription: `${ApiVersion.currentVersion}certDescription`,
	getCertificateHistory: `${ApiVersion.currentVersion}certHistory`,
	getToDoList: `${ApiVersion.currentVersion}toDoList`,
	getCourseDetails: `${ApiVersion.currentVersion}courseDetails`,
	getEnrollmentActivity: `${ApiVersion.currentVersion}enrollment`,
	getLaunchWebinar: `${ApiVersion.currentVersion}launchWebinar`,
	getTrainingLocationDetails: `${ApiVersion.currentVersion}trainingLocationDetails`,
	getClassDetails: `${ApiVersion.currentVersion}classDetails`,
	getEquivalenceDetails: `${ApiVersion.currentVersion}equivalenciesInPreReq`,
	getMissingPrerequisites: `${ApiVersion.currentVersion}missingPrerequisite`,
	getLaunchWebinarCourses: `${ApiVersion.currentVersion}launchWebinarCourses`,
	getHotelList: `${ApiVersion.currentVersion}getHotelList?sort={sort}&direction={direction}`,
	getHotelDetails: `${ApiVersion.currentVersion}hotelDetails`,
	getHotelDetail: `${ApiVersion.currentVersion}hotelDetail`,
	createHotel: `${ApiVersion.currentVersion}createHotel`,
	updateHotel: `${ApiVersion.currentVersion}updateHotel`,
	deleteHotel: `${ApiVersion.currentVersion}deleteHotel`,
	getTransferTerminateDealerList: `${ApiVersion.currentVersion}transferOrTerminateList?sort={sort}&direction={direction}`,
	verifyTransferTerminateDealer: `${ApiVersion.currentVersion}verifyTransferOrTerminateDealerDetails`,
	addTransferTerminateDealer: `${ApiVersion.currentVersion}addTransferOrTerminateDealer`,
	cancelTransferTerminateDealer: `${ApiVersion.currentVersion}cancelTransferTerminateDetails`,
	getAddTransferDetails: `${ApiVersion.currentVersion}getAddTransferDetails`,
	getAddTerminateDetails: `${ApiVersion.currentVersion}getAddTerminateDetails`,
	getCourseAndTaskCatalogDetails: `${ApiVersion.currentVersion}courseAndTaskCatalog`,
	getHomePage: `${ApiVersion.currentVersion}dashboard`,
	getTrainingHistoryDetails: `${ApiVersion.currentVersion}trainingHistoryList?direction={direction}&sort={sort}`,
	getWebinarSchedule: `${ApiVersion.currentVersion}webinarSchedule?direction={direction}&sort={sort}`,
	getClassSchedule: `${ApiVersion.currentVersion}classSchedule?direction={direction}&sort={sort}`,
	usageReport: `${ApiVersion.currentVersion}usageReport`,
	announcementsAndUserSupportInformation: `${ApiVersion.currentVersion}announcementsAndUserSupportInformation`,
	getAssignedCertifications: `${ApiVersion.currentVersion}assignedCertifications?direction={direction}&sort={sort}`,
	removeCertifications: `${ApiVersion.currentVersion}removeCertifications`,
	addCertifications: `${ApiVersion.currentVersion}addCertifications`,
	getAvailableCertifications: `${ApiVersion.currentVersion}availableCertifications?direction={direction}&sort={sort}`,
	getUserProfileDetails: `${ApiVersion.currentVersion}getUserProfileDetails`,
	updateUserProfileDetails: `${ApiVersion.currentVersion}updateEmployeeDetails`,
	forgotPassword: `${ApiVersion.currentVersion}forgotPassword`,
	changePassword: `${ApiVersion.currentVersion}changePassword`,
	forgotID: `${ApiVersion.currentVersion}forgotStarsID`,
	jobHistory: `${ApiVersion.currentVersion}employmentJobHistoryList?direction={direction}&sort={sort}`,
	getEmployeeList: `${ApiVersion.currentVersion}employeeListAPI?direction={direction}&sort={sort}`,
	inactiveEmployee: `${ApiVersion.currentVersion}inActivateEmployee`,
	enrollmentActivityList: `${ApiVersion.currentVersion}enrollmentActivityList?direction={direction}&sort={sort}`,
	getCancelEnrollment: `${ApiVersion.currentVersion}cancelEnrollmentActivities`,
	getAutoLogon: `${ApiVersion.currentVersion}autoLogon`,
	getResetPassword: `${ApiVersion.currentVersion}resetPassword`,
	enrollInClass: `${ApiVersion.currentVersion}classEnroll`,
	confirmEnrollment: `${ApiVersion.currentVersion}confirmEnrollment`,
	confirmSubmitWaitList: `${ApiVersion.currentVersion}confirmSubmitWaitList`,
	getTrainingLocationsList: `${ApiVersion.currentVersion}trainingLocationList?direction={direction}&sort={sort}`,
	getHotelTrainingLocationsList: `${ApiVersion.currentVersion}common/locationsForCountry?countryCode={countryCode}&languageCode={languageCode}&jvCode={jvCode}&withAll={withAll}`,
	submitWaitlistRequest: `${ApiVersion.currentVersion}submitWaitList`,
	getShopCompetencyList: `${ApiVersion.currentVersion}shopCompetencyList?sort={sort}&direction={direction}`,
	getCertificationList: `${ApiVersion.currentVersion}getCertificationList`,
	getShopCompEmployeeDetails: `${ApiVersion.currentVersion}getShopCompEmployeeDetails?direction={direction}&sort={sort}`,
	updateEmployeeProfile: `${ApiVersion.currentVersion}admin/activateEmployeeStatus`,
	addSelectedRoles: `${ApiVersion.currentVersion}admin/addSelectedRoles`,
	deleteSelectedRoles: `${ApiVersion.currentVersion}admin/deleteSelectedRoles`,
	sortAssignRoles: `${ApiVersion.currentVersion}admin/sortAssignRoles?sort={sort}&direction={direction}`,
	sortAvailableRoles: `${ApiVersion.currentVersion}admin/sortAvailableRoles?sort={sort}&direction={direction}`,
	getAssignedEmployees: `${ApiVersion.currentVersion}admin/getAssignedEmployees?direction={direction}&sort={sort}`,
	removeExistingEmployees: `${ApiVersion.currentVersion}admin/removeExistingEmployees`,
	getAvailableEmployees: `${ApiVersion.currentVersion}admin/getAvailableEmployees?direction={direction}&sort={sort}`,
	addAvailableEmployees: `${ApiVersion.currentVersion}admin/addAvailableEmployees`,
	existingEmployeeActivationDetails: `${ApiVersion.currentVersion}admin/existingEmployeeActivation`,
	searchExistingEmployeeDetails: `${ApiVersion.currentVersion}admin/searchExistingEmployeeDetails`,
	getActivationDealerDetails: `${ApiVersion.currentVersion}admin/getActivationDealerDetails`,
	addNewEmployee: `${ApiVersion.currentVersion}admin/createNewEmployee`,
	addNewEmployeeCorporate: `${ApiVersion.currentVersion}admin/createNewEmployeeByCorporate`,
	updateQuarterlyEmail: `${ApiVersion.currentVersion}login/quarterlyEmailVerification`,
	getEmailFrequency: `${ApiVersion.currentVersion}getEmailFrequency`,
	getAvailableDepartments: `${ApiVersion.currentVersion}getAvailableDepartments`,
	getDepartmentSubscriptionDetails: `${ApiVersion.currentVersion}getDepartmentSubscriptionDetails`,
	unsubscribeDepartmentSubscription: `${ApiVersion.currentVersion}unsubscribeDepartmentSubscription`,
	getCertAlertAvailableCertifications: `${ApiVersion.currentVersion}getAvailableCertifications?direction={direction}&sort={sort}`,
	addCertSubscription: `${ApiVersion.currentVersion}addCertSubscription`,
	removeCertSubscription: `${ApiVersion.currentVersion}removeCertSubscription`,
	getDirrpAPIResponse: `${ApiVersion.currentVersion}admin/getDirrpAPIResponse`,
	languages: `${ApiVersion.currentVersion}common/languages?languageCode={languageCode}&withAll={withAll}`,
	updateCorporateEmployeeDetails: `${ApiVersion.currentVersion}updateCorporateEmployeeDetails`,
	countries: `${ApiVersion.currentVersion}common/countries?languageCode={languageCode}&withAll=false`,
	regionsForCountry: `${ApiVersion.currentVersion}common/regionsForCountry?countryCode={countryCode}&languageCode={languageCode}&withAll={withAll}`,
	specialDivisions: `${ApiVersion.currentVersion}common/specialDivisions?hasSPJOINTVEN=true&languageCode={languageCode}&withAll={withAll}`,
	sponsoringActivities: `${ApiVersion.currentVersion}common/sponsoringActivities?jvCode={jvCode}&languageCode={languageCode}&withAll={withAll}`,
	statesForCountry: `${ApiVersion.currentVersion}common/statesForCountry?countryCode={countryCode}&languageCode={languageCode}`,
	locationTypes: `${ApiVersion.currentVersion}common/locationTypes?languageCode={languageCode}`,
	dealerSelections: `${ApiVersion.currentVersion}common/dealerSelections?languageCode={languageCode}&withAll={withAll}`,
	createLocation: `${ApiVersion.currentVersion}createLocation`,
	changeDealerShip: `${ApiVersion.currentVersion}admin/changeDealership?sort={sort}&direction={direction}`,
	dealerList: `${ApiVersion.currentVersion}dealerList?sort={sort}&direction={direction}`,
	authorizedCountries: `${ApiVersion.currentVersion}common/authorizedCountries?languageCode={languageCode}&starsID={starsID}&withAll={withAll}`,
	supplierList: `${ApiVersion.currentVersion}getSupplierList?direction={direction}&sort={sort}`,
	locationDetails: `${ApiVersion.currentVersion}trainingLocationDetails`,
	locationRooms: `${ApiVersion.currentVersion}getLocationRooms`,
	deleteLocationRooms: `${ApiVersion.currentVersion}deleteLocationRoom`,
	addLocationRoom: `${ApiVersion.currentVersion}addLocationRoom`,
	getExportToExcel: `${ApiVersion.currentVersion}employees/download?direction={direction}&sort={sort}`,
	//createSupplier: `${ApiVersion.currentVersion}createSupplier`,
	jobRoleCountryList: `${ApiVersion.currentVersion}jobRoleCountryList?direction={direction}&sort={sort}`,
	jobRoleMasterList: `${ApiVersion.currentVersion}jobRoleMasterList?direction={direction}&sort={sort}`,
	getTitles: `${ApiVersion.currentVersion}common/getTitles`,
	updateLocation: `${ApiVersion.currentVersion}updateLocation`,
	addTitle: `${ApiVersion.currentVersion}common/addTitle`,
	updateTitle: `${ApiVersion.currentVersion}common/updateTitle`,
	deleteTitle: `${ApiVersion.currentVersion}common/deleteTitle`,
	deleteLocation: `${ApiVersion.currentVersion}deleteLocation`,
	updateLocationRoom: `${ApiVersion.currentVersion}updateLocationRoom`,
	jobRoleDetails: `${ApiVersion.currentVersion}jobRoleDetails`,
	certPlannerDownload: `${ApiVersion.currentVersion}certPlanner/download`,
	getExportTrainingHistoryToExcel: `${ApiVersion.currentVersion}trainingHistory/download?direction={direction}&sort={sort}`,
	updateJobRoleDetails: `${ApiVersion.currentVersion}updateJobRole`,
	createJobRole: `${ApiVersion.currentVersion}createJobRole`,
	jobRoleCountryAssignment: `${ApiVersion.currentVersion}jobRoleCountryAssignment`,
	getELearningActivity: `${ApiVersion.currentVersion}elearningActivity?direction={direction}&sort={sort}`,
	addELearningActivity: `${ApiVersion.currentVersion}addElearningAssignment`,
	getFleetDetails: `${ApiVersion.currentVersion}fleetDetails`,
	getUpdateCertificationStatusList: `${ApiVersion.currentVersion}getUpdateCertificationStatusList`,
	updateCertificationStatus: `${ApiVersion.currentVersion}udateCertificationStatus`,
	fleetList: `${ApiVersion.currentVersion}fleetList?sort={sort}&direction={direction}`,
	createOrUpdateEmployeeTrainingHistory: `${ApiVersion.currentVersion}createOrUpdateEmployeeTrainingHistory`,
	getShopCompetencyHistory: `${ApiVersion.currentVersion}getShopCompetencyHistory?direction={direction}&sort={sort}`,
	updateFleetDetails: `${ApiVersion.currentVersion}updateFleet`,
	employeeBrandHistory: `${ApiVersion.currentVersion}employeeBrandHistory?direction={direction}&sort={sort}`,
	instructorList: `${ApiVersion.currentVersion}instructorList?direction={direction}&sort={sort}`,
	getCbuList: `${ApiVersion.currentVersion}corporateBusinessUnitList?direction={direction}&sort={sort}`,
	getCbuDetails: `${ApiVersion.currentVersion}corporateBusinessUnitDetails`,
	createCbu: `${ApiVersion.currentVersion}createCorporateBusinessUnit`,
	updateCbu: `${ApiVersion.currentVersion}updateCorporateBusinessUnit`,
	deleteCbu: `${ApiVersion.currentVersion}deleteCorporateBusinessUnit`,
	getCbuEmployeeList: `${ApiVersion.currentVersion}corporateBusinessUnitEmployeeList?direction={direction}&sort={sort}`,
	getCbuJobList: `${ApiVersion.currentVersion}corporateBusinessUnitJobList`,
	getCorporateUserProfileDetails: `${ApiVersion.currentVersion}getCorporateUserProfileDetails`,
	validateStarsIDs: `${ApiVersion.currentVersion}validateStarsID`,
	saveRecords: `${ApiVersion.currentVersion}saveNonScheduleTrainingRecords`,
	getNonScheduledTrainingRecords: `${ApiVersion.currentVersion}getNonScheduleTrainingRecords?direction={direction}&sort={sort}`,
	deleteNonScheduledTrainingRecord: `${ApiVersion.currentVersion}deleteEmployeeTrainingHistory`,
	updateNonScheduledTrainingRecord: `${ApiVersion.currentVersion}createOrUpdateEmployeeTrainingHistory`,
	// for Update Class Enrollment
	getUpdateClassEnrollmentData: `${ApiVersion.currentVersion}getUpdateClassEnrollmentData`,
	updateClassEnrollment: `${ApiVersion.currentVersion}updateClassEnrollment`,
	getEmployeeLookup: `${ApiVersion.currentVersion}employeeLookUp?direction={direction}&sort={sort}`,
	createFleet: `${ApiVersion.currentVersion}createFleet`,
	deleteFleet: `${ApiVersion.currentVersion}deleteFleet`,
	certificationList: `${ApiVersion.currentVersion}certificationList?direction={direction}&sort={sort}`,
	emsIsoGetCountriesByRegion: `${ApiVersion.currentVersion}emsiso/countriesByRegion?sort={sort}&direction={direction}`,
	emsIsoGetEmsRegions: `${ApiVersion.currentVersion}emsiso/emsRegions`,
	emsIsoAvailableCountries: `${ApiVersion.currentVersion}emsiso/availableCountries`,
	emsIsoAssignCountries: `${ApiVersion.currentVersion}emsiso/assignCountries`,
	emsIsoRemoveCountry: `${ApiVersion.currentVersion}emsiso/removeCountry`,
	verifyDealerAdministrationList: `${ApiVersion.currentVersion}admin/verifyDealerAdministrationList`,
	updateDealerAdministrationList: `${ApiVersion.currentVersion}admin/updateDealerAdministrationList`,
	// for update Deferral Dates
	getDeferralDates: `${ApiVersion.currentVersion}getDeferralDates`,
	updateDeferralDates: `${ApiVersion.currentVersion}updateDeferralDates`,
	dealerSubscriptionList: `${ApiVersion.currentVersion}getDealerSubscriptionList?sort={sort}&direction={direction}`,
	removeDealerSubscription: `${ApiVersion.currentVersion}removeDealerSubscription`,
	availableDealersSubscriptionList: `${ApiVersion.currentVersion}getAvailableDealerSubscriptionList?sort={sort}&direction={direction}`,
	addDealerSubscription: `${ApiVersion.currentVersion}addDealerSubscription`,
	deleteJobRole: `${ApiVersion.currentVersion}deleteJobRole`,

	getProgramList: `${ApiVersion.currentVersion}programList?sort={sort}&direction={direction}`,
	getProgramDetails: `${ApiVersion.currentVersion}getProgramDetails`,
	deleteProgram: `${ApiVersion.currentVersion}deleteProgramDetails`,
	createUpdateProgramDetails: `${ApiVersion.currentVersion}createUpdateProgram`,
	getClassRoster: `${ApiVersion.currentVersion}classRoster?direction={direction}&sort={sort}`,
	sponsorList: `${ApiVersion.currentVersion}sponsorList?direction={direction}&sort={sort}`,
	createSponsor: `${ApiVersion.currentVersion}createUpdateSponsor`,
	viewSponsor: `${ApiVersion.currentVersion}sponsorDetails`,
	deleteSponsor: `${ApiVersion.currentVersion}deleteSponsor`,
	departments: `${ApiVersion.currentVersion}common/departments?languageCode={languageCode}&securityRole={securityRole}&withAll={withAll}`,
	getRightToBeForgottenList: `${ApiVersion.currentVersion}rightToBeForgottenList?direction={direction}&sort={sort}`,
	getRightToBeForgottenDetails: `${ApiVersion.currentVersion}rightToBeForgottenDetails`,
	addRightToBeForgottenDetails: `${ApiVersion.currentVersion}addEmployeeToRightToBeForgottenList`,
	rightToBeForgottenDetails: `${ApiVersion.currentVersion}rightToBeForgottenDetails`,
	cancelRightToBeForgottenDetails: `${ApiVersion.currentVersion}cancelEmployeeToRightToBeForgottenList`,
	validateCourseCode: `${ApiVersion.currentVersion}validateCourseCode`,
	deliveryMethods: `${ApiVersion.currentVersion}common/deliveryMethods?languageCode={languageCode}&withAll={withAll}`,
	activeDeliveryMethods: `${ApiVersion.currentVersion}common/activeDeliveryMethods?languageCode={languageCode}&withAll={withAll}`,
	getAvailableLangsForCountries: `${ApiVersion.currentVersion}getAvailableLangsForCountries`,
	employeeTargets: `${ApiVersion.currentVersion}common/employeeTargets?languageCode={languageCode}&withAll={withAll}`,
	passCriteria: `${ApiVersion.currentVersion}common/passCriteria?languageCode={languageCode}`,
	createCourse: `${ApiVersion.currentVersion}createCourse`,
	courseOptimizedFor: `${ApiVersion.currentVersion}common/courseOptimizedFor?languageCode={languageCode}&withAll={withAll}`,
	getExistingEquivCourses: `${ApiVersion.currentVersion}getExistingEquivCourses`,
	getAvailableEquivCourses: `${ApiVersion.currentVersion}getAvailableEquivCourses`,
	addEquivalencyCourses: `${ApiVersion.currentVersion}addEquivalencyCourses`,
	removeEquivCourses: `${ApiVersion.currentVersion}removeEquivCourses`,
	getExistingPrerequisites: `${ApiVersion.currentVersion}getExistingPrerequisites`,
	addPrerequisites: `${ApiVersion.currentVersion}addPrerequisites`,
	getAvailablePrerequisites: `${ApiVersion.currentVersion}getAvailablePrerequisites`,
	getDepartmentRolesForCourse: `${ApiVersion.currentVersion}getDepartmentRolesForCourse`,
	courseToRoleData: `${ApiVersion.currentVersion}courseToRoleData`,
	addCourseRole: `${ApiVersion.currentVersion}addCourseRole`,
	updateCourseRole: `${ApiVersion.currentVersion}updateCourseRole`,
	deleteCourseRole: `${ApiVersion.currentVersion}deleteCourseRole`,
	removePrerequisites: `${ApiVersion.currentVersion}removePrerequisites`,
	getCancellationReason: `${ApiVersion.currentVersion}common/cancellationReasons?languageCode={languageCode}`,
	associateWLCourse: `${ApiVersion.currentVersion}associateWLCourse`,
	urlCountries: `${ApiVersion.currentVersion}urlCountries`,
	urlTypes: `${ApiVersion.currentVersion}common/urlTypes?languageCode={languageCode}&deliveryMethodCode={deliveryMethodCode}`,
	validateURLType: `${ApiVersion.currentVersion}validateURLType`,
	saveURL: `${ApiVersion.currentVersion}saveURL`,
	courseURLs: `${ApiVersion.currentVersion}courseURLs`,
	deleteURL: `${ApiVersion.currentVersion}deleteURL`,
	urlDetails: `${ApiVersion.currentVersion}urlDetails`,
	updateURL: `${ApiVersion.currentVersion}updateURL`,
	deleteCourse: `${ApiVersion.currentVersion}deleteCourse`,
	updateCourse: `${ApiVersion.currentVersion}updateCourse`,
	getWaitlistRequests: `${ApiVersion.currentVersion}viewWaitlistRequests?sort={sort}&direction={direction}`,
	cancelWaitlistRequest: `${ApiVersion.currentVersion}cancelWaitlistRequest`
};
