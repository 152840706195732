import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WebinarScheduleRequest, WebinarScheduleResponse} from '../models/webinar-schedule.model';
import {environment} from '../../../environments/environment';
import {ApiEndPoints} from '../../../Constants/apiendpoints.constants';

@Injectable()
export class WebinarScheduleService {
	constructor(private http: HttpClient) {
	}

	getWebinarSchedule(webinarScheduleRequest: WebinarScheduleRequest,direction: string, sort: string) {
		let webinarScheduleURL = `${environment.ApiBaseURL}${ApiEndPoints.getWebinarSchedule}`;
		webinarScheduleURL = webinarScheduleURL.replace('{direction}', direction.toString());
		webinarScheduleURL = webinarScheduleURL.replace('{sort}', sort.toString());
		return this.http.post<WebinarScheduleResponse>(webinarScheduleURL
			, JSON.stringify(webinarScheduleRequest));
	}
}
