import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiEndPoints } from 'Constants/apiendpoints.constants';
import { CorpCourseDetailsRequest, CorpCourseDetailsResponse } from '../models/corp-course-details.model';

@Injectable()
export class CorpCourseDetailsService {
	constructor(
		private http: HttpClient,
	) { }

	getCourseDetails(courseDetailsRequest: CorpCourseDetailsRequest) {
		return this.http.post<CorpCourseDetailsResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getCourseDetails}`
			, JSON.stringify(courseDetailsRequest));
	}

	deleteCourseDetails(courseDetailsRequest: CorpCourseDetailsRequest) {
		return this.http.post<CorpCourseDetailsResponse>(`${environment.ApiBaseURL}${ApiEndPoints.deleteCourse}`
			, JSON.stringify(courseDetailsRequest));
	}

}
