import { Component, Input, OnInit } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-course-language-options',
	templateUrl: './course-language-options.component.html',
	styleUrls: ['./course-language-options.component.scss']
})
export class CourseLanguageOptionsComponent implements OnInit {
	@Input()
	languageOptions: any;

	@Input()
	launchCourseParameters: any;

	@Input()
	displayField = 'courseLangName';

	@Input()
	valueField = 'courseLang';

	selectedLanguage: any;

	constructor(private readonly _bsModalRef: BsModalRef) {
	}

	ngOnInit() {
		this.selectedLanguage = this.languageOptions[0];
	}

	closeModal() {
		this._bsModalRef.content.emitData.next(true);
	}

	continueWithOptionalLanguage() {
		this.launchCourseParameters.optionalLanguage = this.selectedLanguage[this.valueField];
		this._bsModalRef.content.emitCourseData.next(this.launchCourseParameters);
	}
}
