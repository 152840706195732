// Launch Course Request and Response
export class LaunchCourseRequest {
	courseCode: string;
	countryCode: string;
	urlType: string;
	starsID: string;
	userDefaultLanguage: string;
	deliveryMethod: string;
}

class CourseUrl {
	courseLang: string;
	courseCode: string;
	courseCountryCode: string;
	hrefURL: string;
	urlType: string;
};

export class LaunchCourseResponse {
	message: string;
	errorMsgDesc: string;
	result: {
		courseURLs: CourseUrl[];
		fatalErrorMessage: string;
		errorMessageID: string;
		errorFlag: string;
	};
}

// LaunchWebinar Request and Response
export class LaunchLiveWebinarCourseRequest {
	starsID: string;
	country: string;
	adobeServerCode: string;
	launchWebinar: boolean;
	courseCode: string;
	lang: string;
}

export class LaunchLiveWebinarCourseResponse {
	message: string;
	errorMsgDesc: string;
	result: LaunchLiveWebinarCourseResponseResult;
}

export class LaunchLiveWebinarCourseResponseResult {
	errorFlag: string;
	errorMessageID: string;
	fatalErrorMessage: string;
	adbo: LaunchLiveWebinarCourseAdobeResponseResult;
	adobeServerURL: string;
	adobeSession: string;
	courseURL: string;
}

export class LaunchLiveWebinarCourseAdobeResponseResult {
	adobePassword: string;
	starsID: string;
	scoID: null;
	groupID: null;
	groupPrincipalID: null;
	userPrincipalID: string;
	datafound: boolean;
}

// LaunchWebinarCourses Request and Response
export class LaunchWebinarCoursesRequest {
	adobeServerCode: string;
	countryCode: string;
	courseCode: string;
	groupType: string;
	lang: string;
	urlType: string;
	userCDSID: string;
	userStarsID: string;
}
export class LaunchWebinarCoursesResponse {
	message: string;
	errorMsgDesc: string;
	result: LaunchWebinarCoursesResponseResult;
}

export class LaunchWebinarCoursesResponseResult {
	errorFlag: string;
	errorMessageID: string;
	fatalErrorMessage: string;
	adobeSessionNumber: string;
	userPrincipalID: string;
	starsID: string;
	userPassword: string;
	studentCountry: string;
	classSession: string;
	courseURL: string;
	courseCode: string;
	courseCountry: string;
	courseLang: string;
	adobeUtilsDetails: AdobeUtilDetailsResponse;
	courseGroupPrincipalID: null;
	courseGroupName: null;
	urlAvailableLanguages: [
		{
			targetLanguage: string;
			targetLanguageName: string;
		}
	];
}

export class AdobeUtilDetailsResponse {
	adobeServerUrl: string;
	sessionNO: string;
	userID: string;
	strPassword: string;
}

