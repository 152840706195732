import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ApiEndPoints} from '../../../../Constants/apiendpoints.constants';
import { CreateLocationResponse, LocationDetailBO, LocationDetailsRequest, Room, TrainingLocationDetailsResponse } from '../models/corp-training-location.model';

@Injectable()
export class TrainingLocationService {
	constructor(
		private http: HttpClient) {
	}

	createTrainingLocation(trainingLocationRequest: LocationDetailBO) {
		return this.http.post<CreateLocationResponse>(`${environment.ApiBaseURL}${ApiEndPoints.createLocation}`
			, JSON.stringify(trainingLocationRequest));
	}

	getTrainingLocationDetails(trainingLocationDetailsRequest: LocationDetailsRequest) {
		return this.http.post<TrainingLocationDetailsResponse>(`${environment.ApiBaseURL}${ApiEndPoints.locationDetails}`
			, JSON.stringify(trainingLocationDetailsRequest));
	}

	getLocationRooms(trainingLocationDetailsRequest: LocationDetailsRequest) {
		return this.http.post<TrainingLocationDetailsResponse>(`${environment.ApiBaseURL}${ApiEndPoints.locationRooms}`
			, JSON.stringify(trainingLocationDetailsRequest));
	}

	deleteLocationRooms(roomRequest: Room) {
		return this.http.post<CreateLocationResponse>(`${environment.ApiBaseURL}${ApiEndPoints.deleteLocationRooms}`
			, JSON.stringify(roomRequest));
	}

	addLocationRoom(roomRequest: Room) {
		return this.http.post<CreateLocationResponse>(`${environment.ApiBaseURL}${ApiEndPoints.addLocationRoom}`
			, JSON.stringify(roomRequest));
	}

	updateTrainingLocation(trainingLocationRequest: LocationDetailBO) {
		return this.http.post<CreateLocationResponse>(`${environment.ApiBaseURL}${ApiEndPoints.updateLocation}`
			, JSON.stringify(trainingLocationRequest));
	}

	deleteTrainingLocation(trainingLocationRequest: LocationDetailBO) {
		return this.http.post<CreateLocationResponse>(`${environment.ApiBaseURL}${ApiEndPoints.deleteLocation}`
			, JSON.stringify(trainingLocationRequest));
	}

	updateLocationRoom(roomRequest: Room) {
		return this.http.post<CreateLocationResponse>(`${environment.ApiBaseURL}${ApiEndPoints.updateLocationRoom}`
			, JSON.stringify(roomRequest));
	}
}
