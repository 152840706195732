<div class="light_blue_bg">
	<div id="mySidenav" class="sidenav" [ngClass]="{'open' : mySideNavOpen}">
		<!--Left Panel-->
		<div class="leftPanel">
			<a href="javascript:void(0)" class="closebtn" (click)="closeSideNav()">
				<img src="../../../assets/images/icon_close.jpg" alt="X">
			</a>

			<!--Welcome with User Name-->
			<div class="welcomettext">{{'NavBar.Welcome_To_Stars' | translate }}
				<br>
				<b>{{userName}}</b>
				<div *ngIf="loginService.isAdmin && this.loginService.IsAdminView" class="change-dealership">
					{{this.loginResponse?.result.userSessionInformation.dealerName}}
					{{this.loginResponse?.result.userSessionInformation.dealerCode}}
					{{this.loginResponse?.result.userSessionInformation.dealerSubCode ? ' - ' +
					this.loginResponse?.result.userSessionInformation.dealerSubCode : ''}}
					<!--					<a href="javascript:void(0)">{{'NavBar.change_dealership' | translate}}</a>-->
				</div>
				<div
					*ngIf="loginService && loginService.isChangeDealerShip && loginService.isAdmin && this.isAdminView">
					<a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#ChangeDealerShip"
						style="color: white;font-size: medium;text-decoration: underline;">
						{{'NavBar.change_dealership' | translate }}
					</a>
				</div>
			</div>

			<!--STARS ID Info-->
			<div class="accountInfo" id="stars-id-dashboard" [hidden]="true">
				<span id="stars-id-lr-cta">{{'NavBar.Stars_ID' | translate }}
					{{this.loginResponse?.result.userSessionInformation.starsID}}</span>
				<!--<br><span id="stars-email-lr-cta">{{'NavBar.e_Mail' | translate }} {{this.loginResponse?.result.userSessionInformation.email}}</span>-->
			</div>

			<!--Admin Dropdown Menu-->
			<div class="dropdownMenu" *ngIf="this.loginService.isAdmin || this.loginService.isCorporateAdmin">
				<div id="accordion">
					<div class="card" id="viewCard">
						<div class="card-header" [ngClass]="{'disabled' : !IsPrimaryDealerShip}" id="headingOne">
							<button class="btn btn-link" [ngClass]="{'collapsed' : isCollapsed}" #adminDropDown
								(click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
								aria-controls="collapseDropdown">
								<ng-container *ngIf="this.loginService.isAdmin">
									<ng-container *ngIf="!this.isAdminView">
										<img src="../../../assets/images/admin-task-user.png" alt="">
										{{'NavBar.my_training' | translate}}
									</ng-container>
									<ng-container *ngIf="this.isAdminView">
										<img src="../../../assets/images/admin-task-icon.png" alt="">
										{{'NavBar.admin_tasks' | translate}}
									</ng-container>
								</ng-container>
								<ng-container *ngIf="this.loginService.isCorporateAdmin">
									<ng-container *ngIf="this.isCorporateAdminView">
										<img src="../../../assets/images/admin-task-icon.png" alt="">
										{{'NavBar.administrator' | translate}}
									</ng-container>
									<ng-container *ngIf="!this.isCorporateAdminView">
										<img src="../../../assets/images/admin-task-user.png" alt="">
										{{'NavBar.my_training' | translate}}
									</ng-container>
								</ng-container>
							</button>
						</div>
						<div id="collapseDropdown" [collapse]="isCollapsed" [isAnimated]="true">
							<div class="card-body">
								<div class="accordion-submenu" *ngIf="this.loginService.isAdmin">
									<a *ngIf="!this.isAdminView" (click)="adminDropdownChange('admin')">
										<img src="../../../assets/images/admin-task-icon.png" alt="">
										{{'NavBar.admin_tasks' | translate}}
									</a>
									<a *ngIf="this.isAdminView" (click)="adminDropdownChange('dashboard')">
										<img src="../../../assets/images/admin-task-user.png" alt="">
										{{'NavBar.my_training' | translate}}
									</a>
								</div>

								<div class="accordion-submenu" *ngIf="this.loginService.isCorporateAdmin">
									<a *ngIf="!this.isCorporateAdminView"
										(click)="corporateAdminDropdownChange('corporateAdmin')">
										<img src="../../../assets/images/admin-task-icon.png" alt="">
										{{'NavBar.administrator' | translate}}
									</a>
									<a *ngIf="this.isCorporateAdminView"
										(click)="corporateAdminDropdownChange('to-do')">
										<img src="../../../assets/images/admin-task-user.png" alt="">
										{{'NavBar.my_training' | translate}}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="spacer"></div>

			<!--Left Menu-->
			<div class="leftMenu">
				<ul>
					<!--Learner Left Menu-->
					<ng-container
						*ngIf="(!this.loginService.isAdmin || !this.isAdminView) && !this.loginService.isCorporateAdmin">
						<li [routerLink]="['/dashboard']" [routerLinkActive]="['active']"
							(click)="toggleMenu(leftMenus.Dashboard)">
							<a href="javascript:void(0);">
								<img src="../../../assets/images/icn_home.png" alt="">{{'NavBar.Dashboard' | translate}}
							</a>
						</li>
						<li *ngIf="!constants.europeGroup4.includes(this.userSessionInformation.countryCode)"
							[routerLink]="['/to-do']" [routerLinkActive]="['active']"
							(click)="toggleMenu(leftMenus.ToDo)">
							<a href="javascript:void(0);">{{'NavBar.To_Do_List' | translate}}</a>
						</li>
						<li *ngIf="!constants.europeGroup4.includes(this.userSessionInformation.countryCode)"
							[routerLink]="['/certifications']" [routerLinkActive]="['active']"
							(click)="toggleMenu(leftMenus.CertSummary)">
							<a href="javascript:void(0);">{{'NavBar.Certification_Summary' | translate}}</a>
						</li>
						<li [routerLinkActive]="['active']" *ngIf="((this.userSessionInformation.highestSecurityProfileCode === 'INTGENERAL'
						&& loginResponse.result.employeeType === '4' && this.userSessionInformation.dealerCode !== '')
						&& (this.corporateCBUCode !== ''))" (click)="gotoCBUDetails()">
							<a href="javascript:void(0)">{{'NavBar.CorporateBusinessUnitDetails' | translate}}</a>
						</li>
						<li [routerLink]="['/enrollment-activity']" [routerLinkActive]="['active']"
							(click)="toggleMenu(leftMenus.EnrollmentActivity)">
							<a href="javascript:void(0);">{{'NavBar.Enrollment_Activity' | translate}}</a>
						</li>
						<li [routerLink]="['/training-history']" [routerLinkActive]="['active']"
							(click)="toggleMenu(leftMenus.TrainingHistory)">
							<a href="javascript:void(0);">{{'NavBar.Training_History' | translate}}</a>
						</li>
						<li [routerLink]="['/course-catalog']" [routerLinkActive]="['active']"
							(click)="toggleMenu(leftMenus.CourseAndTaskCatalog)">
							<a href="javascript:void(0)">{{'NavBar.Course_And_Task_Catalog' | translate}}</a>
						</li>
						<li [routerLink]="['/class-schedule']" [routerLinkActive]="['active']"
							*ngIf="!constants.europeGroup4.includes(this.userSessionInformation.countryCode) || (isDealerAdmin || isFleetAdmin)"
							(click)="toggleMenu(leftMenus.ClassroomSchedule)">
							<a href="javascript:void(0);">{{'NavBar.Classroom_Schedule' | translate}}</a>
						</li>
						<li [routerLink]="['/eLearning-activity']" [routerLinkActive]="['active']"
							*ngIf="constants.allEuropeanCountries.includes(this.userSessionInformation.countryCode)"
							(click)="toggleMenu(leftMenus.eLearning)">
							<a href="javascript:void(0)">{{'NavBar.eLearning_activity' | translate}}</a>
						</li>
					</ng-container>

					<!--Corporate Learner Left Menu-->
					<ng-container *ngIf="this.loginService.isCorporateAdmin && !this.isCorporateAdminView">
						<li [routerLink]="['/to-do']" [routerLinkActive]="['active']"
							(click)="toggleMenu(leftMenus.ToDo)">
							<a href="javascript:void(0);">{{'NavBar.To_Do_List' | translate}}</a>
						</li>
						<li [routerLink]="['/certifications']" [routerLinkActive]="['active']"
							(click)="toggleMenu(leftMenus.CertSummary)">
							<a href="javascript:void(0);">{{'NavBar.Certification_Summary' | translate}}</a>
						</li>
						<li [routerLinkActive]="['active']" *ngIf="((loginResponse.result.employeeType === '4'
						&& this.userSessionInformation.dealerCode !== '') && (this.corporateCBUCode !== ''))" (click)="gotoCBUDetails()">
							<a href="javascript:void(0)">{{'NavBar.CorporateBusinessUnitDetails' | translate}}</a>
						</li>
						<li [routerLink]="['/enrollment-activity']" [routerLinkActive]="['active']"
							(click)="toggleMenu(leftMenus.EnrollmentActivity)">
							<a href="javascript:void(0);">{{'NavBar.Enrollment_Activity' | translate}}</a>
						</li>
						<li [routerLink]="['/training-history']" [routerLinkActive]="['active']"
							(click)="toggleMenu(leftMenus.TrainingHistory)">
							<a href="javascript:void(0);">{{'NavBar.Training_History' | translate}}</a>
						</li>
						<li [routerLink]="['/course-catalog']" [routerLinkActive]="['active']"
							(click)="toggleMenu(leftMenus.CourseAndTaskCatalog)">
							<a href="javascript:void(0)">{{'NavBar.Course_And_Task_Catalog' | translate}}</a>
						</li>
						<li [routerLink]="['/class-schedule']" [routerLinkActive]="['active']"
							(click)="toggleMenu(leftMenus.ClassroomSchedule)">
							<a href="javascript:void(0);">{{'NavBar.Classroom_Schedule' | translate}}</a>
						</li>
					</ng-container>

					<!--Admin Left Menu-->
					<ng-container *ngIf="this.loginService.isAdmin && this.isAdminView">
						<li
							[ngClass]="{'active' : this.router.url.includes('/admin/employee-list') && !this.router.url.includes('/admin/employee-list/emp-profile')}">
							<a href="javascript:void(0);" (click)="onClickSideNavItem($event, '/admin/employee-list')">
								{{'NavBar.employee_list' | translate}}
							</a>
						</li>
						<li [ngClass]="{'active' : this.router.url.includes('/admin/cert-alert-subscriptions')}"
							*ngIf="this.isDealerAdmin && constants.displayCertAlerts.includes(this.userSessionInformation.countryCode)">
							<a href="javascript:void(0);"
								(click)="onClickSideNavItem($event, '/admin/cert-alert-subscriptions')">
								{{'Administration.CertAlertSubscriptions' | translate}}
							</a>
						</li>
						<li [ngClass]="{'active' : this.router.url.includes('/admin/shop-tech-competency')}"
							*ngIf="this.isDealerAdmin  && constants.displayShopTechMenu.includes(this.userSessionInformation.countryCode)">
							<a href="javascript:void(0);"
								(click)="onClickSideNavItem($event, '/admin/shop-tech-competency')">
								{{'NavBar.shop_and_tech_competency' | translate}}
							</a>
						</li>
						<li [ngClass]="{'active' : this.router.url.includes('/admin/enrollment-activity')}">
							<a href="javascript:void(0);"
								(click)="onClickSideNavItem($event, '/admin/enrollment-activity')">
								{{'NavBar.Enrollment_Activity' | translate}}
							</a>
						</li>
						<li [ngClass]="{'active' : this.router.url.includes('/admin/course-catalog')}">
							<a href="javascript:void(0);" (click)="onClickSideNavItem($event, '/admin/course-catalog')">
								{{'NavBar.Course_And_Task_Catalog' | translate}}
							</a>
						</li>
						<li [ngClass]="{'active' : this.router.url.includes('/admin/class-schedule')}">
							<a href="javascript:void(0);" (click)="onClickSideNavItem($event, '/admin/class-schedule')">
								{{'NavBar.Classroom_Schedule' | translate}}
							</a>
						</li>
						<li [ngClass]="{'active' : this.router.url.includes('/admin/eLearning-activity')}"
							*ngIf="constants.allEuropeanCountries.includes(this.userSessionInformation.countryCode)">
							<a href="javascript:void(0);"
								(click)="onClickSideNavItem($event, '/admin/eLearning-activity')">
								{{'NavBar.eLearning_activity' | translate}}
							</a>
						</li>
					</ng-container>

					<!--Corporate Admin Left Menu-->
					<ng-container *ngIf="this.loginService.isCorporateAdmin && this.isCorporateAdminView">
						<li (click)="onClickCorporateNavItem($event, translateText('NavBar.Home'), 'corporateAdmin/home')"
							[ngClass]="{'active' : currentRoute === translateText('NavBar.Home')}">
							<a href="javascript:void(0);">
								<img src="../../../assets/images/icn_home.png" alt="">
								{{'NavBar.Home' | translate}}
							</a>
						</li>
						<li (click)="onClickCorporateNavItem($event, translateText('NavBar.Administration'))"
							[ngClass]="{'active' : currentRoute===translateText('NavBar.Administration')}">
							<span href="javascript:void(0);">
								{{'NavBar.Administration' | translate}}
								<img class="arrow" src="../../../assets/images/start-v-arrow.png" alt="">
							</span>
							<ng-container *ngIf="this.isINTDLRSUPPORT">
								<div class="sub-menu" *ngIf="this.mySideNavSubMenuOpen">
									<div class="admin-submenu"></div>
									<ul>
										<li>
											<ng-container *ngFor="let subMenu of this.INTDLRSUPPORTSubMenu">
												<a [ngClass]="{'sub-menu-disabled': subMenu.isDisabled}"
													(click)="onClickSideNavItem($event, subMenu.path)">
													{{subMenu.menuName}}
												</a>
											</ng-container>
										</li>
									</ul>
								</div>
							</ng-container>
							<ng-container *ngIf="this.isINTINSTRUCTOR">
								<div class="sub-menu" *ngIf="this.mySideNavSubMenuOpen">
									<div class="admin-submenu"></div>
									<ul>
										<li>
											<ng-container *ngFor="let subMenu of this.INTINSTRUCTORSubMenu">
												<a [ngClass]="{'sub-menu-disabled': subMenu.isDisabled}"
													(click)="onClickSideNavItem($event, subMenu.path)">
													{{subMenu.menuName}}
												</a>
											</ng-container>
										</li>
									</ul>
								</div>
							</ng-container>
							<ng-container *ngIf="this.isINTCURRCOORD">
								<div class="sub-menu" *ngIf="this.mySideNavSubMenuOpen">
									<div class="admin-submenu"></div>
									<ul>
										<li>
											<ng-container *ngFor="let subMenu of this.INTCURRCOORDSubMenu">
												<a [ngClass]="{'sub-menu-disabled': subMenu.isDisabled}"
													(click)="onClickSideNavItem($event, subMenu.path)">
													{{subMenu.menuName}}
												</a>
											</ng-container>
										</li>
									</ul>
								</div>
							</ng-container>
							<ng-container *ngIf="this.isINTCTRYADMIN">
								<div class="sub-menu" *ngIf="this.mySideNavSubMenuOpen">
									<div class="admin-submenu"></div>
									<ul>
										<li>
											<ng-container *ngFor="let subMenu of this.INTCTRYADMINSubMenu">
												<a [ngClass]="{'sub-menu-disabled': subMenu.isDisabled}"
													(click)="onClickSideNavItem($event, subMenu.path)">
													{{subMenu.menuName}}
												</a>
											</ng-container>
										</li>
									</ul>
								</div>
							</ng-container>
							<ng-container *ngIf="this.isINTBUSADMIN">
								<div class="sub-menu" *ngIf="this.mySideNavSubMenuOpen">
									<div class="admin-submenu"></div>
									<ul>
										<li>
											<ng-container *ngFor="let subMenu of this.INTBUSADMINSubMenu">
												<a [ngClass]="{'sub-menu-disabled': subMenu.isDisabled}"
													(click)="onClickSideNavItem($event, subMenu.path)">
													{{subMenu.menuName}}
												</a>
											</ng-container>
										</li>
									</ul>
								</div>
							</ng-container>
						</li>
						<ng-container *ngIf="this.isCareerEntryVisible">
							<li (click)="onClickCorporateNavItem($event, translateText('NavBar.CareerEntry'))"
								[ngClass]="{'active' : currentRoute===translateText('NavBar.CareerEntry')}">
								<span href="javascript:void(0);">
									{{'NavBar.CareerEntry' | translate}}
									<img src="../../../assets/images/start-v-arrow.png" alt="">
								</span>
								<div class="sub-menu" *ngIf="this.mySideNavSubMenuOpen">
									<ul>
										<li>
											<a (click)="onClickSideNavItem($event, '')" class="sub-menu-disabled">
												{{'CareerEntry.CareerEntryCertificationList' | translate}}
											</a>
											<a (click)="onClickSideNavItem($event, '')" class="sub-menu-disabled"
												*ngIf="this.userSessionInformation?.spCEINSView === false">
												{{'Corporate.EmployeeLookup' | translate}}
											</a>
											<a (click)="onClickSideNavItem($event, '')" class="sub-menu-disabled">
												{{'CareerEntry.GraduatingClassList' | translate}}
											</a>
											<a (click)="onClickSideNavItem($event, '')" class="sub-menu-disabled">
												{{'CareerEntry.NonScheduledTrainingResults' | translate}}
											</a>
											<a (click)="onClickSideNavItem($event, '')" class="sub-menu-disabled"
												*ngIf="this.userSessionInformation?.spCESCHAdmin === false">
												{{'CareerEntry.SchoolList' | translate}}
											</a>
											<a (click)="onClickSideNavItem($event, '')" class="sub-menu-disabled">
												{{'Tiles.reset_password' | translate}}
											</a>
										</li>
									</ul>
								</div>
							</li>
						</ng-container>
						<li (click)="onClickCorporateNavItem($event, translateText('NavBar.ClassScheduleCatalog'))"
							[ngClass]="{'active' : currentRoute===translateText('NavBar.ClassScheduleCatalog')}">
							<span href="javascript:void(0);">
								{{'NavBar.ClassScheduleCatalog' | translate}}
								<img src="../../../assets/images/start-v-arrow.png" alt="">
							</span>
							<div class="sub-menu" *ngIf="this.mySideNavSubMenuOpen">
								<ul>
									<li>
										<a (click)="onClickSideNavItem($event, pathConstants.CLASS_SCHEDULE)">
											{{'NavBar.Classroom_Schedule' | translate}}
										</a>
										<a (click)="onClickSideNavItem($event, pathConstants.COURSE_CATALOG)">
											{{'NavBar.Course_And_Task_Catalog' | translate}}
										</a>
									</li>
								</ul>
							</div>
						</li>
						<li (click)="onClickCorporateNavItem($event, translateText('NavBar.Corporate'))"
							[ngClass]="{'active' : currentRoute===translateText('NavBar.Corporate')}">
							<span href="javascript:void(0);">
								{{'NavBar.Corporate' | translate}}
								<img src="../../../assets/images/start-v-arrow.png" alt="">
							</span>
							<div class="sub-menu" *ngIf="this.mySideNavSubMenuOpen">
								<ul>
									<li>
										<a (click)="onClickSideNavItem($event, pathConstants.CBU_SEARCH)">
											{{'Corporate.CorporateBusinessUnitSearch' | translate}}
										</a>
										<a (click)="onClickSideNavItem($event, '')" class="sub-menu-disabled">
											{{'Corporate.DealerList' | translate}}
										</a>
										<a (click)="onClickSideNavItem($event, '')" class="sub-menu-disabled"
											*ngIf="this.userSessionInformation?.spCEINSView === false">
											{{'Corporate.EmployeeLookup' | translate}}
										</a>
										<a (click)="onClickSideNavItem($event, pathConstants.FLEET_LIST)">
											{{'Corporate.FleetList' | translate}}
										</a>
									</li>
								</ul>
							</div>
						</li>
					</ng-container>

					<!--Nav Bar Bottom-->
					<li>
						<div class="nav-bar-bottom">
							<ng-container
								*ngIf="this.isAdminView && !this.router.url.includes('corp-admin-emp-profile')">
								<span href="javascript:void(0);" (click)="openManagementReports($event)">
									{{'NavBar.management_reports' | translate}}
								</span>
							</ng-container>

							<!--for learner-->
							<ng-container *ngIf="!(this.isAdminView || this.isCorporateAdminView)">
								<span href="javascript:void(0)" [routerLink]="['/user-support-information']"
									[routerLinkActive]="['active']"
									(click)="toggleMenu(leftMenus.UserSupportInformation)">
									{{'NavBar.User_Support_Information' | translate}}
								</span>
							</ng-container>

							<!--for Admin-->
							<ng-container *ngIf="this.loginService.isAdmin && this.isAdminView">
								<span
									[ngClass]="{'active' : this.router.url.includes('/admin/user-support-information')}"
									(click)="onClickSideNavItem($event, '/admin/user-support-information')">
									{{'NavBar.User_Support_Information' | translate}}
								</span>
							</ng-container>

							<!--for Corporate Admin-->
							<ng-container *ngIf="this.loginService.isCorporateAdmin && this.isCorporateAdminView">
								<span
									(click)="onClickCorporateNavItem($event, translateText('NavBar.User_Support_Information'), 'corporateAdmin/user-support-information')"
									[ngClass]="{'active' : currentRoute===translateText('NavBar.User_Support_Information')}">
									{{'NavBar.User_Support_Information' | translate}}
								</span>
							</ng-container>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<!--Right Panel-->
		<div class="RightPanel" (click)="closeSideNav()">
			<!--Header-->
			<app-header (mySideNavOpenEvent)="openSideNav($event)"></app-header>

			<!--Application Content-->
			<div class="contentPart">
				<!--Survey-->
				<!--<app-survey></app-survey>-->

				<!--Announcements-->
				<app-announcements></app-announcements>

				<router-outlet></router-outlet>
			</div>

			<!--Footer-->
			<div class="footerSection">{{'Login.Copyright_Ford_Motor_Company' | translate}}</div>
		</div>
	</div>
</div>

<div class="change-dealership-popup modal fade" (click)="fadeClick($event);" id="ChangeDealerShip" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">
					{{'NavBar.change_dealership'| translate}}
				</h5>
				<button type="button" class="close" #closeModal (click)="closeChangeDealerShip()"
					data-bs-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">
						<img src="../../assets/images/closed.png" alt="">
					</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="classDetails-popup-error-messages" *ngIf="this.errorOccured">
					<app-start-error [overrideHeight]="true" [height]="200" [errorMessages]="this.errorMessages"
						(closeStarsErrorModalEvent)="closeModal.click()">
					</app-start-error>
				</div>

				<div class="body-content scrollClass" id="equivalncies"
					*ngIf="!this.errorOccured && changeDealershipResponse?.result.changeDealershipList">
					<div class="list_row" *ngIf="changeDealershipResponse?.result.changeDealershipList === null">
						{{changeDealershipResponse?.errorMsgDesc}}
					</div>
					<div class="listingOuter" *ngIf="changeDealershipResponse?.result?.changeDealershipList.length > 0">
						<div class="list_row" style="vertical-align: middle;"
							*ngFor="let dealerShip of changeDealershipResponse?.result.changeDealershipList">
							<div class="row">
								<div class="col-lg-12 col-md-12 col-12"
									[ngStyle]="{ 'font-weight' : dealerShip.homeDealerFlag ? 'bold' : null}">
									<input type="radio" name="dealerShip" (click)="clickedRadio(dealerShip)"
										[ngModel]="selectedDealerCodeAndSubDealerShipCode" style="padding-right:10px"
										[value]="dealerShip.dealerCode + dealerShip.dealerSubCode">
									&nbsp;&nbsp;&nbsp;{{dealerShip.dealerName}} {{dealerShip.dealerCode}}
									{{dealerShip.dealerSubCode ? ' - ' + dealerShip.dealerSubCode : ''}}
								</div>
							</div>
						</div>
					</div>
					<div class="PopupfooterBtn">
						<div style="text-align: right!important">
							<a data-bs-dismiss="modal" aria-label="Close" (click)="closeChangeDealerShip()"
								style="text-decoration: none;" href="javascript:void(0)" class="cancelBtn">
								{{'Header.Cancel'| translate}}
							</a>
							<a href="javascript:void(0)" (click)="acceptDealershipChange()"
								class="normal-btn applyIcon">
								{{'Buttons.accept'| translate}}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<script>

	function removeActiveMenuClass() {
		$('.leftMenu li').removeClass('active');
	}
	$(".iconstop_mobile").click(function () {
		$(".iconstop_mobile").toggleClass("active");
	});
	$(".CM li").click(function () {
		$(this).toggleClass("active");
	});

</script>