import { Injectable } from '@angular/core';
import { LoginService } from '../login.service';
import { CommonService } from '../common.service';
import { TranslateService } from '@ngx-translate/core';
import { PageHistoryService } from '../pageHistoryService/page-history.service';
import { LaunchWebinarCoursesRequest, LaunchWebinarCoursesResponse } from '../actionService/launch-actions.model';
import { PopupBlockerService } from '../popupBlockerService/popup-blocker.service';
import { environment } from '../../../environments/environment';
import { ErrorService } from '../error-service/error.service';
import { ActionService } from '../actionService/action.service';
import { LoadCourseUrlService } from '../loadCourseUrlService/load-course-url.service';
import { LaunchCourseMissingPrereqComponent } from '../../launch-course-missing-prereq/launch-course-missing-prereq.component';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CourseLanguageOptionsComponent } from '../../course-language-options/course-language-options.component';

@Injectable()
export class LaunchWebinarCoursesCallService {
    serverErrorOccurred = false;
    loginResponse = this.loginService.getLoginResponse();
    launchWebinarCoursesResponse: LaunchWebinarCoursesResponse;
    public modalRef: BsModalRef;

    constructor(public loginService: LoginService,
        public commonService: CommonService,
        public translate: TranslateService,
        public pageHistoryService: PageHistoryService,
        public popupBlockerService: PopupBlockerService,
        public errorService: ErrorService,
        public actionService: ActionService,
        public loadCourseUrlService: LoadCourseUrlService,
        public modalService: BsModalService) { }

    // method to decide whether to Launch the OnDemand or Replay
    launchOnDemandReplay(task?: any, urlType?: string, route?: any, currentUrl?: any, optionalLanguage?: string) {
        this.loginResponse = this.loginService.getLoginResponse();
        const launchWebinarCoursesRequest: LaunchWebinarCoursesRequest = new LaunchWebinarCoursesRequest();
        if (!environment.launchWebinarAdobeServerCode) {
            launchWebinarCoursesRequest.adobeServerCode = 'NP';
        } else {
            launchWebinarCoursesRequest.adobeServerCode = 'USA';
        }
        launchWebinarCoursesRequest.countryCode = this.loginResponse.result.userSessionInformation.countryCode;
        launchWebinarCoursesRequest.courseCode = task.courseCode || task.taskCode;
        if (task.deliveryMethodCode === 'ONDMD') {
            launchWebinarCoursesRequest.groupType = 'OnDemand';
        } else if (task.deliveryMethodCode === 'WEBNR') {
            launchWebinarCoursesRequest.groupType = 'Replay';
        }
        launchWebinarCoursesRequest.lang = optionalLanguage ? optionalLanguage : this.loginResponse.result.userSessionInformation.userDefaultLanguageCode;
        launchWebinarCoursesRequest.urlType = urlType;
        launchWebinarCoursesRequest.userCDSID = sessionStorage.getItem('userId') || localStorage.getItem('userId');
        launchWebinarCoursesRequest.userStarsID = this.loginResponse.result.userSessionInformation.starsID;

        this.actionService.getLaunchOnDemandReplayWebinarCourses(launchWebinarCoursesRequest)
            .subscribe((launchWebinarCoursesResponse: LaunchWebinarCoursesResponse) => {
                this.launchWebinarCoursesResponse = launchWebinarCoursesResponse;
                if (this.launchWebinarCoursesResponse.message === 'InvalidRequest') {
                    this.openCourseLanguageOptions(this.launchWebinarCoursesResponse.result.urlAvailableLanguages,
                        {
                            launchWebinarCoursesResponse: this.launchWebinarCoursesResponse,
                            task: task,
                            urlType: urlType,
                            route: route,
                            currentUrl: currentUrl,
                        });
                }
                else {
                    this.loadCourseUrlService.loadWebinarCourseUrl(this.launchWebinarCoursesResponse);
                }
            },
                error => {
                    //console.log(error);
                    if (error.error.message === 'ValidationFailureMessage') {
                        this.openMissingPreReq(error.error.result.missingPrereqResponse.mapCourseList, error.error.errorMsgDesc);
                    } else {
                        this.serverErrorOccurred = true;
                        this.errorService.handleLaunchCourseServiceError(error, route, currentUrl);
                    }
                });
    }

    openCourseLanguageOptions(languageOptions, courseCallParams) {
        const initialState = {
            languageOptions: languageOptions,
            launchCourseParameters: courseCallParams,
            displayField: 'targetLanguageName',
            valueField: 'targetLanguage'
        };
        this.modalRef = this.modalService.show(CourseLanguageOptionsComponent, {
            initialState, class: 'modal-enforce-prereq'
        });
        this.modalRef.content.emitData = new Subject();
        this.modalRef.content.emitData.subscribe(() => {
            this.modalRef.hide();
        });

        this.modalRef.content.emitCourseData = new Subject();
        this.modalRef.content.emitCourseData.subscribe((courseData) => {
            this.modalRef.hide();
            this.launchOnDemandReplay(courseData.task, courseData.urlType, courseData.route, courseData.currentUrl, courseData.optionalLanguage);
        })
    }

    openMissingPreReq(missinPreReqData, errorMessage) {
        const initialState = {
            missingPreReqData: missinPreReqData,
            title: 'Missing Prerequisites',
            closeBtnName: 'Close',
            errorMessage: errorMessage
        };
        this.modalRef = this.modalService.show(LaunchCourseMissingPrereqComponent, {
            initialState, class: 'modal-enforce-prereq'
        });
        this.modalRef.content.emitData = new Subject();
        this.modalRef.content.emitData.subscribe(() => {
            this.modalRef.hide();
        });
    }
}