import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {CommonService} from '../../app/services/common.service';

const DROPDOWN_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DropdownComponent),
	multi: true,
};

@Component({
	selector: 'app-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [DROPDOWN_VALUE_ACCESSOR],
	encapsulation: ViewEncapsulation.None,
})

export class DropdownComponent implements OnInit {

	@Input()
	required = false;

	@Input()
	label: string;

	@Input()
	id: string;

	@Input()
	options: Array<any>;

	@Input()
	displayField: string;

	@Input()
	valueField: string;

	@Output()
	onSelectedItemChange = new EventEmitter<any>();

	@Input()
	selectedItem: any;

	@Input()
	disabled = false;

	@Input()
	isSubmitWaitlist = false;

	@Input()
	removeRightMargin = false;

	@Input()
	isCustomerSupport = false;

	@Input()
	width = '100%';

	@Input()
	invalidSelection = false;

	dropdownActive: boolean;

	get value() {
		return this.selectedItem;
	}

	set value(value) {
		this.selectedItem = value;
	}

	constructor(private commonService: CommonService, private _cd: ChangeDetectorRef) {}

	writeValue(value: any): void {
		//The below line is for Create Supplier
		//this.selectedItem = value;

		//The if condition must be removed for Create Supplier
		if (value) {
			this.selectedItem = value;
		}
		/*if(this._cd && !(this._cd as ViewRef).destroyed) {
			this._cd.detectChanges();
		}*/
	}

	onChange = (_) => {};
	onTouched = () => {};

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	ngOnInit() {}

	dropDownSelected(selectedItem) {
		this.selectedItem = selectedItem;
		this.onChange(selectedItem);
		this.onSelectedItemChange.emit(selectedItem);
	}

	@HostListener('document:click', ['$event'])
	clickout(event: any): void {
		this.dropdownActive = this.commonService.closePopup(
			this.dropdownActive,
			this.id,
			event
		);
	}

	@ViewChild('listOptionsOpen')
	set watch(listOptionsOpen: ElementRef) {
		if (listOptionsOpen) {
			this.scroll();
		}
	}

	scroll() {
		const elem = document.querySelector('.select-items:not(.select-hide) .same-as-selected') as HTMLElement;
		let container;
		if (elem) {
			container = elem.parentElement as HTMLElement;

			if (elem.offsetTop < container.scrollTop) {
				container.scrollTop = elem.offsetTop;
			} else {
				const offsetBottom = elem.offsetTop + elem.offsetHeight;
				const scrollBottom = container.scrollTop + container.offsetHeight;
				if (offsetBottom > scrollBottom) {
					container.scrollTop = offsetBottom - container.offsetHeight;
				}
			}
		}
	}
}
