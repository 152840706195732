<div *ngIf="overrideHeight" class="error-page" style="min-height: 200px !important; height: 200px !important">
	<ng-container [ngTemplateOutlet]="errorTemplate">
	</ng-container>
</div>
<div *ngIf="!overrideHeight" class="error-page">
	<ng-container [ngTemplateOutlet]="errorTemplate">
	</ng-container>
</div>

<ng-template #errorTemplate>
	<img src="../../assets/images/404.png" alt="">
	<p class="text1">{{this.errorMessages?.serverErrorTitle}}</p>
	<p class="text2">
		({{this.errorMessages?.serverErrorOccurredTime | date: 'mediumDate'}}
		- {{this.errorMessages?.serverErrorOccurredTime | date: 'hh:mm a' | lowercase}} EST) - ({{this.starsID}})
	</p>
	<p>{{this.errorMessages?.serverErrorBody}}
		<a target="_self" href="javascript:void(0)"
		   (click)="navigateCustomerSupport('/customer-support')">
			{{'<' + 'Customer Support' + '>'}}
		</a>
	</p>
</ng-template>