<div class="pre-reques-model" id="missing-prerequisites">
	<div class="modal-header">
		<h5 class="modal-title">
			{{'enrollmentActivity.missingPrerequisitesTitle' | translate}}
		</h5>
		<button type="button" class="modal-close" style="opacity: .85; border: none;" data-bs-dismiss="modal"
				(click)="closeModal()">
      <span aria-hidden="true">
        <img src="../../assets/images/closed.png" alt="">
      </span>
		</button>
	</div>
	<div class="launch-course-error-message">
		<div>
			<img src="../../assets/images/danger.png" alt="">
		</div>
		<div style="padding-left: 10px;">
			{{errorMessage}}
		</div>
	</div>
	<div class="modal-body">
		<ng-container *ngFor="let missingPrerequisite of this.missingPreReqData">
			<div class="prerequisite">
				<div class="preResp-code">
					<b style="word-break: break-all;">{{missingPrerequisite.mappedCourseCode}}: </b>
				</div>
				<div class="preResp-title">
					{{missingPrerequisite.mappedCourseTitle}}
				</div>
			</div>
		</ng-container>
	</div>
	<div style="display: flex;flex: 1;justify-content: center;padding-top: 15px;">
		<a href="javascript:void(0)" class="normal-btn" (click)="closeModal()" data-bs-dismiss="modal">
			{{'Close' | translate}}
		</a>
	</div>
	<div class="modal-body" *ngIf="this.serverErrorOccurred">
		<div class="modal-error-messages">
			<app-start-error [errorMessages]="this.errorMessages"></app-start-error>
		</div>
	</div>
</div>