import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../services/login.service';
import {PageHistoryService} from '../../services/pageHistoryService/page-history.service';
import {CourseDetailsRequest, CourseDetailsResponse} from '../models/course-details.model';
import {environment} from '../../../environments/environment';
import {ApiEndPoints} from '../../../Constants/apiendpoints.constants';

@Injectable()
export class CourseDetailsService {
	constructor(
		private http: HttpClient,
		public loginService: LoginService,
		public pageHistoryService: PageHistoryService,
		public router: Router,
		public route: ActivatedRoute) {
	}

	getCourseDetails(courseDetailsRequest: CourseDetailsRequest) {
		return this.http.post<CourseDetailsResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getCourseDetails}`
			, JSON.stringify(courseDetailsRequest));
	}

	loadCourseDetails(course: any, route: any) {
		this.loginService.starLearning.get('courseDetails').get('courseCode').patchValue(course.taskCode || course.courseCode);
		this.loginService.starLearning.get('courseDetails').get('taskDetails').patchValue({course});
		this.pageHistoryService.pushPageValueIntoPageHistory(this.router.url);
		this.router.navigate(['course-details'], {relativeTo: route});
	}
}
