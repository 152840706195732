import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ApiEndPoints} from '../../../Constants/apiendpoints.constants';
import {EnrollmentActivityRequest, EnrollmentActivityResponse,
	MissingPrerequisiteRequest, MissingPrerequisiteResponse} from '../models/enrollment-activity.model';

@Injectable()
export class EnrollmentActivityService {

	constructor(private http: HttpClient) {}

	getEnrollmentActivity(enrollmentActivityRequest: EnrollmentActivityRequest) {
		return this.http.post<EnrollmentActivityResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getEnrollmentActivity}`
			, JSON.stringify(enrollmentActivityRequest));
	}

	getMissingPrerequisites(missingPrerequisiteRequest: MissingPrerequisiteRequest) {
		return this.http.post<MissingPrerequisiteResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getMissingPrerequisites}`
			, JSON.stringify(missingPrerequisiteRequest));
	}
}
