export class LeftMenus {
	Dashboard = 'Dashboard';
	CertSummary = 'cert_summary';
	ToDo = 'ToDo';
	ToDoUptodate = 'ToDoUpToDate';
	PrintIcon = 'PrintIcon';
	EnrollmentActivity = 'EnrollmentActivity';
	ClassDetails = 'ClassDetails';
	CourseAndTaskCatalog = 'CourseAndTaskCatalog';
	TrainingHistory = 'TrainingHistory';
	ClassroomSchedule = 'ClassroomSchedule';
	UserSupportInformation = 'UserSupportInformation';
	EmployeeList = 'EmployeeList';
	eLearning = 'eLearning';
}

export class ChangeDealershipRequest {
	starsID: string;
	loginUserLanguageCode: string;
}

export class ChangeDealershipResponse {
	message: string;
	errorMsgDesc: string;
	errorMsgDescList: string;
	warnMsgDesc: string;
	result: ChangeDealerShipResult;
}

export class ChangeDealerShipResult {
	errorFlag: string;
	errorMessageID: string;
	errorMessage: string;
	changeDealershipList: Array<ChangeDealershipList>;
	newDealerAllowed: boolean;
}

export class ChangeDealershipList {
	dealerCode: string;
	dealerSubCode: string;
	countryCode: string;
	countryName: string;
	dealerName: string;
	homeDealerFlag: boolean;
}
