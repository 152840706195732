import { Injectable } from '@angular/core';
import { LoginService } from '../login.service';
import { PageHistoryService } from '../pageHistoryService/page-history.service';
import { CommonService } from '../common.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class ViewScheduleCourseCodeStore {

	constructor(public loginService: LoginService,
		public pageHistoryService: PageHistoryService,
		private commonService: CommonService,
		public router: Router,
		public route: ActivatedRoute) { }

	// on navigating to Schedule pages, setting the contextData into sessionStorage with searchData
	saveContext(searchOption, courseInfo) {
		const contextData = { searchOption, courseInfo };
		sessionStorage.setItem('searchData', JSON.stringify(contextData));
	}

	// this method captures the Course Code and also we pass code as searchOption to switch the radio button on Class/Webinar Schedule
	// as we have a hold of the searchData we pass the searchData as key to save the sessionContext and code to populate the text in th search bar
	captureCourseCodeAndPassToSchedule(courseInfo?: any, currentUrl?: any, relativeTo?: ActivatedRoute) {
		this.commonService.saveSessionContext('searchData', { searchOption: 'code', courseInfo });
		if (courseInfo.deliveryMethodCode === 'CLASSRM') {
			this.gotoRespectiveSchedulePage(courseInfo, currentUrl, 'class-schedule', relativeTo);
		} else if (courseInfo.deliveryMethodCode === 'WEBNR') {
			this.gotoRespectiveSchedulePage(courseInfo, currentUrl, 'webinar-schedule', relativeTo);
		}
	}

	// this method will navigate the user to Class Schedule or Webinar Schedule
	// based on the pageName we pass from the above method
	gotoRespectiveSchedulePage(courseInfo: any, currentUrl: any, pageName: string, relativeTo?: ActivatedRoute) {
		this.pageHistoryService.pushPageValueIntoPageHistory(currentUrl);
		this.router.navigate([pageName], { relativeTo: relativeTo ? relativeTo : this.route }); //change when all other pages are refactor to modules
	}

}
