export const northAmericaUs = ['USA'];
export const northAmericaCan = ['CAN'];
export const northAmericaMex = ['MEX'];
export const southAmericaGroup1 = ['ARG', 'CHL', 'COL', 'PER', 'VEN'];
export const southAmericaGroup2 = ['BRA'];
export const southAmericaGroup3 = ['BOL', 'ECU', 'FLK', 'GUF', 'GUY', 'PRY', 'SUR', 'URY'];
export const asiaPacificGp1 = ['CHN', 'TWN', 'JPN', 'IND', 'IDN', 'MYS'];
export const asiaPacificGp2 = ['ZAF', 'NAM', 'BWA', 'SWZ'];
export const asiaPacificGp3 = ['THA', 'VNM', 'PHL', 'NZL'];
export const asiaPacificGp4 = ['AUS'];
export const emsGroup1 = ['PRI', 'VIR'];
export const emsGroup2 = ['WAP', 'WCB', 'WCA', 'EDM', 'WIS', 'ME1', 'WME', 'WEX', 'WAF'];
export const europeGroup1 = ['DNK', 'FRA', 'ITA', 'PRT', 'RUS', 'ESP'];
export const europeGroup2 = ['DEU'];
export const europeGroup3 = ['BEL', 'CHE', 'CZE', 'FIN', 'GRC', 'HRV', 'HUN', 'IRL', 'NOR',
    'POL', 'SWE', 'TUR', 'AUT', 'NLD', 'ROU', 'ALB', 'DZA', 'AND', 'ARM', 'AZE', 'BLR', 'BIH',
    'BGR', 'CYP', 'EGY', 'EST', 'GEO', 'GIB', 'KAZ', 'KOS', 'LVA', 'LBY', 'LTU', 'MKD', 'MLT',
    'MDA', 'MNE', 'MAR', 'SRB', 'SVK', 'SVN', 'TUN', 'UKR'];
export const europeGroup4 = ['GBR'];

export const northAmerica = northAmericaUs.concat(northAmericaCan, northAmericaMex);
export const rfcCountries = northAmericaMex;
export const northAmericaButMex = northAmericaUs.concat(northAmericaCan);
export const cpfCountries = southAmericaGroup2;
export const emptyVeteran = southAmericaGroup2.concat(northAmericaCan, emsGroup1);

export const southAmericaButBrazil = southAmericaGroup1.concat(southAmericaGroup3);
export const southAmerica = southAmericaGroup1.concat(southAmericaGroup2, southAmericaGroup3);
export const southAmericaExceptGroup3 = southAmericaGroup1.concat(southAmericaGroup2);
export const allowSearchBySSN = northAmerica.concat(southAmericaGroup2, emsGroup1);
export const addressFormat1 = northAmerica.concat(emsGroup1);
export const showMapCountries = northAmerica.concat(asiaPacificGp4);
export const allAsiaPacific = asiaPacificGp1.concat(asiaPacificGp2, asiaPacificGp3, asiaPacificGp4);
export const asiaPacificNotShopping = asiaPacificGp4.concat(asiaPacificGp3[asiaPacificGp3.length - 1]);
export const asiaPacificShopping = allAsiaPacific.filter(elem => !asiaPacificNotShopping.includes(elem));
export const allEMSCountries = emsGroup1.concat(emsGroup2);
export const allEuropeanCountries = europeGroup1.concat(europeGroup2, europeGroup3, europeGroup4);
export const allowEditGeneral = northAmericaButMex.concat(southAmerica, allAsiaPacific, allEMSCountries, allEuropeanCountries);
export const wingSpanCountries = asiaPacificGp3.concat(asiaPacificGp4);
export const optionalDealerPortal = allEuropeanCountries;
export const acceptDeclarationCountries = asiaPacificGp2.concat(southAmericaGroup2, europeGroup2);
export const acceptPrivacyCountries = europeGroup1.concat(europeGroup3, europeGroup4);
/* These for now have the same values in case in future have specific countries */
export const showDealerBranch = southAmerica.concat(allAsiaPacific);
export const addressFormat2 = southAmerica.concat(allAsiaPacific, emsGroup2, allEuropeanCountries);
export const showDBCeckbox = southAmerica.concat(allAsiaPacific, emsGroup2, allEuropeanCountries);
export const ssnView = northAmericaButMex.concat(emsGroup1);
export const showSpecialDivision = asiaPacificGp1[0]; //Only China
export const displayCertAlerts = northAmericaButMex.concat(allEuropeanCountries);

// Dealer Pages
export const displayShopTechMenu = northAmerica.concat(southAmericaGroup1, southAmericaGroup2, asiaPacificShopping, allEMSCountries);
export const displayDealerListMoreActions = northAmerica.concat(southAmericaExceptGroup3, allAsiaPacific, allEMSCountries);
export const allGOLDDCountries = northAmerica.concat(emsGroup1, emsGroup2, asiaPacificGp1, asiaPacificGp2, asiaPacificGp3, asiaPacificGp4,
    southAmericaGroup1, southAmericaGroup2, southAmericaGroup3, europeGroup1, europeGroup2);
export const allNonGOLDDCountries = europeGroup3.concat(europeGroup4);
export const dealerProgramStatus = northAmericaUs.concat(northAmericaCan);
export const canCreateDealer = europeGroup3.concat(europeGroup4);
