import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ApiEndPoints} from '../../../Constants/apiendpoints.constants';
import {FormArray} from '@angular/forms';
import {LoginService} from '../../services/login.service';
import {CommonService} from '../../services/common.service';
import {PageHistoryService} from '../../services/pageHistoryService/page-history.service';
import {Router} from '@angular/router';
import {LoginResponse} from '../../login/Models/LoginResponse.model';
import {CertificatePlannerRequest, CertificatePlannerResponse} from '../models/certificate-planner.model';

@Injectable()
export class CertificatePlannerService {
	constructor(private http: HttpClient,
				public loginService: LoginService,
				public commonService: CommonService,
				public pageHistoryService: PageHistoryService,
				public router: Router) {}

	getCertificatePlanner(certificateRequest: CertificatePlannerRequest) {
		return this.http.post<CertificatePlannerResponse>(`${environment.ApiBaseURL}${ApiEndPoints.getCertificatePlanner}`
			, JSON.stringify(certificateRequest));
	}

	loadCertificationPlanner(course: any, loginResponse: LoginResponse, route: any) {
		// below if condition overrides the taskCode to certCode
		// To support certification planner from To Do List, Certifications and Dashboard
		if (this.router.url.includes('to-do') || this.router.url.includes('dashboard')) {
			course.certCode = course.taskCode || course.certCode;
		}
		(this.loginService.starLearning.get('certPlanner').get('certPlannerHistory') as FormArray).value.push(this.commonService.trim(course.certCode));
		this.loginService.starLearning.get('certPlanner').get('certCountry').patchValue(loginResponse.result.userSessionInformation.countryCode);
		this.loginService.starLearning.get('certPlanner').get('starsId').patchValue(loginResponse.result.userSessionInformation.starsID);
		this.pageHistoryService.pushPageValueIntoPageHistory(this.router.url);
		this.router.navigate(['certification-planner'], {relativeTo: route});
	}

}
