import { Injectable } from '@angular/core';
import { DeclarationRequest, UsageRequestModel, UserModel } from '../login/Models/UserModel.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiEndPoints } from 'Constants/apiendpoints.constants';
import { LoginResponse } from '../login/Models/LoginResponse.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from './common.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LoginService {
	userInfo: Array<UserModel> = [];
	loginResponseObject: LoginResponse;
	loginResponse: Subject<LoginResponse> = new Subject<LoginResponse>();
	starLearning: FormGroup;

	corporateAdminSecurityProfiles: Array<string> = [
		'INTDLRSUPPORT',
		'INTINSTRUCTOR',
		'INTCURRCOORD',
		'INTCTRYADMIN',
		'INTBUSADMIN'
	];

	dealerSecurityProfiles: Array<string> = [
		'DLRMANAGER', 'DLRGENERAL'
	];

	constructor(private http: HttpClient,
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		public translate: TranslateService) {
	}

	updateLoginResponse(loginResponse: LoginResponse) {
		this.loginResponse.next(loginResponse);
		localStorage.setItem('userContext', JSON.stringify(loginResponse));
		this.loginResponseObject = loginResponse;
	}

	getLoginResponse(): LoginResponse {
		// changed this from sessionStorage to localStorage as userContext is not available in the new tab
		// sessionStorage was working till this point because we were testing in a single tab
		// as sessionStorage variables are not accessible in other tabs or new tabs, we changed this to get the userContext from localStorage
		if (localStorage.getItem('userContext') === undefined) {
			return this.loginResponseObject;
		} else {
			this.loginResponseObject = JSON.parse(localStorage.getItem('userContext')) as LoginResponse;
			return this.loginResponseObject;
		}
	}

	get isCorporate() {
		return this.loginResponseObject.result.userSessionInformation.internalUser;
	}

	//getters and setters to access the components of Form Group - Start
	getStarsHistory() {
		return this.starLearning.get('starsHistory');
	}

	getBaseComponent() {
		return this.starLearning.get('starsHistory').get('baseComponent');
	}

	get IsAdminView() {
		return this.starLearning.get('starsHistory').get('isAdminView').value;
	}

	set IsAdminView(value: any) {
		this.starLearning.get('starsHistory').get('isAdminView').setValue(value);
	}

	get isChangeDealerShip() {
		return this.loginResponseObject.result.userProfileDetailsResponse.userDetailsBO.showChangeDealershipSubMenu;
	}

	getCurrentComponent() {
		return this.starLearning.get('currentComponent');
	}

	setCurrentComponent(value) {
		return this.starLearning.get('currentComponent').setValue(value);
	}

	getPageComponent() {
		return this.starLearning.get('pageHistory').get('pageComponent');
	}

	getBackPageUrl(): string {
		let backUrls = this.getPageComponent().value;
		return backUrls.length > 0 ? backUrls[backUrls.length - 1] : '';
	}

	setPageComponent(values) {
		return this.getPageComponent().setValue(values);
	}

	setCertPlannerHistory(patchValues: any) {
		return this.starLearning.get('certPlanner').get('certPlannerHistory').setValue(patchValues);
	}

	getErrorMessage() {
		return this.starLearning.get('errorMessage');
	}

	getServerErrorTitle() {
		return this.starLearning.get('errorMessage').get('serverErrorTitle');
	}

	getServerErrorBody() {
		return this.starLearning.get('errorMessage').get('serverErrorBody');
	}

	getEmployeeInfoForAdmin() {
		return this.starLearning.get('adminEmployeeInfo');
	}

	get isAdmin() {
		const isAdmin = this.loginResponseObject &&
			this.loginResponseObject.result &&
			(this.loginResponseObject.result.userSessionInformation.highestSecurityProfileCode === 'DLRMANAGER'
				|| this.loginResponseObject.result.userSessionInformation.highestSecurityProfileCode === 'FLTMANAGER');
		return isAdmin;
	}

	get isCorporateAdmin(): boolean {
		return (
			this.corporateAdminSecurityProfiles.filter(
				(x) =>
					this.loginResponseObject &&
					x === this.loginResponseObject.result.userSessionInformation.highestSecurityProfileCode.toString()
			).length > 0
		);
	}
	//getters and setters to access the components of Form Group - End

	getStartsLearningForm() {
		if (sessionStorage.getItem('formContext1')) {
			this.starLearning.patchValue(sessionStorage.getItem('formContext') as any);
		} else {
			this.starLearning = this.formBuilder.group({
				starsHistory: this.formBuilder.group({
					baseComponent: [],
					selectedBubble: [''],
					selectedPage: [''],
					showDiscontinued: [''],
					isAdminView: [false]
				}),
				currentComponent: [],
				certSummary: this.formBuilder.group({
					showDiscontinued: [false]
				}),
				certPlanner: this.formBuilder.group({
					certCode: [''],
					certCountry: [''],
					starsId: [''],
					certPlannerHistory: this.formBuilder.array([]),
					certLaunchCourseURL: ['']
				}),
				certHistory: this.formBuilder.group({
					selectedStarsID: [''],
					selectedCertCode: [''],
					selectedCountry: [''],
					userDefaultLanguage: ['']
				}),
				courseDetails: this.formBuilder.group({
					courseCode: [''],
					taskDetails: []
				}),
				enrollmentActivity: this.formBuilder.group({
					starsId: [''],
					country: [''],
					selectedStatus: [''],
					userLang: [''],
					dealerCode: ['']
				}),
				trainingLocationDetails: this.formBuilder.group({
					country: [''],
					locationCode: [''],
					language: [''],
					locationIndicator: [''],
					locationTitle: [''],
					courseCode: [''],
					courseTitle: ['']
				}),
				classDetails: this.formBuilder.group({
					courseCode: [''],
					title: [''],
					deliveryMethod: [''],
					sessionNo: ['']
				}),
				viewHotelDetails: this.formBuilder.group({
					hotelCode: [''],
					hotelCountry: [''],
					userDefaultLanguage: [''],
					trainingLocation: ['']
				}),
				errorMessage: this.formBuilder.group({
					serverErrorBody: [''],
					serverErrorTitle: ['']
				}),
				pageHistory: this.formBuilder.group({
					pageComponent: [[]]
				}),
				adminEmployeeInfo: this.formBuilder.group({
					empStarsID: [''],
					empName: [''],
					empEmail: [''],
					empCountry: [''],
					userDefaultLanguage: [''],
					isSecDealership: [''],
					empDealerCode: [''],
					empDealerFleetIndicator: [''],
					empJointVentureCode: ['']
				}),
				corporateRequiredInfo: this.formBuilder.group({
					locCode: [null],
					locCountry: [null],
					locSpJointVen: [null],
					altName: this.formBuilder.group({
						defaultLangInd: [''],
						description: [''],
						languageCode: [''],
						languageDesc: [''],
						lastUpdateTime: [''],
						title: ['']
					}),
					roomInfo: this.formBuilder.group({
						capacity: [''],
						roomName: [''],
						roomNumber: [''],
						timeStamp: ['']
					})
				}),
				eLearningInfo: this.formBuilder.group({
					employee: null,
					courseCode: [''],
					courseName: ['']
				}),
				trainingHistory: this.formBuilder.group({
					employee: null,
					courseCode: [''],
					courseName: [''],
					trainingHistoryObject: null
				}),
				courseCreation: this.formBuilder.group({
					courseCode: [''],
					courseName: [''],
					jointVentureCode: [''],
					jointVentureName: [''],
					deliveryMethod: [''],
					groupToAdd: ['']
				}),
				associatedWaitlist: this.formBuilder.group({
					employee: null,
					courseCode: [''],
					courseName: [''],
					trainingHistoryObject: null
				}),
				maintainURL: this.formBuilder.group({
					courseCode: [''],
					targetLanguageCode: [''],
					targetLanguageDesc: [''],
					urlType: [''],
					urlTypeDesc: [''],
					urlAddress: [''],
					displayTargetCountryCodes: [''],
					displayTargetCountriesDesc: ['']
				})
			});
		}
		this.getLoginResponse();
		if (sessionStorage.getItem('context')) {
			const contextHistory = JSON.parse(sessionStorage.getItem('context'));
			// this.starLearning.get('homePage').patchValue(contextHistory.homePage);
			// Certification Summary
			this.starLearning.get('starsHistory').get('baseComponent').patchValue(contextHistory.starsHistory.baseComponent);
			this.starLearning.get('starsHistory').get('selectedBubble').patchValue(contextHistory.starsHistory.selectedBubble);
			this.starLearning.get('starsHistory').get('selectedPage').patchValue(contextHistory.starsHistory.selectedPage);
			this.starLearning.get('starsHistory').get('isAdminView').patchValue(contextHistory.starsHistory.isAdminView);
			this.starLearning.get('starsHistory').get('showDiscontinued').patchValue(contextHistory.starsHistory.showDiscontinued);
			this.starLearning.get('currentComponent').patchValue(contextHistory.currentComponent);
			// Certification Planner
			this.starLearning.get('certPlanner').get('certPlannerHistory').patchValue([]);
			contextHistory.certPlanner.certPlannerHistory.forEach(element => {
				this.starLearning.get('certPlanner').get('certPlannerHistory').value.push(element);
			});
			this.starLearning.get('certPlanner').get('certCountry').patchValue(contextHistory.certPlanner.certCountry);
			this.starLearning.get('certPlanner').get('starsId').patchValue(contextHistory.certPlanner.starsId);
			// Certification History
			this.starLearning.get('certHistory').get('selectedStarsID').patchValue(this.commonService.trim(this.loginResponseObject.result.userSessionInformation.starsID));
			this.starLearning.get('certHistory').get('selectedCertCode').patchValue(this.commonService.trim(contextHistory.certHistory.selectedCertCode));
			this.starLearning.get('certHistory').get('selectedCountry').patchValue(this.loginResponseObject.result.userSessionInformation.countryCode);
			this.starLearning.get('certHistory').get('userDefaultLanguage').patchValue(this.loginResponseObject.result.userSessionInformation.userDefaultLanguageCode);
			// Course Details
			this.starLearning.get('courseDetails').get('courseCode').patchValue(contextHistory.courseDetails.courseCode);
			this.starLearning.get('courseDetails').get('taskDetails').patchValue(contextHistory.courseDetails.taskDetails);
			// Enrollment Activity
			this.starLearning.get('enrollmentActivity').get('starsId').patchValue(this.commonService.trim(this.loginResponseObject.result.userSessionInformation.starsID));
			this.starLearning.get('enrollmentActivity').get('country').patchValue(this.loginResponseObject.result.userSessionInformation.countryCode);
			this.starLearning.get('enrollmentActivity').get('selectedStatus').patchValue(contextHistory.enrollmentActivity.selectedStatus);
			this.starLearning.get('enrollmentActivity').get('userLang').patchValue(this.loginResponseObject.result.userSessionInformation.userDefaultLanguageCode);
			this.starLearning.get('enrollmentActivity').get('dealerCode').patchValue(this.loginResponseObject.result.userSessionInformation.dealerCode);
			// Training Location Details
			this.starLearning.get('trainingLocationDetails').get('country').patchValue(contextHistory.trainingLocationDetails.country);
			this.starLearning.get('trainingLocationDetails').get('locationCode').patchValue(contextHistory.trainingLocationDetails.locationCode);
			this.starLearning.get('trainingLocationDetails').get('language').patchValue(this.loginResponseObject.result.userSessionInformation.userDefaultLanguageCode);
			this.starLearning.get('trainingLocationDetails').get('locationIndicator').patchValue('');
			this.starLearning.get('trainingLocationDetails').get('courseCode').patchValue(contextHistory.trainingLocationDetails.courseCode);
			this.starLearning.get('trainingLocationDetails').get('courseTitle').patchValue(contextHistory.trainingLocationDetails.courseTitle);
			// Class Details
			this.starLearning.get('classDetails').patchValue(contextHistory.classDetails);
			// Hotel Details
			this.starLearning.get('viewHotelDetails').get('hotelCode').patchValue(contextHistory.viewHotelDetails.hotelCode);
			this.starLearning.get('viewHotelDetails').get('hotelCountry').patchValue(this.loginResponseObject.result.userSessionInformation.countryCode);
			this.starLearning.get('viewHotelDetails').get('userDefaultLanguage').patchValue(this.loginResponseObject.result.userSessionInformation.userDefaultLanguageCode);
			this.starLearning.get('viewHotelDetails').get('trainingLocation').patchValue(contextHistory.viewHotelDetails.trainingLocation);
			// Error Messages
			this.getErrorMessage().patchValue(contextHistory.errorMessage);
			// Page History
			this.getPageComponent().patchValue(contextHistory.pageHistory);
			// Admin Employee Info
			this.getEmployeeInfoForAdmin().patchValue(contextHistory.adminEmployeeInfo);
		}
		return this.starLearning;
	}

	saveContext() {
		const contextHistory = {
			starsHistory: this.starLearning.get('starsHistory').value,
			currentComponent: this.starLearning.get('currentComponent').value,
			certPlanner: this.starLearning.get('certPlanner').value,
			certHistory: this.starLearning.get('certHistory').value,
			courseDetails: this.starLearning.get('courseDetails').value,
			enrollmentActivity: this.starLearning.get('enrollmentActivity').value,
			trainingLocationDetails: this.starLearning.get('trainingLocationDetails').value,
			classDetails: this.starLearning.get('classDetails').value,
			viewHotelDetails: this.starLearning.get('viewHotelDetails').value,
			errorMessage: this.getErrorMessage().value,
			pageHistory: this.getPageComponent().value,
			adminEmployeeInfo: this.getEmployeeInfoForAdmin().value
		};
		sessionStorage.setItem('context', JSON.stringify(contextHistory));
	}

	authenticateUser(loginInfo: UserModel): Observable<LoginResponse> {
		return this.http.post<LoginResponse>(`${environment.ApiBaseURL}${ApiEndPoints.login}`
			, JSON.stringify(loginInfo));
	}

	runUsageReport(usageRequestModel: UsageRequestModel): Observable<any> {
		return this.http.post<any>(`${environment.ApiBaseURL}${ApiEndPoints.usageReport}`
			, JSON.stringify(usageRequestModel));
	}

	acceptDeclarationOfConsent(declarationRequest: DeclarationRequest): Observable<LoginResponse> {
		return this.http.post<LoginResponse>(`${environment.ApiBaseURL}${ApiEndPoints.acceptDeclarationOfConsent}`
			, JSON.stringify(declarationRequest));
	}

	translateTheText(text: string) {
		return this.translate.instant(text).toLowerCase();
	}
}
