import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { environment } from '../../environments/environment';
import { UserIdService } from '../services/userIdService/user-id.service';

/**
 * This guard is used across all app routes that require live data,
 * determining if the auth token is valid before allowing navigation to occur
 */
@Injectable()
export class AuthGuardService implements CanActivate {

	/** Creates an instance of the AuthGuardService
	 *
	 * @param router used for navigation actions
	 */
	constructor(private router: Router, private idService: UserIdService) {}

	/** the actual guard for routing. Uses the current token information to determine if the user needs to go through the login flow again
	 *
	 * @param route the snapshot of the current ActivatedRoute
	 * @param state the snapshot of the current RouterState
	 * @returns whether route can be activated or not
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		// Store state url in sessionStorage for later redirect by callback component
		// added this condition to make sure that we have the redirect uri to be not equal to dashboard to overcome the issue of tab navigation
		if (state.url && (!state.url.includes('dashboard'))) {
			sessionStorage['redirectURL'] = state.url;
			console.log(sessionStorage['redirectURL']);
		}
		return this.checkLogin();
	}

	/** Function used to determine if the current token is valid. Checks token expiration against the current timestamp
	 * @returns whether or not the token is expired
	 */
	isTokenExpired(): boolean {
		const epoch = Math.trunc(new Date().getTime() / 1000);
		let expEpoch = null;

		if (sessionStorage.getItem('tokenExp')
			&& sessionStorage.getItem('tokenExp') !== 'null') {
			expEpoch = parseInt(sessionStorage.getItem('tokenExp'), 10);
			return (epoch >= expEpoch);
		} else {
			return true;
		}
	}

	/** Uses isTokenExpired() to determine if the user credentials should be cleared and the user forwarded to the adfs-login component
	 * @returns for whether the user is "logged in" or not
	 */
	checkLogin(): boolean {
		if (environment.inmem) {
			return true;
		}

		// using local storage to access userContext because userContext which is in session storage is not available in new tab
		if (this.isTokenExpired() && !localStorage.getItem('userContext')) {
			// Clear oauth session items
			sessionStorage.removeItem('tokenExp');
			sessionStorage.removeItem('tokenIssue');
			sessionStorage.removeItem('strAccessToken');
			sessionStorage.removeItem('encodedAccessToken');
			this.idService.setUserID('Not Logged In');

			// Navigate to the adfs-login page with extras
			this.router.navigate(['/adfs-login']);

			return false; // guard complete and then router redirects to /adfs-login
		}
		return true;
	}
}
