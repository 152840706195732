import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ApiEndPoints} from '../../../../Constants/apiendpoints.constants';
import {EmployeeListRequest} from '../models/employee-list.model';
import {CertificatePlannerRequest} from '../../../certification-planner/models/certificate-planner.model';
import {TrainingHistoryRequest} from '../../../training-history/models/training-history.model';

@Injectable()
export class ExcelExportService {
	constructor(private http: HttpClient) { }
	getExportToExcel(employeeListRequest: EmployeeListRequest, direction: string, sort: string): Observable<Blob> {
		let exportToExcelURL = `${environment.ApiBaseURL}${ApiEndPoints.getExportToExcel}`;
		exportToExcelURL = exportToExcelURL.replace('{direction}', direction.toString());
		exportToExcelURL = exportToExcelURL.replace('{sort}', sort.toString());

		return this.http.post(exportToExcelURL
			, JSON.stringify(employeeListRequest), {
				responseType: 'blob'
			});
	}

	getExportPlannerToExcel(certificatePlannerRequest: CertificatePlannerRequest): Observable<Blob> {
		const exportToExcelURL = `${environment.ApiBaseURL}${ApiEndPoints.certPlannerDownload}`;

		return this.http.post(exportToExcelURL
			, JSON.stringify(certificatePlannerRequest), {
				responseType: 'blob'
			});
	}

	getExportTrainingHistoryToExcel(trainingHistoryRequest: TrainingHistoryRequest, direction: string, sort: string): Observable<Blob> {
		let exportToExcelURL = `${environment.ApiBaseURL}${ApiEndPoints.getExportTrainingHistoryToExcel}`;
		exportToExcelURL = exportToExcelURL.replace('{direction}', direction.toString());
		exportToExcelURL = exportToExcelURL.replace('{sort}', sort.toString());

		return this.http.post(exportToExcelURL
			, JSON.stringify(trainingHistoryRequest), {
				responseType: 'blob'
			});
	}
}
