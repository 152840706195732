import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { LoginService } from '../services/login.service';
import { LogoutService } from '../services/logout.service';
import { Router } from '@angular/router';
import { AnnouncementService } from '../announcements/services/announcement.service';
import { CommonService } from '../services/common.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	// emitting the event to nav bar component to open the side nav
	@Output() mySideNavOpenEvent = new EventEmitter<boolean>();

	// below variables are used for toggling the announcement button on header
	showLgIcon: boolean;
	showMbIcon: boolean;

	// below variables for user profile and logout
	//isUserProfile = false;
	logoutOpen = false;
	isAdminView = false;
	isCorporateAdminView = false;

	constructor(public loginService: LoginService,
		public logoutService: LogoutService,
		public router: Router,
		public announcementService: AnnouncementService,
		public commonService: CommonService) {
	}

	isUserProfile() {
		return (this.router.url.includes('/viewuserprofile') ||
			this.router.url.includes('/admin/employee-list/emp-profile') ||
			this.router.url.includes('/corp-admin-emp-profile'));
	}

	ngOnInit() {
		window.scrollTo(0, 0);
		//this.isUserProfile = (this.router.url.includes('/viewuserprofile') || this.router.url.includes('/admin/employee-list/emp-profile'));
		this.isAdminView = this.router.url.includes('admin') && this.commonService.getSessionData('selectedAdminView') !== 'dashboard';
		this.isCorporateAdminView = (this.router.url.includes('corporateAdmin') || this.router.url.includes('corp-admin-emp-profile'))
			&& this.commonService.getSessionData('selectedCorpAdminView') !== 'to-do';

		// here we subscribe to displayAllAnnouncements observable and set the Flags to toggle the Announcements button on header
		this.announcementService.getDisplayAnnouncements().subscribe((res) => {
			this.showLgIcon = res.displayAllAnnouncements || res.displaySingleAnnouncement;
			this.showMbIcon = res.displayAllAnnouncements || res.displaySingleAnnouncement;
		});
	}

	// method to pass the event, to open the side nav
	openSideNav(event: Event) {
		this.mySideNavOpenEvent.emit(true);
		event.preventDefault();
		event.stopPropagation();
	}

	// method to logout the user
	onClickLogout(event: Event): void {
		// user will be logged out on click of logout button and navigated to route login
		// as we are clearing the sessionStorage and localStorage it will navigate to ADFS page
		event.preventDefault();
		event.stopPropagation();
		this.logoutService.clearAndLogout();
	}

	@HostListener('document:click', ['$event'])
	clickout(event: any): void {
		if (this.logoutOpen) {
			const logoutLi = document.getElementById('logoutLi');
			if (!logoutLi.contains(event.target)) {
				this.logoutOpen = false;
			}
		}
	}

	// method used to set the values to display all Announcements on click of Announcements button on header
	openAnnouncements() {
		window.scrollTo(0, 0);
		this.announcementService.setDisplayAnnouncements({
			displayAllAnnouncements: !this.showLgIcon || !this.showMbIcon,
			displaySingleAnnouncement: false
		});
	}

	// method to decide whether to load the Employee List or Dashboard
	get dashboardRoute() {
		if (this.loginService.isAdmin) {
			return this.loginService.isAdmin && this.isAdminView ? '/admin/employee-list' : '/dashboard';
		} else if (this.loginService.isCorporateAdmin) {
			return this.loginService.isCorporateAdmin && this.isCorporateAdminView ? '/corporateAdmin/home' : '/to-do';
		}
	}

}
