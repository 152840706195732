<ng-container *ngIf="this.openAllAnnouncements">
	<div class="announcement-panel">
		<div class="header">
			<p class="header-title">{{ 'HomePage.Announcements' | translate }}</p>
			<img src="../../assets/images/closed.png" alt="X" (click)="closeAnnouncementsWindow()">
		</div>
		<p class="no-announcements"
		   *ngIf="this.announcements?.length === 0">{{'HomePage.NoAnnouncements' | translate}}</p>
		<ng-container *ngFor="let announcement of this.announcements">
			<div class="display-all-announcements">
				<a class="announcement-window-card" (click)="openRespectiveAnnouncement(announcement)">
					<img class="megaphone-on-window" src="../../assets/images/announcements/megaphone.png" alt="">
					<span class="announcement-window-date">{{announcement.startDate | date: 'mediumDate'}}</span>
					<span class="announcement-window-title">
							<a href="javascript:void(0)">{{announcement.subject}}</a>
						</span>
					<img class="announcement-arrow" src="../../assets/images/Fill-arrow.png" alt="">
				</a>
				<a class="announcement-window-card-small" (click)="openRespectiveAnnouncement(announcement)">
						<span class="announcement-window-date-title">
							<span
								class="announcement-window-date">{{announcement.startDate | date: 'mediumDate'}}</span>
							<span class="announcement-window-title">
								<a href="javascript:void(0)">{{announcement.subject}}</a>
							</span>
						</span>
					<img class="announcement-arrow" src="../../assets/images/Fill-arrow.png" alt="">
				</a>
				<div class="announcement-window-card-background"></div>
			</div>
		</ng-container>
	</div>
</ng-container>

<ng-container *ngIf="this.respectiveAnnouncement">
	<div class="respective-announcement-panel">
		<div class="display-respective-announcement">
			<div class="respective-announcement-card">
				<img class="megaphone-on-window" src="../../assets/images/announcements/megaphone.png" alt="">
				<div class="announcement-window-date">{{this.clickedAnnouncement.startDate | date: 'mediumDate'}}</div>
				<div class="announcement-window-title">{{this.clickedAnnouncement.subject}}</div>
			</div>
			<div class="respective-announcement-card-background"></div>
		</div>
		<div class="announcement-body-content" [innerHTML]="this.announcementBodyContent | safeHtml"></div>
		<div class="announcements-buttons">
			<a class="all-announcements-btn" href="javascript:void(0)" (click)="goToAllAnnouncements()">{{'HomePage.AllAnnouncements' | translate}}</a>
			<a class="normal-btn done-btn" href="javascript:void(0)" (click)="closeAnnouncementsWindow()">{{'Buttons.done' | translate}}</a>
		</div>
	</div>
</ng-container>
