import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ApiEndPoints} from '../../../Constants/apiendpoints.constants';
import {UserSupportInformationRequest, UserSupportInformationResponse} from '../models/user-support-information.model';

@Injectable({
	providedIn: 'root'
})
export class AnnouncementsAndUserSupportInformationService {

	constructor(private http: HttpClient) {}

	getAnnouncementsAndUserSupportInformation(userSupportInformationRequest: UserSupportInformationRequest) {
		return this.http.post<UserSupportInformationResponse>(`${environment.ApiBaseURL}${ApiEndPoints.announcementsAndUserSupportInformation}`
			, JSON.stringify(userSupportInformationRequest));
	}
}
