<div class="headerPanel">
	<div class="row">
		<div class="col-8 col-lg-6 logoOuter">
			<a class="responsiveMenuIcon" (click)="openSideNav($event)">
				<img src="../../assets/images/Menu-responsive.png" alt="">
			</a>
			<div class="logoTop">
				<img class="stars-xp-image" src="../../assets/images/STARS-XP.png" alt="">
			</div>
		</div>
		<div class="col-4 col-lg-6">
			<div class="topMiniMenu">
				<ul>
					<li>
						<span (click)="openAnnouncements()" href="javascript:void(0);"
						   [ngClass]="this.showLgIcon ? 'announcements-icon-lg-active' : 'announcements-icon-lg'">
							{{'HomePage.Announcements' | translate}}
						</span>
						<a class="announcements-icon-mb" href="javascript:void(0);" (click)="openAnnouncements()">
							<img src="{{showMbIcon ? '../../assets/images/announcements/announcement_mobile_selected.png' : '../../assets/images/announcements/announcement_mobile_unselected.png'}}" alt="">
						</a>
					</li>
					<li>
						<a href="javascript:void(0);" *ngIf="!this.isUserProfile() && !this.loginService.isAdmin
						&& !this.loginService.isCorporateAdmin" [routerLink]="['/viewuserprofile']">
							<img src="../../assets/images/user-top-icon-normal.png" alt="">
						</a>
						<a href="javascript:void(0);" *ngIf="!this.isUserProfile() && this.loginService.isAdmin && !this.isAdminView"  [routerLink]="['/admin/employee-list/emp-profile']">
						<img src="../../assets/images/user-top-icon-normal.png" alt="">
						</a>
						<a href="javascript:void(0);" *ngIf="!this.isUserProfile() && this.loginService.isAdmin && this.isAdminView" [routerLink]="['/admin/employee-list/emp-profile']">
							<img src="../../assets/images/user-top-icon-normal.png" alt="">
						</a>
						<a href="javascript:void(0);" *ngIf="!this.isUserProfile() && this.loginService.isCorporateAdmin && !this.isCorporateAdminView" [routerLink]="['/corp-admin-emp-profile']">
							<img src="../../assets/images/user-top-icon-normal.png" alt="">
						</a>
						<a href="javascript:void(0);" *ngIf="!this.isUserProfile() && this.loginService.isCorporateAdmin && this.isCorporateAdminView" [routerLink]="['/corp-admin-emp-profile']">
							<img src="../../assets/images/user-top-icon-normal.png" alt="">
						</a>
						<a href="javascript:void(0);" *ngIf="this.isUserProfile()" [routerLink]="[dashboardRoute]">
							<img src="../../assets/images/user-top-icon.png" alt="">
						</a>
					</li>
					<li id="logoutLi">
						<a href="javascript:void(0);" (click)="this.logoutOpen = !this.logoutOpen">
							<img src="../../assets/images/logout-new.png" alt="">
						</a>
						<ul [style.display]="this.logoutOpen ? 'flex' : 'none'" *ngIf="this.logoutOpen">
							<li>
								<a href="javascript:void(0);" (click)="this.logoutOpen = !this.logoutOpen">
									{{'Header.Cancel' | translate}}
								</a>
							</li>
							<li>
								<a href="javascript:void(0);" (click)="onClickLogout($event)" id="logout_from_header">
									{{'Header.Logout' | translate}}
								</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>