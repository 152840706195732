export class UserSupportInformationRequest {
	certCode: string;
	country: string;
	departmentCode: string;
	jointVentureCode: string;
	lastUpdateTime: string;
	starsID: string;
	userDefaultLanguage: string;
}

export class UserSupportInformationResponse {
	message: string;
	errorMsgDesc: string;
	result: Array<UserSupportInformationResponseResult>;
}

export class UserSupportInformationResponseResult {
	body: string;
	endDate: string;
	endDateList: string;
	jointVentureCode: string;
	jointVentureName: string;
	lastUpdateTime: string;
	lastUpdateUser: string;
	messageId: string;
	messageIdUpdate: string;
	messageType: string;
	selectedDepartments: string;
	startDate: string;
	startDateList: string;
	subject: string;
	targetCountry: string;
	targetLanguage: string;
	targetUserProfile: string;
	timeStamp: string;
}
