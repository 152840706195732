import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { OAuthCallbackComponent } from './oauth/oauth-callback.component';
import { UrlConsumerService } from './oauth/url-consumer.service';
import { AuthGuardService } from './oauth/auth-guard.service';
import { ADFSLoginComponent } from './oauth/adfs-login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginUser } from './oauth/login-user.service';
import { LoginUserDashboard } from './oauth/login-user-dashboard.service';
import { NavBarComponent } from './navbar/components/navbar.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/login',
		pathMatch: 'full'
	},
	{
		path: 'adfs-login',
		component: ADFSLoginComponent
	},
	{
		path: 'access_token',
		component: OAuthCallbackComponent,
		canActivate: [UrlConsumerService, LoginUserDashboard]
	},
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
		canActivate: [AuthGuardService, LoginUserDashboard]
	},
	// main routes - Start
	{
		path: 'dashboard',
		component: NavBarComponent,
		loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'to-do',
		component: NavBarComponent,
		loadChildren: () => import('./to-do/to-do.module').then(m => m.ToDoModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'certifications',
		component: NavBarComponent,
		loadChildren: () => import('./certificates/cert-summary.module').then(m => m.CertSummaryModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'enrollment-activity',
		component: NavBarComponent,
		loadChildren: () => import('./enrollment-activity/enrollment-activity.module').then(m => m.EnrollmentActivityModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'training-history',
		component: NavBarComponent,
		loadChildren: () => import('./training-history/training-history.module').then(m => m.TrainingHistoryModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'course-catalog',
		component: NavBarComponent,
		loadChildren: () => import('./course-catalog/course-catalog.module').then(m => m.CourseCatalogModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'class-schedule',
		component: NavBarComponent,
		loadChildren: () => import('./classroom-schedule/class-schedule.module').then(m => m.ClassScheduleModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'user-support-information',
		component: NavBarComponent,
		loadChildren: () => import('./user-support-information/user-support-information.module').then(m => m.UserSupportInformationModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'viewuserprofile',
		component: NavBarComponent,
		loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'admin',
		component: NavBarComponent,
		loadChildren: () => import('./dealer-admin/dealer-admin.module').then(m => m.DealerAdminModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'learner',
		component: NavBarComponent,
		loadChildren: () => import('./dealer-admin/dealer-admin.module').then(m => m.DealerAdminModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'action-error',
		component: NavBarComponent,
		loadChildren: () => import('./stars-xp-error/stars-xp-error.module').then(m => m.StarsXPErrorModule)
	},
	{
		path: 'forgot-password',
		loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
		canActivate: [AuthGuardService, LoginUserDashboard]
	},
	{
		path: 'forgot-id',
		loadChildren: () => import('./forgot-id/forgot-id.module').then(m => m.ForgotIdModule),
		canActivate: [AuthGuardService, LoginUserDashboard]
	},
	{
		path: 'change-temp-password',
		loadChildren: () => import('./change-temp-password/change-temp-password.module').then(m => m.ChangeTempPasswordModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'declaration-of-approval',
		loadChildren: () => import('./declaration-of-approval/declaration-of-approval.module').then(m => m.DeclarationOfApprovalModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'corporateAdmin',
		component: NavBarComponent,
		loadChildren: () => import('./corporate-admin/corporate-admin.module').then(m => m.CorporateAdminModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'corp-admin-emp-profile',
		component: NavBarComponent,
		loadChildren: () => import('./corp-admin-user-profile/corp-admin-user-profile.module').then(m => m.CorpAdminEmployeeProfileModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'eLearning-activity',
		component: NavBarComponent,
		loadChildren: () => import('./eLearning/eLearning.module').then(m => m.ELearningModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	// main routes - End
	{
		path: 'privacy-statement',
		component: PrivacyStatementComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'customer-support',
		loadChildren: () => import('./customer-support/customer-support.module').then(m => m.CustomerSupportModule)
	},
	{
		path: 'page-not-found',
		component: PageNotFoundComponent,
		canActivate: [AuthGuardService, LoginUser]
	},
	{
		path: 'cert-alert-subscriptions',
		component: NavBarComponent,
		loadChildren: () => import('./cert-alert-subscriptions/cert-alert-subscriptions.module').then(m => m.CertAlertSubscriptionsModule),
		canActivate: [AuthGuardService, LoginUser]
	},
	// do not place any routes below this line,
	// below line is executed when no route is found from the above defined routes
	{
		path: '**',
		redirectTo: '/dashboard'
	}

];

@NgModule({
	imports: [RouterModule, RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule {
}
