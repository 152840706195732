import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class LogoutService {

	constructor(private router: Router) {}

	clearAndLogout() {
		localStorage.clear();
		sessionStorage.clear();
		this.router.navigate(['/login']);
	}

	logoutAndGoLogin() {
		localStorage.removeItem('tempPassword');
		localStorage.removeItem('userContext');
		sessionStorage.removeItem('userContext');
		this.router.navigate(['/login']);
	}
}
