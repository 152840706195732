import { NgModule } from '@angular/core';
import { CommonModule, Location, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { OAuthCallbackComponent } from './oauth-callback.component';
import { AuthGuardService } from './auth-guard.service';
import { UrlConsumerService } from './url-consumer.service';
import {ADFSLoginComponent} from './adfs-login.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		OAuthCallbackComponent,
		ADFSLoginComponent
	],
	providers: [
		AuthGuardService,
		UrlConsumerService,
		Location,
		{ provide: LocationStrategy, useClass: HashLocationStrategy }
	]
})
export class OauthModule { }
