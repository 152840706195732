import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, timer } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public tokenExpiration$: Observable<number>;
	tokenIssued = sessionStorage.getItem('tokenIssue');
	tokenExpiry = sessionStorage.getItem('tokenExp');

	constructor(private http: HttpClient) {
		// set the expiration time in seconds
		const expirationTime = (Number(this.tokenExpiry) - Number(this.tokenIssued) / 60) * 60;
		// create an Observable that emits after the expiration time
		this.tokenExpiration$ = timer(expirationTime * 1000);
	}

	getTokenExpiration(): Observable<number> {
		// return the token expiration Observable
		return this.tokenExpiration$;
	}
}
