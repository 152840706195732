import { Component, HostListener, OnInit, TemplateRef, VERSION, ViewChild } from '@angular/core';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LogoutService } from './services/logout.service';
import { NavigationStart, Router } from '@angular/router';
import { UserIdService } from './services/userIdService/user-id.service';
import { LocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs';
import { AnnouncementService } from './announcements/services/announcement.service';
import { AuthService } from './services/authService/authService';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BearerTokenComponent } from './bearer-token/bearer-token/bearer-token.component';
import { LoginService } from './services/login.service';
import { FordUniversityComponent } from './ford-university/ford-university.component';
import { LoginResponse, UserSessionInformationResult } from './login/Models/LoginResponse.model';

export let browserRefresh = false;
/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	timeoutTimer: any;
	timeoutInterval = 45 * 60 * 1000; // 45 minutes
	isAuthenticated: boolean;
	subscription: Subscription;
	userCtx: any;
	tokenExpirationSubscription: Subscription;
	sessionTokenExpirationSubscription: Subscription;

	private modalRef: BsModalRef;

	logoutTimeoutTimer: any;
	logoutTimeoutInterval: number;
	warningTimer: any;
	warningTimerInterval: number

	loginResponse: LoginResponse;
	userSessionInformation: UserSessionInformationResult;
	securityProfile: boolean;
	fordUniveristyModalDisplayed = false;
	countryCode: string;

	constructor(
		public translate: TranslateService,
		public logoutService: LogoutService,
		public router: Router,
		public idService: UserIdService,
		public locationStrategy: LocationStrategy,
		public announcementService: AnnouncementService,
		public authService: AuthService,
		public modalService: BsModalService,
		public loginService: LoginService) {
		// console.log('inside AppComponent constructor');
		this.translate.setDefaultLang('en');
		// calling the isUserAuthenticated() to check if the sessionStorage has any value
		// as it finds an empty string in the sessionStorage, isAuthenticated will be false
		this.isUserAuthenticated();
		this.preventBackButton();
		this.subscription = router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				browserRefresh = !router.navigated;
			}
		});
	}
	/** Boolean for whether the menu is changed to collapsed 'hamburger menu' or full top horizontal menu */
	hamMenu: boolean;
	/** the Angular version */
	version = VERSION.full;
	/** whether we are production or not */
	envProd = environment.production;
	/** A listener to toggle between full screen menu and collapsed hamburger menu for smaller screens */
	@HostListener('window:resize', ['$event']) onResize(event?: any) {
		if (event.target.innerWidth > 767) {
			this.hamMenu = false;
		}
	}

	ngOnInit() {
		// as the application is loaded, ngOnInit will be called
		// On calling ngOnInit life cycle hook, we are subscribing to getUseAuthenticated to set isAuthenticated to true
		window.scrollTo(0, 0);
		this.listenToLoginResponseChanges();
		this.tokenExpirationOnUserAuthentication();
	}

	tokenExpirationOnUserAuthentication() {
		this.idService.getUserAuthenticated().subscribe(res => {
			this.isAuthenticated = res;
			if (!this.sessionTokenExpirationSubscription) {
				this.sessionTokenExpirationSubscription = this.idService.getSessionTokens().subscribe((res: any) => {
					if (res.tokenIssue !== undefined && res.tokenExp !== undefined) {
						this.preventBackButton();
						this.sessionTokenTimer(res);
						this.fifteenMinutesWarningTimer(res);
					}
				});
				// below code change is to load the announcements globally on refresh
				this.callAnnouncements();
			}
		});
	}

	private sessionTokenTimer(res: any) {
		const that = this;
		this.logoutTimeoutInterval = ((Number(res.tokenExp) - Number(res.tokenIssue)) / 60) * 60 * 1000;
		window.clearTimeout(this.logoutTimeoutTimer);
		this.logoutTimeoutTimer = window.setTimeout(function () {
			that.modalService.hide(1);
			that.logoutService.clearAndLogout();
			that.router.navigate(['/adfs-login']);
		}, this.logoutTimeoutInterval);
	}

	private fifteenMinutesWarningTimer(res: any) {
		const that = this;
		this.warningTimerInterval = ((Number(res.tokenExp) - Number(res.tokenIssue)) / 60) * 45 * 1000;

		window.clearTimeout(this.warningTimer);
		this.warningTimer = window.setTimeout(function () {
			that.openModal();
		}, this.warningTimerInterval);
	}

	isUserAuthenticated() {
		// as the value from sessionStorage and localStorage is a string we are checking that if string is empty or null or undefined or it has some value
		// in any of the case we are saying to return the string
		this.userCtx = sessionStorage.getItem('userContext') || localStorage.getItem('userContext') || '';
		// to convert the value of string to a boolean we are using !! to check against all return values from sessionStorage
		this.isAuthenticated = !!this.userCtx.trim();
	}

	preventBackButton() {
		history.pushState(null, null, location.href);
		this.locationStrategy.onPopState(() => {
			history.pushState(null, null, location.href);
		});
	}

	@HostListener('window:click')
	idleUser() {
		// after the user is logged in as the sessionStorage and localStorage will have userContext
		// calling the isUserAuthenticated() to evaluate whether user is Authenticated
		this.isUserAuthenticated();
		// console.log('isAuthenticated from idleUser: ' + this.isAuthenticated);
		const that = this;
		// When the user is authenticated and idle for 45 minutes, user will be logged out
		if (this.isAuthenticated) {
			window.clearTimeout(this.timeoutTimer);
			this.timeoutTimer = window.setTimeout(function () {
				that.logoutService.clearAndLogout();
			}, that.timeoutInterval);
		}
	}

	@HostListener('window:beforeunload')
	onBeforeUnload() {
		if (!this.isAuthenticated) {
			this.logoutService.clearAndLogout();
		}
	}

	callAnnouncements() {
		const userContext = this.userCtx && JSON.parse(this.userCtx);
		this.announcementService.loadAnnouncements(userContext);
	}

	openModal() {
		this.modalService.show(BearerTokenComponent);
	}

	listenToLoginResponseChanges() {
		this.loginService.loginResponse.subscribe(res => {
			this.userSessionInformation = res?.result?.userSessionInformation;
			this.securityProfile = this.loginService.dealerSecurityProfiles.includes(this.userSessionInformation.highestSecurityProfileCode);
		});
	}

}
