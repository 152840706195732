import {Injectable} from '@angular/core';
import {LoginService} from 'app/services/login.service';
import {EmployeeInformationForAdmin} from '../models/employee-information-for-admin.model';
import {Router} from '@angular/router';
import {SESSION_STORAGE_CONSTANTS} from 'Constants/StarsLearning.constants';
import {CommonService} from 'app/services/common.service';
import {
	EnrollmentActivityRecord
} from 'app/dealer-admin/admin-enrollment-activity/models/admin-enrollment-activity.model';

@Injectable()
export class EmployeeDetailsAdminService {
	employeeInfoForAdmin: EmployeeInformationForAdmin;
	enrollmentActivityData: EnrollmentActivityRecord;

	constructor(public loginService: LoginService,
				public router: Router,
				public commonService: CommonService) {
	}

	// method to populate the employee information for admin
	displayEmployeeDetailsForAdmin(route: any) {
		if ((route.includes('/admin') && this.loginService.isAdmin) ||
			(route.includes('/corporateAdmin') && this.loginService.isCorporateAdmin)) {
			this.employeeInfoForAdmin = new EmployeeInformationForAdmin();
			this.employeeInfoForAdmin.isAdminPage = true;
			this.enrollmentActivityData = this.commonService.getSessionData(SESSION_STORAGE_CONSTANTS.ADMIN_ENROLLMENT_ACTIVITY_RECORD);
			this.employeeInfoForAdmin.fullName = this.loginService?.starLearning?.get('adminEmployeeInfo').get('empName').value || this.enrollmentActivityData?.employeeName;
			this.employeeInfoForAdmin.starsId = this.loginService?.starLearning?.get('adminEmployeeInfo').get('empStarsID').value || this.enrollmentActivityData?.starsID;
			this.employeeInfoForAdmin.email = this.loginService?.starLearning?.get('adminEmployeeInfo').get('empEmail').value;
			this.employeeInfoForAdmin.country = this.loginService?.starLearning?.get('adminEmployeeInfo').get('empCountry').value;
			this.employeeInfoForAdmin.userDefaultLanguage = this.loginService?.starLearning?.get('adminEmployeeInfo').get('userDefaultLanguage').value;
			this.employeeInfoForAdmin.isSecDealership = this.loginService?.starLearning?.get('adminEmployeeInfo').get('isSecDealership').value;
			this.employeeInfoForAdmin.empDealerCode = this.loginService?.starLearning?.get('adminEmployeeInfo').get('empDealerCode').value;
			this.employeeInfoForAdmin.empDealerFleetIndicator = this.loginService?.starLearning?.get('adminEmployeeInfo').get('empDealerFleetIndicator').value;
			this.employeeInfoForAdmin.empJointVentureCode = this.loginService?.starLearning?.get('adminEmployeeInfo').get('empJointVentureCode').value;
			return this.employeeInfoForAdmin;
		}
		return {} as any;
	}

	// method to know if we are in submit waitlist
	isSubmitWaitlist(route: any) {
		if (route.includes('/confirm-waitlist') && this.loginService.isAdmin) {
			return true;
		}
		return false;
	}
}
