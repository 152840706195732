import { Injectable, Input } from '@angular/core';
import { LoginService } from '../login.service';
import { CommonService } from '../common.service';
import { PopupBlockerService } from '../popupBlockerService/popup-blocker.service';

@Injectable()
export class LoadCourseUrlService {

	constructor(public loginService: LoginService,
		public commonService: CommonService,
		public popupBlockerService: PopupBlockerService) { }

	loginResponse = this.loginService.getLoginResponse();

	// method prepares the all the Courses URL
	loadLaunchCourse(response?: any, optionalLanguage?: any) {
		this.loginResponse = this.loginService.getLoginResponse();
		const selectedLanguage = optionalLanguage === undefined ? this.loginResponse.result.userSessionInformation.userDefaultLanguageCode : optionalLanguage;
		const courseURL = response.result.courseURLs.find(x => x.courseLang === selectedLanguage);
		let launchCourseBaseURL = this.commonService.trim(courseURL.hrefURL);
		if (launchCourseBaseURL.toLowerCase().startsWith('www')) {
			launchCourseBaseURL = 'https://' + launchCourseBaseURL;
		}
		if (launchCourseBaseURL.indexOf('?') === -1) {
			launchCourseBaseURL = launchCourseBaseURL + '?';
		} else {
			launchCourseBaseURL = launchCourseBaseURL + '&';
		}
		let launchCourseURL = `${launchCourseBaseURL}`
			+ `stars2Id=${this.commonService.trim(this.loginResponse.result.userSessionInformation.starsID)}`
			+ `&stars2Country=${this.commonService.trim(this.loginResponse.result.userSessionInformation.countryCode)}`
			+ `&stars2Language=${this.commonService.trim(this.loginResponse.result.userSessionInformation.userDefaultLanguageCode)}`
			+ `&stars2Job=${this.commonService.trim(this.loginResponse.result.userSessionInformation.jobCode)}`
			+ `&stars2Email=${encodeURIComponent(this.commonService.trim(this.loginResponse.result.userSessionInformation.email))}`
			+ `&stars2Course=${encodeURIComponent(this.commonService.trim(courseURL.courseCode))}`
			+ `&stars2CourseCtry=${this.commonService.trim(courseURL.courseCountryCode)}`
			+ `&stars2CourseLang=${this.commonService.trim(courseURL.courseLang)}`
			+ `&employeeName=${encodeURIComponent(this.commonService.trim(this.loginResponse.result.userSessionInformation.empLastName
				+ ',' + this.loginResponse.result.userSessionInformation.empFirstName
				+ ' ' + this.loginResponse.result.userSessionInformation.empMiddleName)).replace(/ /g, '')}`
			+ `&employeeType=${this.commonService.trim(this.loginResponse.result.userSessionInformation.employeeType)}`
			+ `&from=STARS2`
			+ `&link=101`;
		//to print the url that we are launching
		//console.log(launchCourseURL);
		if (response.result.courseURLs[0].urlType === 'E') {
			launchCourseURL = launchCourseURL
				+ `&evalstars2ID=${this.commonService.trim(this.loginService.starLearning.get('adminEmployeeInfo').get('empStarsID').value)}`
				+ `&evalemployeeName=${encodeURIComponent(this.commonService.trim(this.loginService.starLearning.get('adminEmployeeInfo').get('empName').value)).replace(/ /g, '')}`;
		}
		// this change checks for popup and launch the course
		this.popupBlockerService.checkPopUpBlocker(launchCourseURL);
	}

	// method prepares the Informal Learning Course URL
	loadLaunchInformalLearningCourse(response?: any) {
		let launchCourseBaseURL = this.commonService.trim(response.result.courseURLs[0].hrefURL);
		if (launchCourseBaseURL.toLowerCase().startsWith('www')) {
			launchCourseBaseURL = 'https://' + launchCourseBaseURL;
		}
		if (launchCourseBaseURL.indexOf('?') === -1) {
			launchCourseBaseURL = launchCourseBaseURL + '?';
		} else {
			launchCourseBaseURL = launchCourseBaseURL + '&';
		}
		const launchCourseURL = `${launchCourseBaseURL}`
			+ `coursecode=${encodeURIComponent(this.commonService.trim(response.result.courseURLs[0].courseCode))}`
			+ `&starsid=${this.commonService.trim(this.loginResponse.result.userSessionInformation.starsID)}`;
		// this change checks for popup and launch the course
		this.popupBlockerService.checkPopUpBlocker(launchCourseURL);
	}

	// method prepares the all the Webinar Courses URL
	loadWebinarCourseUrl(response?: any) {
		if (response.result.courseURL !== '') {
			let launchWebinarCourseBaseURL = this.commonService.trim(response.result.courseURL);
			if (launchWebinarCourseBaseURL.toLowerCase().startsWith('www')) {
				launchWebinarCourseBaseURL = 'https://' + launchWebinarCourseBaseURL;
			}
			if (launchWebinarCourseBaseURL && launchWebinarCourseBaseURL.indexOf('?') < 0) {
				launchWebinarCourseBaseURL = launchWebinarCourseBaseURL + '?';
			} else {
				launchWebinarCourseBaseURL = launchWebinarCourseBaseURL + '&';
			}
			const launchWebinarCourseURL = `${launchWebinarCourseBaseURL}` + `session=${response.result.adobeSessionNumber || response.result.adobeSession}&proto=true`;
			//console.log(launchWebinarCourseURL);
			// this change checks for popup and launch the course
			this.popupBlockerService.checkPopUpBlocker(launchWebinarCourseURL);
		}
	}
}

