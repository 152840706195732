import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

// This component acts as a 404 page. If an invalid url is entered
// and there are no routes that match, this component will be loaded instead.
@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

	constructor(private router: Router) {}

	ngOnInit(): void {}

	gotoLogin(event: Event): any {
		event.preventDefault();
		event.stopPropagation();
		// as sessionStorage or localStorage has userContext
		// i.e., if user is not Authenticated, we will be routing them to the login page
		// if user is Authenticated, and click of 'Go to Homepage button' we will route them to dashboard route
		if (!sessionStorage.getItem('userContext') || !localStorage.getItem('userContext')) {
			this.router.navigate(['/login']);
		} else {
			this.router.navigate(['/dashboard']);
		}
	}
}
