import {Injectable} from '@angular/core';
import {LoginService} from '../login.service';
import {CommonService} from '../common.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {PageHistoryService} from '../pageHistoryService/page-history.service';
import {TimeDateService} from '../timeDateService/time-date.service';
import {ErrorMessagesModel} from 'app/start-error/models/error-messages.model';

@Injectable()
export class ErrorService {
	serverErrorOccurredTime: string;
	errorMessages: ErrorMessagesModel;

	constructor(public loginService: LoginService,
				public commonService: CommonService,
				public translate: TranslateService,
				public router: Router,
				public pageHistoryService: PageHistoryService,
				public timeDateService: TimeDateService) {
	}

	handleServiceError(error: any, route: any) {
		this.loginService.starLearning.get('errorMessage').get('serverErrorTitle').patchValue(error.error.errorMsgDesc || this.translate.instant('ErrorScreen.Error_Occurred'));
		this.loginService.starLearning.get('errorMessage').get('serverErrorBody').patchValue(this.translate.instant('ErrorScreen.systemErrorMessage'));
		this.router.navigate(['action-error'], {relativeTo: route});
	}

	// method used for handling the error scenario for launch actions and to navigate user back to previous route
	handleLaunchCourseServiceError(error: any, route: any, currentUrl?: any) {
		this.populateErrorValues(error);
		this.pageHistoryService.pushPageValueIntoPageHistory(currentUrl);
		this.router.navigate(['action-error'], {relativeTo: route});
	}

	populateErrorValues(error: any) {
		this.loginService.starLearning.get('errorMessage').get('serverErrorTitle').patchValue(error.error.errorMsgDesc || this.translate.instant('ErrorScreen.Error_Occurred'));
		this.loginService.starLearning.get('errorMessage').get('serverErrorBody').patchValue(this.translate.instant('ErrorScreen.systemErrorMessage'));
	}

	displayErrorMessages(error: any) {
		if (error) {
			this.errorMessages = new ErrorMessagesModel();
			this.errorMessages.serverErrorOccurredTime = this.timeDateService.callForDateTime();
			this.errorMessages.serverErrorTitle = error.error.errorMsgDesc || this.translate.instant('ErrorScreen.Error_Occurred');
			this.errorMessages.serverErrorBody = this.translate.instant('ErrorScreen.systemErrorMessage');
			this.errorMessages.serverErrorOccurred = true;
			return this.errorMessages;
		}
		return {} as any;
	}
}
