import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoginUser implements CanActivate {

	constructor(private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		// when the user is logged in, we get the loginResponse and we are checking it with the key 'userContext'
		// as localStorage is maintained through out the browser, here if the user doesn't have 'userContext' we are routing them to the login page.
		if (!localStorage.getItem('userContext')) {
			this.router.navigate(['/login']);
			return false;
		}
		return true;
	}
}
