import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiEndPoints } from '../../../Constants/apiendpoints.constants';
import {ClassroomScheduleRequest, ClassroomScheduleResponse} from '../models/classroom-schedule.model';

@Injectable()
export class ClassroomScheduleService {
	constructor(private http: HttpClient) {
	}

	getClassroomScheduleResponse(classroomScheduleRequest: ClassroomScheduleRequest,direction: string, sort: string) {
		let classroomScheduleURL = `${environment.ApiBaseURL}${ApiEndPoints.getClassSchedule}`;
		classroomScheduleURL = classroomScheduleURL.replace('{direction}', direction.toString());
		classroomScheduleURL = classroomScheduleURL.replace('{sort}', sort.toString());

		return this.http.post<ClassroomScheduleResponse>(classroomScheduleURL
			, JSON.stringify(classroomScheduleRequest));
	}
}
