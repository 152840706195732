import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ApiEndPoints} from '../../../Constants/apiendpoints.constants';
import { ChangeDealershipRequest, ChangeDealershipResponse } from '../models/navbar.model';

@Injectable()
export class ChangeDealershipService {
	constructor(private http: HttpClient) { }

	getDealerships(changeDealershipRequest: ChangeDealershipRequest, sort: string, direction: string): Observable<any> {
		let getDealershipsURL = `${environment.ApiBaseURL}${ApiEndPoints.changeDealerShip}`;
		getDealershipsURL = getDealershipsURL.replace('{sort}', sort.toString());
		getDealershipsURL = getDealershipsURL.replace('{direction}', direction.toString());

		return this.http.post<ChangeDealershipResponse>(getDealershipsURL, JSON.stringify(changeDealershipRequest));
	}
}
