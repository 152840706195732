<div class="iconstop">
	<!--<img title="Export To Excel" src="assets/images/icn_excel.png" alt="Excel">-->
	<img title="Print" onclick="window.print();" src="assets/images/icn_print.png" alt="Print">
</div>
<div class="iconstop_mobile" id="mobileShowTools" [ngClass]="{'active' : showTools == true }">
	<img (click)="showTools=! showTools" src="assets/images/3dots.png" alt="">
	<ul>
		<!--<li><a href="javascript:void(0)"><img src="assets/images/icn_excel.png" alt="">{{ 'Certificates.Export_to_Excel' | translate }}</a></li>-->
		<li>
			<a href="javascript:void(0)" onclick="window.print();">
				<img src="assets/images/icn_print.png" alt="">{{ 'print.print' | translate}}
			</a>
		</li>
	</ul>
</div>