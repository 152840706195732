import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoginService} from '../services/login.service';
import {LoginResponse} from '../login/Models/LoginResponse.model';
import {ErrorMessagesModel} from './models/error-messages.model';
import {ActivatedRoute, Router} from '@angular/router';
import {PageHistoryService} from 'app/services/pageHistoryService/page-history.service';

@Component({
	selector: 'app-start-error',
	templateUrl: './start-error.component.html',
	styleUrls: ['./start-error.component.scss']
})
export class StartErrorComponent implements OnInit {
	loginResponse: LoginResponse;
	@Input()
	starsID: string;

	@Input()
	overrideHeight = false;

	@Input()
	height: string;

	@Input() errorMessages: ErrorMessagesModel;

	@Output() closeStarsErrorModalEvent = new EventEmitter<boolean>();

	constructor(public loginService: LoginService,
				public router: Router,
				public route: ActivatedRoute,
				public pageHistoryService: PageHistoryService) {}

	ngOnInit() {
		window.scrollTo(0, 0);
		this.loginResponse = this.loginService.getLoginResponse();
		if (!this.starsID && this.loginResponse) {
			this.starsID = this.loginResponse.result.userSessionInformation.starsID;
		}
	}

	navigateCustomerSupport(path: string) {
		this.closeStarsErrorModalEvent.emit(false);
		if (this.loginResponse) {
			this.pageHistoryService.pushPageValueIntoPageHistory(this.router.url);
		}
		this.router.navigate([path], {relativeTo: this.route});
	}

}
