import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { MessageService } from 'primeng/api';
import { AppRoutingModule } from './app-routing.module';
import { LoginService } from './services/login.service';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { NavBarComponent } from './navbar/components/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { PagingService } from './services/paging.service';
import { HttpRequestInterceptor } from './services/http-request-interceptor';
import { OauthModule } from './oauth/oauth.module';
import { CommonService } from './services/common.service';
import { LogoutService } from './services/logout.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageHistoryService } from './services/pageHistoryService/page-history.service';
import { TimeDateService } from './services/timeDateService/time-date.service';
import { PopupBlockerService } from './services/popupBlockerService/popup-blocker.service';
import { SharedModule } from '../shared/shared.module';
import { ViewScheduleCourseCodeStore } from './services/view-schedule-course-code/view-schedule-course-code.store';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { HeaderComponent } from './header/header.component';
import { ChangeDealershipService } from './navbar/services/navbar.service';
import { LaunchCourseMissingPrereqComponent } from './launch-course-missing-prereq/launch-course-missing-prereq.component';
import { BearerTokenComponent } from './bearer-token/bearer-token/bearer-token.component';
import { MenubarModule } from 'primeng/menubar';
import { SidebarModule } from 'primeng/sidebar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { CourseLanguageOptionsComponent } from './course-language-options/course-language-options.component';
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { FordUniversityComponent } from './ford-university/ford-university.component';

@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		PrivacyStatementComponent,
		NavBarComponent,
		PageNotFoundComponent,
		HeaderComponent,
		LaunchCourseMissingPrereqComponent,
		BearerTokenComponent,
		CourseLanguageOptionsComponent,
		FordUniversityComponent
	],
	imports: [
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		AppRoutingModule,
		MenubarModule,
		SidebarModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		OauthModule,
		SharedModule,
		ModalModule.forRoot(),
		TooltipModule.forRoot(),
		PopoverModule.forRoot(),
		BsDropdownModule.forRoot(),
		CollapseModule.forRoot()
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestInterceptor,
			multi: true
		},
		MessageService,
		LoginService,
		PagingService,
		CommonService,
		LogoutService,
		PageHistoryService,
		TimeDateService,
		PopupBlockerService,
		ViewScheduleCourseCodeStore,
		ChangeDealershipService
	],
	bootstrap: [AppComponent],
	entryComponents: [LaunchCourseMissingPrereqComponent, BearerTokenComponent, CourseLanguageOptionsComponent],
	exports: [RouterModule]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
