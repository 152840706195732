export class UserModel {
	starsID: string;
	password: string;
	userDefaultLanguage: string;
	autoLoginFlag: string;
	constructor(starsID: string, password: string, autoLoginFlag: string) {
		this.starsID = starsID;
		this.password = password;
		this.userDefaultLanguage = 'EN';
		this.autoLoginFlag = autoLoginFlag;
	}
}

export class UsageRequestModel {
	starsID: string;
	countryCode: string;
	pageName: string;
	systemCode: string;
	jvm: string;
}

export class DeclarationRequest {
	starsID: string;
	userDefaultLanguage: string;
}
