import {Component, HostListener, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
	selector: 'app-print-icon',
	templateUrl: './print-icon.component.html',
	styleUrls: ['./print-icon.component.scss']
})

export class PrintIconComponent implements OnInit {
	@Input() starLearning: FormGroup;
	showTools = false;

	constructor() { }

	ngOnInit() {}

	@HostListener('document:click', ['$event'])
	clickOut(event) {
		if (this.showTools) {
			const mobileShowTools = document.getElementById('mobileShowTools');
			if (!mobileShowTools.contains(event.target)) {
				this.showTools = false;
			}
		}
	}

}
