import { Injectable } from '@angular/core';

@Injectable()
export class PagingService {
	pageSize = 10;
	currentPage = 1;
	total = 0;
	totalPages = 0;

	constructor() {

	}
	resetCurrentPage() {
		this.currentPage = 1;
	}
	setPages() {
		this.totalPages = Math.ceil(this.total / this.pageSize);
	}
	previousPage() {
		this.currentPage = this.currentPage - 1;
	}

	nextPage() {
		this.currentPage = this.currentPage + 1;
	}

	previousDisabled() {
		return this.currentPage === 1;
	}

	nextDisabled() {
		return this.currentPage === this.totalPages;
	}

	getFirstCount() {
		if (this.total > this.pageSize) {
			return ((((this.currentPage * this.pageSize) - this.pageSize)) + 1);
		} else {
			return this.total === 0 ? 0 : 1;
		}
	}
	getLastCount() {
		if (this.currentPage !== this.totalPages && this.total > this.pageSize) {
			return (this.currentPage * this.pageSize);
		} else {
			return this.total;
		}
	}
}
