import {Component, EventEmitter, HostListener, Input, OnChanges, Output} from '@angular/core';
import {
	EnrollmentActivityResponseListData,
	MissingPrerequisiteRequest,
	MissingPrerequisiteResponse
} from '../enrollment-activity/models/enrollment-activity.model';
import {LoginResponse} from '../login/Models/LoginResponse.model';
import {ErrorMessagesModel} from '../start-error/models/error-messages.model';
import {EnrollmentActivityRecord} from '../dealer-admin/admin-enrollment-activity/models/admin-enrollment-activity.model';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-launch-course-missing-prereq',
	templateUrl: './launch-course-missing-prereq.component.html',
	styleUrls: ['./launch-course-missing-prereq.component.scss']
})
export class LaunchCourseMissingPrereqComponent implements OnChanges {
	@Input() courseData: any;
	@Input() adminCourseData: any;
	loginResponse: LoginResponse;
	missingPrerequisiteResponse: MissingPrerequisiteResponse;
	displayModal = false;
	missingPrerequisiteData: EnrollmentActivityResponseListData;
	adminMissingPrerequisiteData: EnrollmentActivityRecord;

	@Input()
	errorMessage: string;

	@Input()
	missingPreReqData: Array<any>;

	// variables for Error Messages
	errorMessages: ErrorMessagesModel;
	serverErrorOccurred: boolean;

	@Output()
	closeMissingPreReq = new EventEmitter<boolean>();

	constructor(private readonly _bsModalRef: BsModalRef) {
	}

	ngOnChanges() {
		window.scrollTo(0, 0);
	}

	closeModal() {
		this._bsModalRef.content.emitData.next(true);
	}

	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		this.closeModal();
	}

}
