<!doctype html>
<html lang="en">

<head>
    <title>{{'Privacy_Statement.privacy_statement_title' | translate }}</title>
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <link href="https://fonts.googleapis.com/css?family=PT+Sans:400,700&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css?family=Assistant:300,400&display=swap" rel="stylesheet">

</head>

<body #topContainer class="light_blue_bg" id="top" (scroll)="onScroll($event)">
    <div class="headerPanel">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 contentpagelogo">

                    <img src="assets/images/STARS-XP.png" class="logotop ">
                </div>

            </div>
        </div>
    </div>
    <div class="content-pages">
        <div class="container" *ngIf="!this.serverErrorOccurred">
            <div class="header-content">
                <div class="back-icon">
                    <a href="javascript:void(0);" (click)="backToLogin($event)">
						<img src="assets/images/back.png">
					</a>
                </div>
                <div class="contentTitle">
                    {{'Login.Privacy_Statement' | translate}}
                    <app-print-icon [starLearning]="this.starLearning"></app-print-icon>
                </div>
            </div>
            <div class="content">

				<!-- For Europe-->
				<p *ngIf="shouldDisplayAccept"> {{'Privacy_Statement.read_accept' | translate }} </p>
				<!-- -->

                <h2>{{'Privacy_Statement.privacy_policy' | translate }}</h2> <br>

                <h2>{{'Privacy_Statement.General_Statement' | translate }}</h2>

                <p> {{'Privacy_Statement.General_Statement_text' | translate }} </p>

                <h2>{{'Privacy_Statement.Business_Purpose' | translate }} </h2>

                <p>{{'Privacy_Statement.Business_Purpose_Text' | translate }} </p>

                <h2>{{'Privacy_Statement.Ford_Star_Website_Info' | translate }} </h2>

                <p> {{'Privacy_Statement.Ford_Star_Website_Info_Text' | translate }} </p>

                <h2> {{'Privacy_Statement.PII_Collected_From_Website' | translate }} </h2>

                <p>{{'Privacy_Statement.PII_Collected_From_Website_Text1' | translate }} </p>

                <p>{{'Privacy_Statement.PII_Collected_From_Website_Text2' | translate }} </p>

                <p>{{'Privacy_Statement.PII_Collected_From_Website_Text3' | translate }} </p>

                <p>{{'Privacy_Statement.PII_Collected_From_Website_Text4' | translate }} </p>

                <p>{{'Privacy_Statement.PII_Collected_From_Website_Text5' | translate }}

                    <p>{{'Privacy_Statement.PII_Collected_From_Website_Text6' | translate }} </p>

                    <h2>{{'Privacy_Statement.How_PII_Is_Used' | translate }} </h2>

                    <p>{{'Privacy_Statement.How_PII_Is_Used_Text1' | translate }} </p>


                    <h2>{{'Privacy_Statement.Non_PII_Collected_From_Website' | translate }} </h2>

                    <p> {{'Privacy_Statement.Non_PII_Collected_From_Website_Text1' | translate }} </p>

                    <p> {{'Privacy_Statement.Non_PII_Collected_From_Website_Text2' | translate }} </p>

                    <p> {{'Privacy_Statement.Non_PII_Collected_From_Website_Text3' | translate }} </p>

                    <h2>{{'Privacy_Statement.Non_PII_Is_USed' | translate }} </h2>

                    <p>{{'Privacy_Statement.Non_PII_Is_USed_Text1' | translate }} </p>

                    <p>{{'Privacy_Statement.Non_PII_Is_USed_Text2' | translate }} </p>


                    <h2>{{'Privacy_Statement.Method_To_Collect_Non_PII' | translate }} </h2>

                    <p>{{'Privacy_Statement.Method_To_Collect_Non_PII_Text1' | translate }} </p>

                    <p>{{'Privacy_Statement.Method_To_Collect_Non_PII_Text2' | translate }} </p>

                    <p>{{'Privacy_Statement.Method_To_Collect_Non_PII_Text3' | translate }} </p>

                    <p> {{'Privacy_Statement.Method_To_Collect_Non_PII_Text4' | translate }} </p>

                    <h2>{{'Privacy_Statement.Sharing_Your_Info' | translate }} </h2>

                    <p> {{'Privacy_Statement.Sharing_Your_Info_Text1' | translate }}

                        <p> {{'Privacy_Statement.Sharing_Your_Info_Text2' | translate }} </p>

                        <p> {{'Privacy_Statement.Sharing_Your_Info_Text3' | translate }}</p>
                        <p>{{'Privacy_Statement.Sharing_Your_Info_Text4' | translate }}</p>

                        <p> {{'Privacy_Statement.Sharing_Your_Info_Text5' | translate }} </p>

                        <p> {{'Privacy_Statement.Sharing_Your_Info_Text6' | translate }} </p>

                        <h2>{{'Privacy_Statement.Access_To_PII_Info' | translate }} </h2>

                        <p> {{'Privacy_Statement.Access_To_PII_Info_Text1' | translate }} </p>

                        <p>{{'Privacy_Statement.Access_To_PII_Info_Text2' | translate }} </p>
                        <p>{{'Privacy_Statement.Access_To_PII_Info_Text3' | translate }} </p>

                        <h2>{{'Privacy_Statement.Security_of_Your_Info' | translate }} </h2>

                        <p>{{'Privacy_Statement.Security_of_Your_Info_Text1' | translate }} </p>

                        <p>{{'Privacy_Statement.Security_of_Your_Info_Text2' | translate }}
                        </p>

                        <p>{{'Privacy_Statement.Security_of_Your_Info_Text3' | translate }} </p>

                        <h2>{{'Privacy_Statement.Processing_Of_Personal_data' | translate }} </h2>

                        <p> {{'Privacy_Statement.Processing_Of_Personal_data_Text1' | translate }} </p>

                        <p> {{'Privacy_Statement.Processing_Of_Personal_data_Text2' | translate }}
                        </p>

                        <p> {{'Privacy_Statement.Processing_Of_Personal_data_Text3' | translate }}</p>

                        <p> {{'Privacy_Statement.Processing_Of_Personal_data_Text4' | translate }}</p>

                        <p> {{'Privacy_Statement.Processing_Of_Personal_data_Text5' | translate }} </p>

                        <h2>{{'Privacy_Statement.Links_To_Other_WebSites' | translate }} </h2>

                        <p>{{'Privacy_Statement.Links_To_Other_WebSites_Text' | translate }} </p>

                        <h2>{{'Privacy_Statement.Contact_US' | translate }} </h2>

                        <p>{{'Privacy_Statement.Contact_US_Text' | translate }} </p>

                        <h2>{{'Privacy_Statement.Privacy_Statement_Revision_Eff_date' | translate }} </h2>

                        <p>{{'Privacy_Statement.Privacy_Statement_Revision_Eff_date_Text1' | translate }}
                        </p>

                        <p>{{'Privacy_Statement.Privacy_Statement_Revision_Eff_date_Text2' | translate }} </p>

            </div>

			<!-- For Europe-->
			<div class="buttons-container" *ngIf="shouldDisplayAccept">
				<span  class="button-cancel" id="login-button-cancel" (click)="backToLogin($event)">{{'Buttons.decline' | translate}}
				</span>
				<button  class="button" id="login-button-submit" (click)="acceptDeclaration()" type="submit">{{'Buttons.accept' | translate}}
				</button>
			</div>
			<!---->

            <p id="back-top" *ngIf="showBackToTop">
                <a href="javascript:void(0)" (click)="backToTop();"> <img src="assets/images/backtotop.png"></a>
            </p>


        </div>
		<div class="reset-employee-error-messages" *ngIf="this.serverErrorOccurred">
			<app-start-error [errorMessages]="this.errorMessages"></app-start-error>
		</div>
    </div>
    <p class="text-center">{{'Login.Copyright_Ford_Motor_Company' | translate}}</p>
    <!-- <div class="footer text-center">
        <div class="container">

        </div>
    </div> -->
    <!-- Optional JavaScript -->
    <!-- jQuery first, then Popper.js, then Bootstrap JS -->
    <script src="js/jquery-3.3.1.slim.min.js"></script>
    <script type="text/javascript" src="js/jquery-1.4.3.min.js"></script>
    <script src="js/popper.min.js"></script>
    <script src="js/bootstrap.min.js"></script>
    <script>
        $(document).ready(function() {

            // hide #back-top first
            $("#back-top").hide();

            // fade in #back-top
            $(function() {
                $(window).scroll(function() {
                    if ($(this).scrollTop() > 100) {
                        $('#back-top').fadeIn();
                    } else {
                        $('#back-top').fadeOut();
                    }
                });

                // scroll body to 0px on click
                $('#back-top a').click(function() {
                    $('body,html').animate({
                        scrollTop: 0
                    }, 800);
                    return false;
                });
            });

        });
    </script>
</body>

</html>