import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-bearer-token',
	templateUrl: './bearer-token.component.html',
	styleUrls: ['./bearer-token.component.scss']
})
export class BearerTokenComponent implements OnInit {
	showTokenTimerPopup = false;
	modalRef: BsModalRef;
	@ViewChild('quarterlyUpdateTemplate') public quarterlyUpdateTemplate: TemplateRef<any>;

	constructor(public modalService: BsModalService) {
	}

	ngOnInit() {}

}
