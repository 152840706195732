export const StarComponents = {
	Dashboard: 'Dashboard',
	Summary: 'CertificateSummary',
	Planner: 'CertificatePlanner',
	CertHistory: 'CertificateHistory',
	ToDo: 'ToDo',
	ToDoUptoDate: 'ToDoUpToDate',
	CourseDetails: 'CourseDetails',
	EnrollmentActivity: 'EnrollmentActivity',
	TrainingLocationDetails: 'TrainingLocationDetails',
	ClassDetails: 'ClassDetails',
	CourseAndTaskCatalog: 'CourseAndTaskCatalog',
	PrintIcon: 'PrintIcon',
	TrainingHistory: 'TrainingHistory',
	WebinarSchedule: 'WebinarSchedule',
	ClassroomSchedule: 'ClassroomSchedule',
	UserSupportInformation: 'UserSupportInformation',
	ManageCertification: 'ManageCertification'
};

export const ControlType = {
	readOnly: 'readOnly',
	textBox: 'textBox',
	dropDown: 'dropDown',
	checkBoxes: 'checkBoxes',
	textArea: 'textArea',
	checkBoxList: 'checkBoxList',
	multi: 'multi',
	radio: 'radio',
	smallTextBox: 'smallTextBox',
	multiBox: 'multiBox',
	textBoxWithLink: 'textBoxWithLink',

	datePicker: 'datePicker'
};

export class RegularExpressionConstant {
	static email: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	static textBox = '^[^<>]*$';
	static numericTextBox = '^[0123456789]*$';
	static decimalTextBox = '^[0123456789.]*$';
	static employeeName = '^[^<>~]*$';
	static supplierCode = '^[A-Za-z0-9&.-]+$';
	static alphaNumeric = '^[a-zA-Z0-9]*$';
	static generalCode = '^[a-zA-Z0-9-&.]*$'; // alphanumeric plus -, &, .
	static generalText = '^[^<>~]*$'; // all characters except angle brackets
	static nonDoubleByte = '(?!.*[<>~])[\x20-\x7E]+$'; // Not double byte characters, allow all letters and numbers, not <>~
	static percentage = '^(?:100(?:\\.0(?:0)?)?|\\d{1,2}(?:\\.\\d{1,2})?)$';
	static roomRate = '^\\d{1,9}(?:\\.\\d{1,2})?$';
	static numberNotZero = '^[0-9]*[1-9][0-9]*$';
	static notLineBreak = '^[^\n\t\r]*$';
	static dateYYYYDashMMDashDD = '^[0-9]{4}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$'
}

export const PATH_CONSTANTS = {
	'COURSE_CATALOG': 'course-catalog',
	'CLASS_SCHEDULE': 'class-schedule',
	'WEBINAR_SCHEDULE': 'webinar-schedule',
	'RESET_PASSWORD': 'reset-password',
	'LOCATION_LIST': 'location-list',
	'SUPPLIER_LIST': 'supplier-list',
	'DEALER_LIST': 'dealerSearch',
	'FLEET_LIST': 'fleetList',
	'FLEET_DETAILS': 'viewFleet',
	'USER_SUPPORT_INFORMATION': 'user-support-information',
	'JOB_ROLE_DETAILS': 'job-role-details',
	'UPDATE_JOB_ROLE_DETAILS': 'update-job-role-details',
	'CREATE_JOB_ROLE_DETAILS': 'create-job-role-details',
	'ADD_JOB_ROLE_TITLE': 'add-job-role-title',
	'JOB_ROLE_LIST_BY_COUNTRY': 'job-role-list-by-country',
	'JOB_ROLE_MASTER_LIST': 'job-role-master-list',
	'HOME': 'home',
	'ENROLLMENT_ACTIVITY': 'enrollment-activity',
	'DEALER_DETAILS': 'dealer-details',
	'SHOP_TECH_COMPETENCY': 'shop-tech-competency',
	'SHOP_TECH_COMP_HISTORY': 'shop-tech-comp-history',
	'EMPLOYEE_LIST': 'employee-list',
	'UPDATE_FLEET': 'updateFleet',
	'CREATE_FLEET': 'createFleet',
	'ALTERNATE_NAME': 'alternateName',
	'COURSE_DETAILS': 'course-details',
	'CLASS_DETAILS': 'class-details',
	'TRAINING_LOCATION': 'training-location',
	'UPDATE_CLASS_ENROLLMENT': 'updateClassEnrollment',
	'EMPLOYEE_LOOKUP': 'employeeLookup',
	'CBU_SEARCH': 'cbuSearch',
	'CBU_DETAILS': 'cbuDetails',
	'CBU_CREATE': 'cbuCreate',
	'CBU_UPDATE': 'cbuUpdate',
	'CBU_EMPLOYEE_LIST': 'cbuEmployeeList',
	'NON_SCHEDULED_TRAINING_RESULTS': 'nonScheduledTrainingResults',
	'NON_SCHEDULED_TRAINING_RESULTS_ADD_RECORDS': 'addRecords',
	'NON_SCHEDULED_TRAINING_RESULTS_VIEW_RECORDS': 'viewRecords',
	'NON_SCHEDULED_TRAINING_RESULTS_DELETE_RECORD': 'deleteRecord',
	'NON_SCHEDULED_TRAINING_RESULTS_UPDATE_RECORD': 'updateRecord',
	'INSTRUCTOR_LIST': 'instructor-list',
	'CERTIFICATION_LIST': 'certification-list',
	'EMSISO': 'emsiso',
	'EMSISO_ASSIGN_COUNTRIES': 'assignCountries',
	'MULTI_DEALER_ADMIN': 'multi-dealer-admin',
	'UPDATE_DEFERRAL_DATES': 'update-deferral-dates',
	'CERTIFICATIONS': 'certifications',
	'CREATE_DEALER': 'createDealer',
	'CORPORATE_ADMIN_TRANSFER_TERMINATE_DEALER': 'transfer-terminate-dealer',
	'CORPORATE_ADMIN_ADD_TRANSFER_DEALER': 'add-transfer-dealer',
	'CORPORATE_ADMIN_ADD_TERMINATE_DEALER': 'add-terminate-dealer',
	'CORPORATE_ADMIN_CANCEL_TRANSFER_TERMINATE_DEALER': 'cancel-transfer-terminate-dealer',
	'CORPORATE_ADMIN_HOTEL': 'hotel',
	'CREATE_HOTEL': 'createHotel',
	'ELEARNING_ACTIVITY': 'eLearning-activity',
	'HOTEL_DETAIL': 'hotelDetail',
	'CORPORATE_CLASS_DETAILS': 'corporate-class-details',
	'HOTEL_UPDATE': 'hotelUpdate',
	'HOTEL_DELETE': 'hotelDelete',
	'CERT_ALERT_SUBSCRIPTIONS': 'cert-alert-subscriptions',
	'CERT_ALERT_DEALER_SUBSCRIPTIONS': 'cert-alert-dealer-subscriptions',
	'CORPORATE_CLASS_SCHEDULE': 'corporate-class-schedule',
	'PROGRAM_LIST': 'program-list',
	'PROGRAM_DETAIL': 'programDetail',
	'PROGRAM_UPDATE': 'programUpdate',
	'CREATE_PROGRAM': 'createProgram',
	'CLASS_ROSTER': 'class-roster',
	'SPONSOR_LIST': 'sponsor-list',
	'SPONSOR_DETAILS': 'sponsor-details',
	'CREATE_SPONSOR': 'create-sponsor',
	'CORPORATE_ADMIN_COURSE_DETAILS': 'course-details',
	'CREATE_COURSE_TWO': 'create-course-two',
	'UPDATE_SPONSOR': 'update-sponsor',
	'Manage_Roles': 'ManageRoles',
	'RIGHT_TO_BE_FORGOTTEN_LIST': 'right-to-be-forgotten-list',
	'RIGHT_TO_BE_FORGOTTEN_DETAILS': 'right-to-be-forgotten-details',
	'RIGHT_TO_BE_FORGOTTEN_ADD': 'right-to-be-forgotten-add',
	'RIGHT_TO_BE_FORGOTTEN_SUBMIT': 'right-to-be-forgotten-submit',
	'RIGHT_TO_BE_FORGOTTEN_CANCEL': 'right-to-be-forgotten-cancel',
	'SCHEDULEANDCLASSMAINTENANCE': 'scheduleAndClassMaintenance',
	'VIEW_SCHEDULE': 'viewSchedule',
	'CREATE_COURSE': 'create-course-one',
	'UPDATE_COURSE': 'update-course-one',
	'VIEW_WAITLIST' : 'view-waitlist'
};

export const SESSION_STORAGE_CONSTANTS = {
	'DEALER_SEARCH_RESPONSE': 'dealerSearchResponse',
	'DEALER_DETAILS_RECORD': 'dealerDetailsRecord',
	'FLEET_SEARCH_RESPONSE': 'fleetSearchResponse',
	'ADMIN_ENROLLMENT_ACTIVITY_RECORD': 'adminEnrollmentActivityRecord',
	'CBU_DETAILS_RECORD': 'cbuDetailsRecord',
	'CBU_DETAILS_RESPONSE': 'cbu-details-response',
	'NONSCHEDULE_TRAINING_SELECTED_COURSE_RESPONSE': 'nstscr',
	'NONSCHEDULE_TRAINING_SELECTED_COURSE_DATE': 'nstscd',
	'NONSCHEDULE_TRAINING_SELECTED_RECORD_TO_UPDATE': 'nstsrtu',
	'EMSISO_COUNTRY_LIST_RESPONSE': 'countryListResponse',
	'PROGRAM_LIST_RESPONSE': 'programListResponse',
	'CORPORATE_CLASS_SCHEDULE_RECORD': 'classSchedule',
	'SPRONSOR_LIST_RECORD': 'sponsorListRecord',
	'SCHEDULE_LIST_RESPONSE': 'scheduleListResponse',
	'VIEWSCHEDULE_LIST_RESPONSE': 'viewScheduleListResponse'
};
