import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
import { tap } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {LogoutService} from './logout.service';


/** This class is an implementation of an HttpInterceptor
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	/** This method is required by the fact that this class implements HttpInterceptor.
	 * It accepts (intercepts) HttpRequests and adds the headers required by API's for this application.
	 *
	 * @param request the HttpRequest object
	 * @param next the Http handling object
	 * @returns the Observable representing the next HttpEvent
	 */

	constructor(private router: Router,
				private logoutService: LogoutService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		request = request.clone({
			setHeaders: {
				Authorization: 'Bearer ' + localStorage.getItem('encodedAccessToken'),
				'Content-Type': 'application/json'
			}
		});
		// console.log('Authorization: ' + ('Bearer ' + localStorage.getItem('encodedAccessToken')));
		return next.handle(request).pipe(
			tap(
				() => {},
					error => {
					if (error instanceof HttpErrorResponse) {
						if (error.status === 0 || error.status === 401) {
							this.logoutService.clearAndLogout();
							this.router.navigate(['/adfs-login']);
						}
					}
				}
			));
	}
}
