/**This file contains a constant object that has properties that are necessary
 * for the build found in the `production` configuration in `angular.json`.
 */
export const environment = {
	production: false,
	inmem: false,
	//ApiBaseURL: 'https://stars-backend-edu.apps.pd11.edc.caas.ford.com/',
	ApiBaseURL: 'https://api-stg.mss.ford.com/dlmstars/',
	starsBaseURL: 'https://webedu.stars.dealerconnection.com',
	atsBaseURL: 'https://wwwqa.fordats.dealerconnection.com/login.aspx?O=U&test=STARSXP',
	atsExtendedURL: '&ctry=usa&language=en&testtype=Level1&module=&acertdate=&NoMenu=&doconfirm=&verifystudent=Y&isMS=1',
	adfsBaseURL: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
	clientId: 'urn:dlm_starsxp:clientid:web_dlm_starsxp_website:edu',
	resource: 'urn:dlm_starsxp:resource:web_dlm_starsxp_website:edu',
	viewMapBaseURL: 'https://www.google.com/maps/search/?api=1&query=',
	StarsXpBaseURL: 'https://stars-frontend-edu.apps.pd11.edc.caas.ford.com',
	//StarsXpBaseURL: 'https://wwwedu.starsxp.dealerconnection.com',
	fleetURL: 'https://www.fleet.ford.com',
	dealerAsk: 'https://www.fmcdealer.dealerconnection.com/content/fmcdealer/en/us/Help.html?utm_source=redirect&utm_medium=sharepoint&utm_campaign=/sites/ford_lm/help/pages/daf.aspx',
	systemMaintenance: false,
	launchWebinarAdobeServerCode: false,
	managementReportsDealerURL: 'https://www.webfocus8.dealerconnection.com/ibi_apps/run.bip?BIP_REQUEST_TYPE=BIP_RUN&BIP_folder=IBFS:/WFC/Repository/STARS16204-DEALER/dealer/&BIP_item=default.fex',
	managementReportsFleetURL: 'https://www.webfocus8.dealerconnection.com/ibi_apps/run.bip?BIP_REQUEST_TYPE=BIP_RUN&BIP_folder=IBFS:/WFC/Repository/stars162/fleet/&BIP_item=default_flt.fex',
	managementReportsCorporateURL: 'https://www.webfocus8.dealerconnection.com/ibi_apps/run.bip?BIP_REQUEST_TYPE=BIP_RUN&BIP_folder=IBFS:/WFC/Repository/STARS16204-DEALER/dealer/&BIP_item=default.fex',
	managementReportsCBUURL: 'https://www.webfocus8.dealerconnection.com/ibi_apps/run.bip?BIP_REQUEST_TYPE=BIP_RUN&BIP_folder=IBFS:/WFC/Repository/stars162/cbu/&BIP_item=default_cbu.fex'
};
