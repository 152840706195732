import {Injectable} from '@angular/core';
import {LoginService} from '../login.service';

@Injectable()
export class PageHistoryService {

	constructor(public loginService: LoginService) {}

	pushPageValueIntoPageHistory(value?: string) {
		const pageValue = value || this.loginService.getCurrentComponent().value;
		const values = this.loginService.getPageComponent().value;
		values.push(pageValue);
		this.loginService.setPageComponent(values);
	}
}
